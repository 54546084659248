import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-estados-cuenta-resposivo',
  templateUrl: './estados-cuenta-resposivo.component.html',
  styleUrls: ['./estados-cuenta-resposivo.component.scss']
})
export class EstadosCuentaResposivoComponent implements OnInit {
    @Input() documentos: any[];
    @Input() index;
    @Input() solicitante;
    @Input() show = true;
  
    @Output() VerInfo = new EventEmitter();
    @Output() Upload = new EventEmitter();
    @Output() VerDocumento = new EventEmitter();
    @Output() VerComentarios = new EventEmitter();
    aceptando = false;
    rechazando = false;
    perfil = null;
  
    validar_documento;

    algun_cancelado
    validados
    incluidos
  
    documentos_estados_cuenta = [];
    estadosCuentaCompletos:boolean;

  constructor() { }

   ngOnChanges(changes: SimpleChanges){
      console.log("ESTADOS", this.documentos )
      this.filtrarEstatusDocumentos();
    }

  ngOnInit(): void {
  }

  filtrarEstatusDocumentos(){
    const documentosCompletos =  this.documentos.filter(documento => documento.status === 1);
    this.estadosCuentaCompletos = documentosCompletos.length > 0
    console.log("DOCUMENTOS ESTADOS COMPLETO", documentosCompletos)
    console.log("DOCUMENTOS ESTADOS COMPLETO", this.estadosCuentaCompletos)
  }
  verDocumento(documentoIndex: number): void {
    const documento = this.documentos[documentoIndex];
    if (!documento) return;
    console.log("documento.status :", documento.status);
    console.log("documento.tipo_documento :", documento.tipo_documento);
    console.log("DOc", documento)
    this.VerDocumento.emit(documento);
  }

  upload(documentoIndex: number): void {
    const documento = this.documentos[documentoIndex];
    if (!documento) return;
    this.Upload.emit(documento);
  }

  info(descripcion: string): void {
    this.VerInfo.emit(descripcion);
  }
  getOrdinal(index: number): string {
    const suffixes = ['er', 'do', 'er']; 
    const suffix = suffixes[index];
    return (index + 1) + suffix;
  }
  validarStatusDoc(){
    const algun_cancelado=  this.documentos.filter(documento => documento.status === 2);
    const validados= this.documentos.filter(documento => documento.status === 3);
  
    this.algun_cancelado=algun_cancelado.length;
    this.validados=validados.length;
  
    if(this.validados < 3 || this.algun_cancelado === 0){
      const incluido =this.documentos.filter(documento => documento.status === 1);
      this.incluidos = incluido.length>0;
    }
    
   return (this.validados === 3 || this.algun_cancelado !== 0);
    }

  
}
