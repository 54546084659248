<div class="container">
  <div class="closet-envio">
    <button mat-icon-button (click)="cerrarMenu()">
      <mat-icon style="color: #9e9e9e">close</mat-icon>
    </button>
  </div>
  <div class="opcion">
    <mat-form-field appearance="outline">
      <mat-label>Tipo de usuario</mat-label>
      <mat-select [(ngModel)]="solicitante" name="tipoUsuario">
        <mat-option [value]="true">Solicitante</mat-option>
        <mat-option [value]="false">Aval</mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field appearance="outline" [class.mat-form-field-invalid]="!celular && isSubmitted">
      <mat-label>Teléfono celular</mat-label>
      <input
        type="text"
        matInput
        mask="00 0000 0000"
        [(ngModel)]="celular"
        name="celular"
        required
      />
      <mat-error *ngIf="!celular && isSubmitted">
        <span style="color: red;">El teléfono es obligatorio</span>
      </mat-error>
    </mat-form-field>

    <button mat-icon-button color="primary" (click)="copy()" matTooltip="Copiar URL">
      <mat-icon>content_copy</mat-icon>
    </button>
    <p class="text-url">{{url || ''}}</p>

    <div class="container-btn">
      <div class="guardar-btn-div">
        <button class="guardar-btn" (click)="generacionURL()" [disabled]="isLoading">
          {{ isLoading ? "Generando..." : "Generar" }}
        </button>
      </div>
      <div class="guardar-btn-div">
        <button class="guardar-btn" [disabled]="isLoading" (click)="validarYEnviarURL()">
          {{ isLoading ? "Enviando..." : "Enviar" }}
        </button>
      </div>
    </div>
  </div>
</div>