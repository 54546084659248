<app-sidenav-v2 [shortMenu]="true">
	<!-- <div class="uploading">
		<img src="https://leaseforu-assets.s3.us-east-2.amazonaws.com/assets/images/loading.jpg" height="50"
			alt="Loading">
	</div> -->
	<div class="container">
		<div class="top">
			<div class="title"> <app-go-bak></app-go-bak><span> Solicitud</span></div>
		</div>
		<div>
			<div class="row-center" style="background-color: white;">
			
					<div class="selctor-botton fisica" *ngIf="parte == 1" (click)="changeActive(1)"
					[ngClass]="{'active':typeP == 1 }">
					<img src="https://leaseforu-assets.s3.us-east-2.amazonaws.com/assets/images/pf-grey.svg" alt="Fisica"><span>Fisica</span>
					<!-- SOLICITUD PERSONA FÍSICA -->
				</div>
				<div class="selctor-botton moral" *ngIf="parte == 1" (click)="changeActive(2)"
					[ngClass]="{'active':typeP == 2}">
					<img src="https://leaseforu-assets.s3.us-east-2.amazonaws.com/assets/images/pm-grey.svg" alt="Moral"><span>Moral</span>
					<!-- SOLICITUD PERSONA MORAL -->
				</div>
			
			</div>
			
		</div>
        <!-- Persona fisica -->
        <div *ngIf="typeP==1">
            <app-ocr-solicitud></app-ocr-solicitud>
        </div>
        <!-- Persona Moral -->
        <div *ngIf="typeP==2">
            <mat-horizontal-stepper [linear]="true" #stepper>
				<mat-step  [editable]="false" [completed]="isStep1Completed1" >
					<div style="margin: 30px;">
						<ng-template matStepLabel>Datos Generales</ng-template>
						<div *ngIf="typeP>0">
								<app-solicitud-dg-p1 
								(stepCompletion)="updateStep1Completion1($event)"
								(parte)="changeForm($event)"
							[solicitud]="solicitud"
							[tipoPersona]="typeP" 
							[esMedico]="es_medico"
							(getDatosGenerales)="SetData($event, 1)"></app-solicitud-dg-p1>
						</div>
					</div>
				</mat-step>
				<mat-step [editable]="false" [completed]="isStep1Completed2" >
					<ng-template matStepLabel>Domicilio</ng-template>
					<div style="margin: 30px;" *ngIf="typeP>0">
							<app-solicitud-dg-p2 
							(stepCompletion2)="updateStep2Completion2($event)"
							*ngIf="ready && load_request == false && parte == 2" 
						[solicitud]="solicitud"
						(parte)="changeForm($event)"
						[tipoPersona]="typeP" 
						(onBack)="parte = $event" 
						(getDatosGenerales)="SetData($event, 2)"></app-solicitud-dg-p2>
					</div>
				</mat-step>
				<mat-step [editable]="false" [completed]="isStep1Completed3" >
					<ng-template matStepLabel *ngIf="typeP == 2">Ocupación</ng-template>
					<div style="margin: 30px;" *ngIf="typeP>0">
							<app-solicitud-dg-p3 
							(stepCompletion3)="updateStep3Completion3($event)"
							*ngIf="load_request == false && parte == 3"
							[solicitud]="solicitud"
							(parte)="changeForm($event)"
							[tipoPersona]="typeP" 
							(onBack)="parte = $event" ></app-solicitud-dg-p3><!--"-->
						
					</div>
				</mat-step>
			</mat-horizontal-stepper>
        </div>
		<app-socials-footer style="width:100%;"></app-socials-footer>
	</div>
</app-sidenav-v2>
