import { Component, OnInit } from "@angular/core";
import { UserService, AuthenticationService } from "src/app/_services";
import { MatDialog } from "@angular/material/dialog";
import { MessageBoxComponent } from "src/app/_dialogs/message-box/message-box.component";
import { ComentariosComponent } from "src/app/_dialogs/comentarios/comentarios.component";
import { Router } from "@angular/router";
import { RechazarDocumentoComponent } from "src/app/_dialogs/rechazar-documento/rechazar-documento.component";
import { InfoComponent } from "src/app/_components/info/info.component";
import * as JSZip from "jszip";
import { HttpClient } from "@angular/common/http";
import { saveAs } from 'file-saver';


@Component({
  selector: "app-carga-documentos",
  templateUrl: "./carga-documentos.component.html",
  styleUrls: ["./carga-documentos.component.scss"],
})
export class CargaDocumentosComponent implements OnInit {
  id_usuario = null;
  perfil = 0;
  id_cliente = 0;
  folio = "";
  tipo_persona = "";
  data: any = {};
  formData: FormData;

  solicitud_prellenada = false

  allowed_type = "application/pdf";

  public cancelDoc = false;

  completado = false;
  documentos_necesarios = 0;
  loading = false;

  documentos_solicitante = true;
  documentos_aval = false;

  documentos_pf = false;
  documentos_pm = false;
  documentos_pm_legal = false;

  documentos_af = false;
  documentos_am = false;
  documentos_am_legal = false;

  rechazar_documentos = [4,10,13,14,15,16,27]

  Documentos = {
    DocumentosSolicitante: [],
    DocumentosSolicitanteLegal: [],
    DocumentosAval: [],
    DocumentosAvalLegal: [],
    DocumentosVendor: []
  };
  status_confirmacion = 0;

  $loading = null

  faltanDocumentos = false
  DocumentosValidar = []
  validandoDocumentos = false

  constructor(
    private userService: UserService,
    private dialog: MatDialog,
    private router: Router,
    private authenticationService: AuthenticationService,
    private http: HttpClient
  ) {}

  ngOnInit(): void {
    const clientDetails = localStorage.getItem("client_details");

  if (!clientDetails) {
    this.dialog.open(MessageBoxComponent, {
      width: "400px",
      data: {
        text: "No se encuentra información del folio",
        text2: "Vuelve a intentarlo",
        type: "error",
        icon: "error",
      },
    }).afterClosed().subscribe(()=>{
      this.router.navigate(["/dashboard"]);
    });
    return;
  }
    const {
      folio,
      id_cliente,
      tipo_persona,
      tipo_aval = 1,
      procesos,
    } = JSON.parse(localStorage.getItem("client_details"));

    this.$loading = document.querySelector('.uploading').setAttribute('class', 'uploading hide')
    

    this.id_usuario = JSON.parse(localStorage.getItem("currentUser")).id;
    this.perfil = Number(JSON.parse(localStorage.getItem("currentUser")).perfil);
    this.tipo_persona = tipo_persona == 1 ? "Persona Física" : "Persona Moral";
    this.data = tipo_persona == 1 ? { id_cliente, tipo_persona, tipo_aval, empleado: false, nuevo_flujo: true, folio }: { folio, tipo_persona, tipo_aval };
    this.folio = folio;
    this.id_cliente = id_cliente;
    // Recuperamos el estado actual de Confirmación
    this.status_confirmacion = procesos.filter(
      (proc) => proc.id_proceso == 3
    )[0].status;
    console.log("Confirmacion: ", this.status_confirmacion);

    this.ObtenerStatus();
  }

  ObtenerStatus() {
    if (!this.userService.alertService.isOnline()) return;
    this.userService.ObtenerStatusDocuments(this.data).subscribe(
      (res) => {
        console.log(res);
        this.Documentos = res;
        this.documentos_necesarios =
          this.Documentos.DocumentosSolicitante.length +
          this.Documentos.DocumentosAval.length + 
          (this.perfil != 1 && this.Documentos.DocumentosVendor ? this.Documentos.DocumentosVendor.length : 0);

          if(this.perfil != 1 && this.Documentos.DocumentosVendor){
            this.Documentos.DocumentosSolicitante = [...this.Documentos.DocumentosSolicitante, ...this.Documentos.DocumentosVendor]
          }

          if (Number(this.perfil) === 4) {
          const result1 = this.Documentos.DocumentosSolicitante.filter(
            (doc: any) => {
              return doc.status === 2;
            }
          );
          console.log("result1 :", result1);
          const result2 = this.Documentos.DocumentosAval.filter((doc: any) => {
            return doc.status === 2;
          });
          console.log("result2 :", result2);
          this.cancelDoc = result1.length + result2.length > 0;
        }
        // if( this.data.tipo_persona == 2 ){
        //   this.Documentos.DocumentosSolicitanteLegal = this.Documentos.DocumentosSolicitante.splice(5)
        //   if( this.data.tipo_aval == 2 ){
        //     this.Documentos.DocumentosAvalLegal = this.Documentos.DocumentosAval.splice(5)
        //   }
        // }
        this.faltanDocumentos = false
        this._hayDocumentosSinCargar(this.Documentos.DocumentosSolicitante)
        this._hayDocumentosSinCargar(this.Documentos.DocumentosAval)
        this.DocumentosCompletos();
      },
      (err) => {
        this.dialog.open(MessageBoxComponent, {
          width: "500px",
          data: {
            text: "El servicio no está disponible por el momento",
            text3: "Vuelve a intentarlo.",
            type: "error",
            icon: "error",
          },
        });
      }
    );
  }

  VerDescripcion(descripcion) {
    this.dialog.open(InfoComponent, {
      width: "500px",
      data: {
        text: descripcion,
      },
    });
  }

  SeleccionarDocumento(doc, solicitante) {
    if (!this.userService.alertService.isOnline()) return;
    // const doc = (solicitante) ? this.Documentos.DocumentosSolicitante[idx] : this.Documentos.DocumentosAval[idx]

    if (doc.status == 3 || doc.status == 1) return;

    this.formData = new FormData();
    this.formData.append("folio", this.folio);
    this.formData.append("id_cliente", this.id_cliente.toString());
    this.formData.append("tipo_documento", doc.tipo_documento);
    this.formData.append("solicitante", solicitante);
    this.formData.append("id_usuario_mod", this.id_usuario);

    console.log(doc);

    const $document: any = document.querySelector("#document");
    $document.click();
  }

  SubirDocumento({ target: { files } }) {
    console.log("files-type");
    console.log("files :", files);
    if (!this.userService.alertService.isOnline()) return;
    const file = files[0];
    console.log("file :", file);

    if (file) {
      if (!this.allowed_type.includes(file.type)) {
        return this.dialog.open(MessageBoxComponent, {
          width: "500px",
          data: {
            text: "Documento no válido",
            text2: "Verifica que sea formato PDF",
            text3: "Vuelve a intentarlo",
            icon: "error",
            type: "error",
          },
        });
      }
      this.formData.append("document", file);
      console.log("this.formData :", this.formData);

      this.$loading = document.querySelector('.uploading').setAttribute('class', 'uploading show')
      this.userService.SubirDocumento(this.formData).subscribe(
        (res) => {
          console.log("res :", res);
          const $document = document.querySelector("#document");
          console.log($document.nodeValue);
          this.$loading = document.querySelector('.uploading').setAttribute('class', 'uploading hide')
          this.dialog.open(MessageBoxComponent, {
            minWidth: "500px",
            data: {
              text: "Se ha cargado correctamente el documento",
              text3: file.name,
              icon: "success",
              type: "success",
            },
          });
          // this.userService.changeStatus(this.folio, 2, 1) // ya se realiza desde el servicio
          this.ObtenerStatus();
        },
        (err) => {
          console.log("err :", err);
          this.dialog.open(MessageBoxComponent, {
            width: "400px",
            data: {
              text: "No se ha cargado el documento correctamente",
              ttext2: "Vuelve a intentarlo",
              type: "error",
              icon: "error",
            },
          });
        }
      );
    }
  }

  resset = (event) => {
    event.target.value = null;
  };
  VerDocumento(documento, solicitante) {
    console.log("Ver: ", documento, solicitante);

    if (this.userService.alertService.isOnline()) {
      const { tipo_documento, status } = documento;
      localStorage.setItem("documento", JSON.stringify(documento));

      if (status != 3 && tipo_documento == 3 && solicitante) {
        localStorage.setItem("tipo_documento", tipo_documento);
        return this.router.navigate(["dashboard/comercial/validacionId"]);
      }

      if (documento.url) {
        if (
          (documento.tipo_documento == 3 || documento.tipo_documento == 9) &&
          solicitante
        ) {
          this.router.navigate([
            "/dashboard",
            "comite",
            "valuacion",
            "validacionId",
          ]);
        } else {
          this.router.navigate([
            "/dashboard",
            "cliente",
            this.folio,
            "ver_documento",
          ]);
        }
      }
    }
  }

  VerComentario(documento, solicitante) {
    if (documento.comentarios) {
      this.dialog.open(ComentariosComponent, {
        width: "550px",
        data: {
          text: documento.comentarios,
          ver_comentario: true,
        },
      });
    }
  }

  DescargarDocumento(documento, solicitante) {
    if (!this.userService.alertService.isOnline()) return;

    if (documento.url) {
      window.open(documento.url, "Download");
    }
  }

  AceptarDocumento(documento, solicitante: boolean, legal = false) {
    if (!this.userService.alertService.isOnline()) return;
    // const documento = !legal ?
    //  (solicitante
    //   ? this.Documentos.DocumentosSolicitante[idx]
    //   : this.Documentos.DocumentosAval[idx])
    //   : (solicitante
    //   ? this.Documentos.DocumentosSolicitanteLegal[idx]
    //   : this.Documentos.DocumentosAvalLegal[idx])

    if (documento.status != 1 || documento.status == 3) return;

    this.userService
      .CambiarStatusDocumento({
        folio: this.folio,
        tipo_documento: documento.tipo_documento,
        solicitante,
        status: 3,
      })
      .subscribe(
        (res) => {
          this.ObtenerStatus();
        },
        (err) => console.log(err)
      );
  }

  RechazarDocumento(documento, solicitante, legal = false) {
console.log('alto!');
console.log(documento);

    if (!this.userService.alertService.isOnline()) return;
    // const documento = !legal ?
    //  (solicitante
    //   ? this.Documentos.DocumentosSolicitante[idx]
    //   : this.Documentos.DocumentosAval[idx])
    //   : (solicitante
    //   ? this.Documentos.DocumentosSolicitanteLegal[idx]
    //   : this.Documentos.DocumentosAvalLegal[idx])

    if (documento.status == 1) {
      console.log("Rechazar");
      const dialogRef = this.dialog.open(RechazarDocumentoComponent, {
        width: "550px",
        height: "350px",
      });

      dialogRef.afterClosed().subscribe(
        (comentarios) => {
          console.log(comentarios);
          this.userService
            .CambiarStatusDocumento({
              folio: this.folio,
              tipo_documento: documento.tipo_documento,
              solicitante,
              status: 2,
              comentarios,
            })
            .subscribe(
              (res) => {
                // this.userService.changeStatus(this.folio, 2, 2)
                this.ObtenerStatus();
              },
              (err) => {
                console.log(err);
                this.dialog.open(MessageBoxComponent, {
                  width: "500px",
                  data: {
                    text: "El servicio no está disponible por el momento",
                    text3: "Vuelve a intentarlo.",
                    type: "error",
                    icon: "error",
                  },
                });
                this.ObtenerStatus();
              }
            );
        },
        (err) => console.log(err)
      );
    } else {
      console.log('Listo???');
      
      if (documento.status === 3 && this.rechazar_documentos.includes(Number(this.perfil))) {
        console.log("Rechazar");
        const dialogRef = this.dialog.open(RechazarDocumentoComponent, {
          width: "550px",
          height: "350px",
        });

        dialogRef.afterClosed().subscribe(
          (comentarios) => {
            console.log(comentarios);
            this.userService
              .CambiarStatusDocumento({
                folio: this.folio,
                tipo_documento: documento.tipo_documento,
                solicitante,
                status: 2,
                comentarios,
              })
              .subscribe(
                (res) => {
                  this.ObtenerStatus();
                },
                (err) => {
                  console.log(err);
                  this.dialog.open(MessageBoxComponent, {
                    width: "500px",
                    data: {
                      text: "El servicio no está disponible por el momento",
                      text3: "Vuelve a intentarlo.",
                      type: "error",
                      icon: "error",
                    },
                  });
                  this.ObtenerStatus();
                }
              );
          },
          (err) => console.log(err)
        );
      } else {
        console.log('No tienes permisos');
        
      }
    }
  }

  DocumentosCompletos() {
    if (!this.userService.alertService.isOnline()) return;
    this.completado = true
    let status = 0;
    console.log("Empezamos");
    
    this.Documentos.DocumentosSolicitante.forEach(
      (documento) => {
        console.log(`Requerido: ${documento.requerido} Status: ${documento.status}`);
        if (documento.requerido == true && documento.status != 3) {
          this.completado = false
        }
        // (status += documento.status)
        this.solicitud_prellenada = documento.tipo_documento == 21 && documento.url.length > 0
      }
    );
    this.Documentos.DocumentosAval.forEach(
      (documento) => {
        // (status += documento.status)
        if (documento.requerido == true && documento.status != 3) {
          this.completado = false
        }
      }
    );

    if (this.Documentos.DocumentosSolicitanteLegal)
      this.Documentos.DocumentosSolicitanteLegal.forEach(
        (documento) => {
          // (status += documento.status)
          if (documento.requerido == true && documento.status != 3) {
            this.completado = false
          }
          this.solicitud_prellenada = documento.tipo_documento == 21 && documento.url.length > 0
        }
      );
    if (this.Documentos.DocumentosAvalLegal)
      this.Documentos.DocumentosAvalLegal.forEach(
        (documento) => {
          // (status += documento.status)
          if (documento.requerido == true && documento.status != 3) {
            this.completado = false
          }
        }
      );

      if (!this.solicitud_prellenada) {
        this.documentos_necesarios -= 1
      }
    // console.log(this.documentos_necesarios * 3, status, this.solicitud_prellenada);

    // this.completado = this.documentos_necesarios * 3 == status;
  }

  CompletarExpediente() {
    if (!this.userService.alertService.isOnline()) return;
    this.loading = true;
    this.userService.changeStatus(this.folio, 2, 3).subscribe((res) => {
      // dejamos el estatus del proceso Confirmación con su estado actual
      this.userService
        .changeStatus(this.folio, 3, this.status_confirmacion)
        .subscribe((res) => null);
      this.userService
        .DeleteProcessComite({
          folio: this.folio,
          id_cliente: this.id_cliente,
        })
        .subscribe(
          (res2: any) => {
            const dialogref = this.dialog.open(MessageBoxComponent, {
              width: "500px",
              data: {
                text: "Éxito",
                text3: "Se ha completado el expediente",
                icon: "success",
                type: "success",
              },
            });
            dialogref
              .afterClosed()
              .subscribe((_) => this.router.navigate(["dashboard/solicitudes"]));
          },
          (err2: any) => {
            console.error("err2 :", err2);
            this.dialog.open(MessageBoxComponent, {
              width: "500px",
              data: {
                text: "El servicio no está disponible por el momento",
                text3: "Vuelve a intentarlo.",
                type: "error",
                icon: "error",
              },
            });
          }
        );
    });
  }

  async ValidarDocumentos() {
    this.validandoDocumentos = true
    await this._validarDocumento(this.Documentos.DocumentosSolicitante, true)
    await this._validarDocumento(this.Documentos.DocumentosAval, false)
    console.log('Finalizacion de validación');
    this.ObtenerStatus();
    this.validandoDocumentos = false
    this.dialog.open(MessageBoxComponent, {
      width: "500px",
      data: {
        text: "Validación de documentos",
        text3: "Se han validado todos los documentos cargados",
        type: "success",
        icon: "success",
      },
    });
  }

  public NextCancelarDocumento(): void {
    if( !this.userService.alertService.isOnline() ) return
    this.userService
      .DeleteProcessState({
        folio: this.folio,
        id_cliente: this.id_cliente,
      })
      .subscribe(
        (res: any) => {
          console.log(res);
          this.dialog.open(MessageBoxComponent, {
            width: "500px",
            data: {
              text: "Éxito",
              text2: "Se han rechazado los documentos de forma correcta.",
              type: "success",
              icon: "success"
            }
          }).afterClosed().subscribe( () => this.router.navigateByUrl(`/dashboard`) )
        },
        (err: any) => {
          console.error("err :", err);
          this.dialog.open(MessageBoxComponent, {
            width: "500px",
            data: {
              text: "El servicio no está disponible por el momento",
              text3: "Vuelve a intentarlo.",
              type: "error",
              icon: "error",
            },
          });
        }
      );
  }

  logout() {
    this.authenticationService.logout();
    this.router.navigate(["/login"]);
  }

  private async _validarDocumento(documentos, solicitante: boolean) {
    for (const documento of documentos) {
      if ([1,2].includes(documento.status) && documento.tipo_documento != 3 ) {
        console.log(`Validar documento: ${documento.tipo_documento} ${documento.status}`);
        await this.userService.CambiarStatusDocumento({
          folio: this.folio,
          tipo_documento: documento.tipo_documento,
          solicitante,
          status: 3,
        }).toPromise()
      } else if ([1,2].includes(documento.status) && documento.tipo_documento == 3) {
        await this.userService.ValidacionComercial(this.folio, documento.tipo_documento, true, 'VALIDACION AUTOMATICA').toPromise()
      }
    }
  }

  private _hayDocumentosSinCargar(documentos) {
    if (!this.faltanDocumentos) {
      for (const documento of documentos) {
        if (documento.tipo_documento != 21 && documento.status == 0) {
          this.faltanDocumentos = true
          break
        }
      }
    }
  }
  // async downloadAllDocuments() {
  //   const zip = new JSZip();
  
  //   for (const doc of this.Documentos.DocumentosSolicitante) {
  //     if (doc.url != "") {
  //       const proxyUrl = `https://cors-anywhere.herokuapp.com/${doc.url}`;
  //       try {
  //         const response = await this.http.get(proxyUrl, { responseType: 'blob' }).toPromise();
  //         zip.file(doc.nombre_documento + '.pdf', response);
  //       } catch (error) {
  //         console.error(`Error downloading ${doc.nombre_documento}:`, error);
  //       }
  //     }
  //   }
  
  //   try {
  //     const content = await zip.generateAsync({ type: 'blob' });
  //     saveAs(content, 'documentos.zip');
  //   } catch (error) {
  //     console.error('Error generating ZIP file:', error);
  //   }
  // }
  
}
