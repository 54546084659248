import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MessageBoxComponent } from 'src/app/_dialogs/message-box/message-box.component';
import { SolicitudService } from 'src/app/_services';

@Component({
  selector: 'app-relacion-patrimonial',
  templateUrl: './relacion-patrimonial.component.html',
  styleUrls: ['./relacion-patrimonial.component.scss']
})
export class RelacionPatrimonialComponent implements OnInit {

  activos=[{ descripcion: '', ubicacion: '', valorcomercial: 0 }]
  pasivos=[{ tipo: '', institucion: '', fechaInicio: '', monto: 0, plazo: 0 }]

  totalActivo:number=0;
  totalPasivo:number=0;
  estado_civil:string='SOLTERO'
  regimen_matrimoanial:string=null
  folio:string=null;

  constructor(
    private dialogRef :MatDialogRef<RelacionPatrimonialComponent>,
     @Inject(MAT_DIALOG_DATA) public data,
     private dialog: MatDialog,
     private solicitudService: SolicitudService
  ) { }

  ngOnInit(): void {
    this.totalActivos();
    this.folio=this.data?.folio;

    this.obtenerInformacion();
  }
  agregarActivo(){
    this.activos.push({ descripcion: '', ubicacion: '', valorcomercial: 0 });
  }
  agregarPasivo() {
    this.pasivos.push({ tipo: '', institucion: '', fechaInicio: '', monto: 0, plazo: 0 });
  }
  eliminarActivo(index: number) {
    this.activos.splice(index, 1);
    this.totalActivos();
  }
  eliminarPasivo(index: number) {
    this.pasivos.splice(index, 1);
    this.totalPasivos();
  }
  totalActivos(){
    this.totalActivo = this.activos.reduce((total, activo) => total + activo.valorcomercial, 0);
    console.log( this.totalActivo)
  }
  totalPasivos(){
    this.totalPasivo = this.pasivos.reduce((total, pasivo) => total + pasivo.monto, 0);
    console.log( this.totalPasivo)
  }
  async guardarInformacion(){
    console.log(this.activos)
    console.log(this.pasivos)

    const relacionPatrimonial={
      activos:this.activos,
      pasivos:this.pasivos,
      totalActivos:this.totalActivo,
      totalPasivos:this.totalPasivo,
      estadoCivil:this.estado_civil,
      regimenMatrimonial:this.regimen_matrimoanial
    }
    console.log({relacionPatrimonial})
     try {
          const response: any = await this.solicitudService.GuardarSolicitud(this.folio, { relacionPatrimonial });
          if (response.statusCode === 200) {
            this.dialog.open(MessageBoxComponent, {
              width: "500px",
              data: {
                text: "Éxito",
                text3: "Tu información se ha guardado con éxito.",
                icon: "success",
                type: "success"
              }
            }).afterClosed().subscribe(()=>{
              this.salir();
            });
          } else {
            this.dialog.open(MessageBoxComponent, {
              width: "500px",
              data: {
                text: "Error",
                text3: "No se pudo guardar la información.",
                icon: "error",
                type: "error"
              }
            });
          }
        } catch (error) {
          console.error("Error al guardar la solicitud :::::", error);
          this.dialog.open(MessageBoxComponent, {
            width: "500px",
            data: {
              text: "Error",
              text3: "Ocurrió un error al procesar la solicitud.",
              icon: "error",
              type: "error"
            }
          });
        }
  }

  async obtenerInformacion(){
    try {
      const data = await this.solicitudService.ObtenerSolicitud(this.folio);

      if(data?.relacionPatrimonial){
        console.log('---',data);

        this.estado_civil= data?.relacionPatrimonial.estadoCivil;
        this.totalActivo= data?.relacionPatrimonial.totalActivos;
        this.totalPasivo=  data?.relacionPatrimonial.totalPasivos;
        this.regimen_matrimoanial =  data?.relacionPatrimonial.regimenMatrimonial;

        this.activos =data?.relacionPatrimonial.activos?.map(activo => ({
          descripcion: activo.descripcion || '',
          ubicacion: activo.ubicacion || '',
          valorcomercial: activo.valorcomercial || 0
        })) || [];
        
        this.pasivos = data?.relacionPatrimonial.pasivos?.map(pasivo => ({
          tipo: pasivo.tipo || '',
          institucion: pasivo.institucion || '',
          fechaInicio: pasivo.fechaInicio || '',
          monto: pasivo.monto || 0,
          plazo: pasivo.plazo || 0
        })) || [];
        
      }

    } catch (error) {
      console.error(error)
    }
  }

  onEstadoCivilChange(event :any){
    this.estado_civil=event.value
  }

  salir(){
    this.dialogRef.close()
  }
}
