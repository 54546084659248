import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { MessageBoxComponent } from 'src/app/_dialogs/message-box/message-box.component';
import { AuthenticationService } from 'src/app/_services';
import { VendorsService } from 'src/app/_services/vendors.service';

@Component({
  selector: 'app-vendors-edit',
  templateUrl: './vendors-edit.component.html',
  styleUrls: ['./vendors-edit.component.scss']
})
export class VendorsEditComponent implements OnInit {
  PAISES = [
    { code: 'MX', name: 'México'},
    { code: 'PE', name: 'Perú'},
  ]

  currentUser: any = {}
  perfil = 0
  id = 0
  is_loading = false
  image_preview: any = 'https://leaseforu-assets.s3.us-east-2.amazonaws.com/assets/images/no_image.png'
  formData = null
  puede_guardar = [27]
  puede_validar = [15]

  puede_cambiar_nombre = [27]
  puede_cambiar_logo = [27]
  puede_cambiar_configuracion = [27]
  usuario_puede_cambiar_configuracion =[5853]
  puede_cambiar_residuales = [27]
  puede_cambiar_tasa_ap = [27]
  puede_cambiar_tasa_msi = [27]

  puede_ver_vendedores = [3,6,10,12,18,25,23,27,28]

  Vendor: any = {
    id: 0,
    fullname: '',
    descuento_ciego: 1,
    factor: 0,
    valores_residuales: {},
    tasa_mensual_financiamiento: {},
    tasa_mensual_msi: {},
    annual_insurance_amount: 0,
    vendor_payment: 0,
    commission_for_opening_type: 'a',
    commission_for_opening: 0,
    cotizar_auto: false,
    country: 'MX',
    commission_for_opening_type_msi: 'a',
    commission_for_opening_msi: 0,
    id_vendor: '',
    comision_vendor: 0,
    comision_activacion: 0,
    cofi_id: '0',
  }

  tasa_mensual_financiamiento = 0
  tasa_mensual_msi = 0

  vista = 1

  listaDocumentos = {
    documentosSolicitantePF: [],
    documentosSolicitantePM: [],
    documentosAvalPF: [],
    documentosAvalPM: [],
  }
  listaInputs = {
    camposReferenciasPf: [],
    camposReferenciasPm: [],
    camposDatosgeneralesPf: [],
    camposDatosgeneralesPm: [],
    camposAvalPf: [],
    camposAvalPm: [],
  }

  porcentajes= [
    {
      plazo: "plazo6",
      label: "6",
      porcentajes: [
        10,
        15,
        20,
        25,
        30,
        35,
        40,
        45,
        50
      ]
    },
    {
      plazo: "plazo9",
      label: "9",
      porcentajes: [
        10,
        15,
        20,
        25,
        30,
        35,
        40,
        45,
        50
      ]
    },
    {
      plazo: "plazo12",
      label: "12",
      porcentajes: [
        10,
        15,
        20,
        25,
        30,
        35,
        40,
        45,
        50
      ]
    },
    {
      plazo: "plazo15",
      label: "15",
      porcentajes: [
        10,
        15,
        20,
        25,
        30,
        35,
        40,
        45,
        50
      ]
    },
    {
      plazo: "plazo18",
      label: "18",
      porcentajes: [
        10,
        15,
        20,
        25,
        30,
        35,
        40,
        45,
        50
      ]
    },
    {
      plazo: "plazo21",
      label: "21",
      porcentajes: [
        10,
        15,
        20,
        25,
        30,
        35,
        40,
        45,
        50
      ]
    },
    {
      plazo: "plazo24",
      label: "24",
      porcentajes: [
        10,
        15,
        20,
        25,
        30,
        35,
        40,
        45,
        50
      ]
    }
  ]

  pago_inicial = {
    gracia0: {
      plazo6: [
        6.92,
        6.53,
        6.15,
        5.76,
        5.38,
        4.99,
        4.61,
        4.23,
        3.84
      ],
      plazo9: [
        9.66,
        9.12,
        8.59,
        8.05,
        7.51,
        6.98,
        6.44,
        5.9,
        5.37
      ],
      plazo12: [
        12.28,
        11.6,
        10.92,
        10.24,
        9.55,
        8.87,
        8.19,
        7.51,
        6.82
      ],
      plazo15: [
        14.79,
        13.97,
        13.15,
        12.33,
        11.51,
        10.68,
        9.86,
        9.04,
        8.22
      ],
      plazo18: [
        17.19,
        16.24,
        15.28,
        14.33,
        13.37,
        12.42,
        11.46,
        10.51,
        9.55
      ],
      plazo21: [
        19.48,
        18.4,
        17.32,
        16.24,
        15.15,
        14.07,
        12.99,
        11.91,
        10.82
      ],
      plazo24: [
        21.68,
        20.48,
        19.27,
        18.07,
        16.86,
        14.91,
        14.45,
        13.25,
        12.04
      ]
    },
    "gracia1": {
      plazo6: [
        8.81,
        8.32,
        7.83,
        7.34,
        6.85,
        6.36,
        5.87,
        5.38,
        4.89
      ],
      plazo9: [
        11.49,
        10.85,
        10.22,
        9.58,
        8.94,
        8.3,
        7.66,
        7.02,
        6.38
      ],
      plazo12: [
        14.06,
        13.28,
        12.49,
        11.71,
        10.93,
        10.15,
        9.37,
        8.59,
        7.81
      ],
      plazo15: [
        16.51,
        15.59,
        14.67,
        13.76,
        12.84,
        11.92,
        11.01,
        10.09,
        9.17
      ],
      plazo18: [
        18.85,
        17.8,
        16.76,
        15.71,
        14.66,
        13.61,
        12.57,
        11.52,
        10.47
      ],
      plazo21: [
        21.09,
        19.92,
        18.75,
        17.58,
        16.41,
        15.23,
        14.06,
        12.89,
        11.72
      ],
      plazo24: [
        23.24,
        21.95,
        20.66,
        19.36,
        18.07,
        16.78,
        15.49,
        14.2,
        12.91
      ]
    },
    "gracia2": {
      plazo6: [
        10.66,
        10.07,
        9.48,
        8.88,
        8.29,
        7.7,
        7.11,
        6.52,
        5.92
      ],
      plazo9: [
        13.28,
        12.54,
        11.81,
        11.07,
        10.33,
        9.59,
        8.86,
        8.12,
        7.38
      ],
      plazo12: [
        15.79,
        14.91,
        14.03,
        13.16,
        12.28,
        11.4,
        10.53,
        9.65,
        8.77
      ],
      plazo15: [
        18.18,
        17.17,
        16.16,
        15.15,
        14.14,
        13.13,
        12.12,
        11.11,
        10.1
      ],
      plazo18: [
        20.47,
        19.34,
        18.2,
        17.06,
        15.92,
        14.79,
        13.65,
        12.51,
        11.37
      ],
      plazo21: [
        22.66,
        21.4,
        20.15,
        18.89,
        17.63,
        16.37,
        15.11,
        13.85,
        12.59
      ],
      plazo24: [
        24.76,
        23.38,
        22.01,
        20.63,
        19.26,
        17.88,
        16.51,
        15.13,
        13.76
      ]
    }
  }
  plazosSeleccionados = []
  plazosFijos = [6, 9, 12, 15, 18, 21, 24, 36, 48, 60];
  porcentajesEditables = this.pago_inicial.gracia0;

 
  nuevoCF: number = 0; 

  nuevoPlazo: string = '';
  nuevoPorcentaje: number | null = null;

  constructor(
    private authenticationService: AuthenticationService,
    private router: Router,
    private vendorService: VendorsService,
    private dialog: MatDialog,
    private location: Location
    ) { }
 
  
  ngOnInit(): void {
    this.currentUser = JSON.parse(localStorage.getItem('currentUser'))
    this.Vendor = JSON.parse(localStorage.getItem('vendor'))
    this.Vendor.valores_residuales = JSON.parse(this.Vendor.valores_residuales) || {}
    // this.Vendor.tasa_mensual_financiamiento = JSON.parse(this.Vendor.tasa_mensual_financiamiento) || {}
    this.Vendor.tasa_mensual_msi = JSON.parse(this.Vendor.tasa_mensual_msi) || {}
    this.image_preview = this.Vendor.url_image || 'https://leaseforu-assets.s3.us-east-2.amazonaws.com/assets/images/no_image.png'

    if (!this.Vendor.country) this.Vendor.country = 'MX'
    if (this.Vendor.cotizar_auto === null) this.Vendor.cotizar_auto = false

    // this.Vendor.commission_for_opening_type = this.Vendor.country == 'PE' ? 'p' : this.Vendor.commission_for_opening_type

    this.perfil = Number(this.currentUser.perfil)
    this.id = Number(this.currentUser.id)
    
    console.log(this.Vendor);

    this.tasa_mensual_financiamiento = this.Vendor.tasa_mensual_financiamiento.ap12
    this.tasa_mensual_msi = this.Vendor.tasa_mensual_msi.msi6
    this.Vendor.comision_vendor = this.Vendor.comision_vendor * 100
    this.Vendor.comision_activacion = this.Vendor.comision_activacion * 100
    console.log(`Perfil: ${this.currentUser.perfil}`);

    this.listaDocumentos.documentosSolicitantePF = this.Vendor.documentos_solicitante_pf.split(',').map(Number)
    this.listaDocumentos.documentosSolicitantePM = this.Vendor.documentos_solicitante_pm.split(',').map(Number)
    this.listaDocumentos.documentosAvalPF = this.Vendor.documentos_aval_pf.split(',').map(Number)
    this.listaDocumentos.documentosAvalPM = this.Vendor.documentos_aval_pm.split(',').map(Number)

    this.listaInputs.camposReferenciasPf = this.Vendor.campos_referencias_pf.split(',').map(Number)
    this.listaInputs.camposReferenciasPm =  this.Vendor.campos_referencias_pm != null ? this.Vendor.campos_referencias_pm.split(',').map(Number) : []
    this.listaInputs.camposDatosgeneralesPf = this.Vendor.campos_datosgenerales_pf.split(',').map(Number)
    this.listaInputs.camposDatosgeneralesPm = this.Vendor.campos_datosgenerales_pm.split(',').map(Number)
    this.listaInputs.camposAvalPf = this.Vendor.campos_aval_pf.split(',').map(Number)
    this.listaInputs.camposAvalPm = this.Vendor.campos_aval_pm.split(',').map(Number)

    console.log(`ID: ${this.id}`);
    console.log("PUEDE CONFITG", this.usuario_puede_cambiar_configuracion.includes(this.id))

  }
 // Agregar un nuevo plazo
 agregarPlazo() {
  if (this.nuevoPlazo && !this.porcentajes.find(p => p.label === this.nuevoPlazo)) {
    const nuevoPlazoLabel = `plazo${this.nuevoPlazo}`;
    this.porcentajes.push({
      plazo: nuevoPlazoLabel,
      label: this.nuevoPlazo,
      porcentajes: [10, 15, 20, 25, 30, 35, 40, 45, 50]
    });

    // Inicializar valores de pago_inicial para el nuevo plazo con 0
    this.pago_inicial.gracia0[nuevoPlazoLabel] = Array(9).fill(0);
    this.pago_inicial.gracia1[nuevoPlazoLabel] = Array(9).fill(0);
    this.pago_inicial.gracia2[nuevoPlazoLabel] = Array(9).fill(0);

    // Ordenar los plazos
    // this.ordenarPlazos();
    this.nuevoPlazo = '';
  }
}

agregarPorcentaje() {
  if (this.nuevoPorcentaje && !this.porcentajes[0].porcentajes.includes(this.nuevoPorcentaje)) {
    // Agregar el nuevo porcentaje a la lista y ordenar
    this.porcentajes.forEach(p => p.porcentajes.push(this.nuevoPorcentaje));
    // this.ordenarPorcentajes();

    // Añadir un valor 0 para el nuevo porcentaje en cada plazo en pago_inicial
    Object.keys(this.pago_inicial).forEach(gracia => {
      Object.keys(this.pago_inicial[gracia]).forEach(plazo => {
        this.pago_inicial[gracia][plazo].push(0);
      });
    });

    // Reordenar los valores en pago_inicial para cada plazo según el nuevo orden de porcentajes
    this.nuevoPorcentaje = null;
  }
}
alinearValoresConPorcentajes() {
  Object.keys(this.pago_inicial).forEach(gracia => {
    Object.keys(this.pago_inicial[gracia]).forEach(plazo => {
      // Obtiene el arreglo de valores del plazo actual
      const valores = this.pago_inicial[gracia][plazo];

      // Reordena los valores según el nuevo orden de porcentajes
      const valoresOrdenados = this.porcentajes[0].porcentajes.map(porcentaje => {
        const index = this.porcentajes[0].porcentajes.indexOf(porcentaje);
        return valores[index] !== undefined ? valores[index] : 0;
      });

      // Actualiza el arreglo de valores en el orden correcto
      this.pago_inicial[gracia][plazo] = valoresOrdenados;
    });
  });
}

// Nuevo método para ordenar valores en pago_inicial
ordenarValoresPorcentajes() {
  Object.keys(this.pago_inicial).forEach(gracia => {
    Object.keys(this.pago_inicial[gracia]).forEach(plazo => {
      // Ordena los valores en cada plazo en función del orden de porcentajes
      this.pago_inicial[gracia][plazo] = this.porcentajes[0].porcentajes
        .map((porcentaje, index) => this.pago_inicial[gracia][plazo][index] || 0);
    });
  });
}



// Ordenar plazos de menor a mayor
ordenarPlazos() {
  this.porcentajes.sort((a, b) => +a.label - +b.label);
}

// Ordenar porcentajes de menor a mayor
ordenarPorcentajes() {
  this.porcentajes.forEach(p => p.porcentajes.sort((a, b) => a - b));
}

// Método para guardar los datos en el formato original
guardarDatos() {
  // Aquí puedes implementar la lógica para enviar los datos al servidor o realizar otras acciones
  console.log('Datos guardados:', { porcentajes: this.porcentajes });
  console.log('Datos guardados:', {  pago_inicial: this.pago_inicial });
  this.ordenarPorcentajes();
  this.alinearValoresConPorcentajes();

}
eliminarPlazo(label: string) {
  // Eliminar el plazo del array porcentajes
  this.porcentajes = this.porcentajes.filter(item => item.label !== label);

  // Eliminar el plazo de cada nivel de gracia en pago_inicial
  delete this.pago_inicial.gracia0[`plazo${label}`];
  delete this.pago_inicial.gracia1[`plazo${label}`];
  delete this.pago_inicial.gracia2[`plazo${label}`];
}
actualizarPlazo(item: any) {
  item.plazo = 'plazo' + item.label;
}


  ImagePreview() {
    const vendor_image:any = document.querySelector("input[name='vendor_image']")
    const file = vendor_image.files[0]

    console.log("Seleccion de archivos");

    var reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      this.image_preview = reader.result
    };
    reader.onerror = function (error) {
      console.log('Error: ', error);
    };
  }
  

  GuardarVendor() {
    this.is_loading = true


    this.formData = new FormData();
    const vendor_image:any = document.querySelector("input[name='vendor_image']")

    if (this.puede_cambiar_logo.includes(this.perfil)) {
      const file = vendor_image.files[0]
  
      if (file) {
        console.log(file);
        this.formData.append("image_vendor", file);
      }
    }
    
    this.formData.append("user_id", this.Vendor.user_id)
    this.formData.append("name", this.Vendor.fullname)
    this.formData.append("descuento_ciego", `${this.Vendor.descuento_ciego}`)
    this.formData.append("factor", this.Vendor.factor)
    this.formData.append("valores_residuales", JSON.stringify(this.Vendor.valores_residuales))
    this.formData.append("tasa_mensual_financiamiento", JSON.stringify(this.Vendor.tasa_mensual_financiamiento))
    this.formData.append("tasa_mensual_msi", JSON.stringify(this.Vendor.tasa_mensual_msi))
    this.formData.append("url_image", this.Vendor.url_image || null )
    this.formData.append("annual_insurance_amount", this.Vendor.annual_insurance_amount || 0 )
    this.formData.append("vendor_payment", this.Vendor.vendor_payment || 0 )
    this.formData.append("commission_for_opening_type", this.Vendor.commission_for_opening_type || 0 )
    this.formData.append("commission_for_opening", this.Vendor.commission_for_opening || 0 )
    this.formData.append("commission_for_opening_type_msi", this.Vendor.commission_for_opening_type_msi || 0 )
    this.formData.append("commission_for_opening_msi", this.Vendor.commission_for_opening_msi || 0 )
    this.formData.append("cotizar_auto", `${this.Vendor.cotizar_auto || false}` )
    this.formData.append("country", `${this.Vendor.country || 'MX'}` )
    this.formData.append("id_vendor", `${this.Vendor.id_vendor}` )
    this.formData.append("comision_vendor", `${this.Vendor.comision_vendor / 100}` )
    this.formData.append("comision_activacion", `${this.Vendor.commission_for_opening / 100}` )
    this.formData.append("cofi_id", `${this.Vendor.cofi_id}` )
    this.formData.append("id_usuario_modif", `${this.currentUser.id}` )
    this.formData.append("nombre_usuario_modif", `${this.currentUser.nombre_usuario}` )

    this.vendorService.GuardarVendor(this.formData).subscribe( res => {
      console.log(res);
      this.ShowDialog('success', `Se ha actualizado el Vendor con éxito`, '', true)
      this.is_loading = false
    })
  }

  ChangeDescuentoCiego() {
    this.Vendor.descuento_ciego = this.Vendor.descuento_ciego ? false : true
  }

  ChangeCotizarAuto() {
    this.Vendor.cotizar_auto = this.Vendor.cotizar_auto ? false : true
  }

  ShowDialog(type, text, text2 = '', home = false) {
    const dialog = this.dialog.open(MessageBoxComponent, {
      width: "500px",
      data: {
        text,
        text2,
        type,
        icon: type,
      },
    }).afterClosed().subscribe(res => {
      if (home) {
        this.location.back()
      }
    })
  }

  ResetCommission() {
    this.Vendor.commission_for_opening = 0
  }
  ResetCommissionMsi() {
    this.Vendor.commission_for_opening_msi = 0
  }

  ValidarVendor() {
    console.log(`Validar vendor ${this.Vendor.id}`);
    console.log(this.tasa_mensual_msi);
    
    this.ShowDialog('success', "Se ha validado el vendor exitosamente")
  }

  ActualizarTasa(tipo) {
    switch(tipo) {
      case 1:
        this.Vendor.tasa_mensual_financiamiento = {
          ap12: this.tasa_mensual_financiamiento || 0,
          ap18: this.tasa_mensual_financiamiento || 0,
          ap24: this.tasa_mensual_financiamiento || 0,
          ap36: this.tasa_mensual_financiamiento || 0,
          ap48: this.tasa_mensual_financiamiento || 0,
          ap60: this.tasa_mensual_financiamiento || 0
        }
        break;
      case 2:
        this.Vendor.tasa_mensual_msi = {
          msi6: this.tasa_mensual_msi || 0,
          msi9: this.tasa_mensual_msi || 0,
          msi12: this.tasa_mensual_msi || 0,
          msi15: this.tasa_mensual_msi || 0,
          msi18: this.tasa_mensual_msi || 0,
          msi21: this.tasa_mensual_msi || 0,
          msi24: this.tasa_mensual_msi || 0
        }
        break;
    }
  }
  
  logout() {
    this.authenticationService.logout();
    this.router.navigate(['/login']);
  }
  ResetPassword() {
    this.is_loading = true
    this.vendorService.ResetPassword(this.Vendor.email).subscribe(
      res => {
        this.is_loading = false
        this.ShowDialog("success", "Se ha cambiado la contraseña con exito")
      },
      err => {
        this.is_loading = false
        this.ShowDialog("error", "Ocurrió un error al cambiar la contraseña")
      }
    )
  }
}


