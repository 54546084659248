<!-- <app-sidenav-v2 [shortMenu]="true"> -->
  <div class="container">
    <!-- <div class="top">
      <app-go-bak style="padding: 5px; margin-top: 10px;"></app-go-bak>
      <div class="title">Solicitud</div>
    </div> -->
    <div>

      <div class="container-form-step">
        <app-stepper-nueva-solicitud [currentStep]="currentStep" *ngIf="perfil==1"></app-stepper-nueva-solicitud>
      </div>
      <div class="btn-validacion" *ngIf="!flujo_cotizador">
        <button class="val-btn" (click)="abrir()"> Validar Info</button>
      </div>
      <div class="btn-validacion" *ngIf="flujo_cotizador">
        <button class="val-btn" (click)="siguiente()"> Siguiente</button>
      </div>
      <div class="btn-validacion" *ngIf="false">
        <button class="val-btn" (click)="relacionPatrimonial()"> Relacion Patrimonial</button>
      </div>

      <!-- Datos del solicitante -->
      <div class="document-section-solicitante">
        <label class="section-title">Datos del solicitante</label>
        <div class="document-solicitud">
          <input type="file" id="document" (click)="resset($event)" accept="application/pdf"
            (change)="SubirDocumento($event)" hidden />
          <app-documents-new *ngFor="let documento of Documentos.DocumentosSolicitante; let i = index"
            [documento]="documento" [index]="i" [solicitante]="true" [isUploading]="isUploading" style="margin: 10px 0"
            [show]="true" (Upload)="SeleccionarDocumento(documento, true)"
            (VerDocumento)="VerDocumento(documento, true)"></app-documents-new>
          <input type="file" id="document_est_cuenta" (click)="resset($event)" accept="application/pdf"
            (change)="SubirDocumento($event)" hidden />
          <ng-container>
            <app-comprobante-new *ngFor="let documento of Documentos.DocumentoComprobanteDomicilio; let i = index"
              [documento]="documento" [index]="i" [solicitante]="true" style="margin: 10px 0" [show]="true"
              (Upload)="SeleccionarDocumento(documento, true)" (VerDocumento)="VerDocumento(documento, true)"
              (openDialogo)="openGoogleMapsDialog(documento)" [geoLocalizacion]="geoLocalizacion"
              [geoLocalizacionActivo]="geoLocalizacionActivo">
            </app-comprobante-new>
          </ng-container>
          <ng-container *ngIf="documentos_estados_cuenta">
            <app-documents-estado-cuenta-new [documentos]="documentos_estados_cuenta" [solicitante]="true"
              style="margin: 10px 0" (Upload)="SeleccionarDocumento($event, true)"
              (VerDocumento)="VerDocumento($event, true)"></app-documents-estado-cuenta-new>
          </ng-container>
        </div>
      </div>

      <!-- Datos del aval -->
      <div class="document-section-aval">
        <label class="section-title">Datos del aval</label>
        <div class="document-aval">
          <div class="omitir-aval">
            <p><mat-slide-toggle  [(ngModel)]="omitirAval" (change)="onSlideToggleChange($event)">Omitir aval</mat-slide-toggle></p>
          </div>
          <div *ngIf="!omitirAval" class="documentos">
            <input type="file" id="documentaval" (click)="resset($event)" accept="application/pdf"
            (change)="SubirDocumentoAval($event)" hidden />
          <app-documents-new *ngFor="let documento of Documentos.DocumentosAval; let i = index" [documento]="documento"
            [index]="i" [solicitante]="documento.solicitante" style="margin: 10px 0" [show]="
                      (documento && documento.tipo_documento != 21) ||
                      (documento.tipo_documento == 21 && documento.url)
                  " (Upload)="SeleccionarDocumentoAval(documento, true)"
            (VerDocumento)="VerDocumento(documento, true)"
            [rfcAval]="rfcAval"
            (rfcAvalEvent)="recibirRFC($event)"></app-documents-new>
            <app-comprobante-new *ngFor="let documento of Documentos.DocumentosAvalDomicilio; let i = index"
              [documento]="documento" [index]="i" [solicitante]="documento.solicitante" style="margin: 10px 0" [show]="true"
              (Upload)="SeleccionarDocumento(documento, true)" (VerDocumento)="VerDocumento(documento, true)"
              (openDialogo)="openGoogleMapsDialog(documento)" [geoLocalizacionAval]="geoLocalizacionAval"
              >
            </app-comprobante-new>
          </div>
        </div>
      </div>
    </div>
  </div>
<!-- </app-sidenav-v2> -->