import { Component, EventEmitter, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-selector-participantes',
  templateUrl: './selector-participantes.component.html',
  styleUrls: ['./selector-participantes.component.scss']
})
export class SelectorParticipantesComponent implements OnInit {
@Output() onSelect = new EventEmitter();


  participanteSeleccionado: number | null = null;
  participantesJson = [
    {nombre: 'Ariel Fainsod Leff', iniciales: 'AF', puesto: 'Director General'},
    {nombre: 'Sebastian Ochoteco', iniciales: 'SO', puesto: 'Director de Finanzas'},
    {nombre: 'Lucero G. Martinez Orduñas', iniciales: 'LM', puesto: 'Directora de Operaciones'},
    {nombre: 'Alberto Gatica R.', iniciales: 'AG', puesto: 'Gerente de Crédito y Riesgos'},
    {nombre: 'Jorge Dávila', iniciales: 'JD', puesto: 'Director Comercial'},
    {nombre: 'Miriam Ortega', iniciales: 'MO', puesto: 'Gerente de operaciones'},
    {nombre: 'Daniel Cacildo M.', iniciales: 'DC', puesto: 'Especialista de Crédito y Riesgos'},
    {nombre: 'Fernando García', iniciales: 'FG', puesto: 'Analista de Crédito y Riesgos'},
    {nombre: 'Alan Lopez', iniciales: 'AL', puesto: 'Auxiliar de Crédito y Riesgos'},
  ]


  constructor() { }

  ngOnInit(): void {
  }

  seleccionarParticipante(){
    this.onSelect.emit(this.participantesJson[this.participanteSeleccionado])
  }

}
