<div class="documento" *ngIf="show && documento?.tipo_documento != 4 && documento?.tipo_documento != 17 && documento?.tipo_documento != 18">
    <div class="container">
        <div class="header-pdf">
            <div class="container-img-pdf">
                <img src="https://leaseforu-assets.s3.us-east-2.amazonaws.com/assets/images/step-porcompletar-pc.svg" *ngIf="documento.status == 0"/>
                <img src="https://leaseforu-assets.s3.us-east-2.amazonaws.com/assets/images/step-finish-c.svg" *ngIf="documento.status == 1"/>
                <img src="https://leaseforu-assets.s3.us-east-2.amazonaws.com/assets/images/validacion-positiva.svg" *ngIf="documento.status == 3">
                <img src="https://leaseforu-assets.s3.us-east-2.amazonaws.com/assets/images/validacion-invalid-input.svg" *ngIf="documento.status ==2"  />
            </div>
            <div class="container-descr">
                <div>
                    <p class="nombre_documento"  [ngClass]="{
                        'status-green': documento.status === 3,
                        'status-blue': documento.status === 1,
                        'status-red': documento.status === 2,
                        'complete': documento.status !== 0
                        }">{{ documento.nombre_documento}}</p>
                    </div>
                <div>
                <p class="descr_documento">{{ documento.descripcioncompleta}}</p>
                </div>
            </div>
            <div class="container-map">
                <button *ngIf="(this.documento.tipo_documento == 11 && !geoLocalizacionAval)" class="btn-white-blue" 
                [ngClass]="{ 'geo-saved': geoLocalizacion}"  (click)="openDialog()">Confirmar dirección</button>
                <button *ngIf="this.documento.tipo_documento == 12" class="btn-white-blue" 
                [ngClass]="{ 'geo-saved': geoLocalizacionActivo}"  (click)="openDialog()">Confirmar dirección</button>
                <button *ngIf="(this.documento.tipo_documento == 11 && geoLocalizacionAval)" class="btn-white-blue" 
                [ngClass]="{ 'geo-saved': geoLocalizacionAval}"  (click)="openDialog()">Confirmar dirección</button>
            </div>
            <!-- <div class="container-icons">
                <img src="https://leaseforu-assets.s3.us-east-2.amazonaws.com/assets/images/clock.svg" *ngIf="documento.status == 0"/>
                <img src="../../../../assets/icons/new-icons/check-input.svg" *ngIf="documento.status == 1"/>
            </div> -->
        </div>
        <div class="options">
            <div class="actions">
                <div class="btn-upload-doc"  (click)="upload()" [ngClass]="{'disabled': documento.status == 3 || documento.status == 1 }" >
                    <img *ngIf="documento.status == 0" src="https://leaseforu-assets.s3.us-east-2.amazonaws.com/assets/images/upload-3-pc.svg"/>
                    <img *ngIf="documento.status == 1" src="https://leaseforu-assets.s3.us-east-2.amazonaws.com/assets/images/step-finish-c.svg"/>
                    <img *ngIf="documento.status ==3" src="https://leaseforu-assets.s3.us-east-2.amazonaws.com/assets/images/validacion-positiva.svg"/>
                    <img *ngIf="documento.status ==2" src="https://leaseforu-assets.s3.us-east-2.amazonaws.com/assets/images/validacion-invalid-input.svg" />
                    <p [ngClass]="{
                        'status-green': documento.status === 3,
                        'complete': documento.status === 1,
                        'status-red': documento.status === 2
                    }"> {{documento.status != 0 ? "Subido con éxito" : "Subir documento"}}</p>
                </div>
                <div class="btn-upload-doc" (click)="desDocumento()">
                    <img *ngIf="documento.status != 0"src="https://leaseforu-assets.s3.us-east-2.amazonaws.com/assets/images/file-download-azul.svg">
                    <p [ngClass]="{
                        'status-green': documento.status === 3,
                        'complete': documento.status === 1,
                        'status-red': documento.status === 2
                    }"> {{documento.status != 0 ? "Descargar" : ""}}</p>

                </div>
                <div class="btn-upload-doc"  (click)="verDocumento()"  [ngClass]="{'disabled': !documento.url }" >
                    <img *ngIf="documento.status == 0" src="https://leaseforu-assets.s3.us-east-2.amazonaws.com/assets/images/eye_new_inactive.svg"/>
                    <img *ngIf="documento.status == 1" src="https://leaseforu-assets.s3.us-east-2.amazonaws.com/assets/images/eye_new_active.svg"/>
                    <img *ngIf="documento.status == 3" src="https://leaseforu-assets.s3.us-east-2.amazonaws.com/assets/images/eye_new_active-green.svg"/>
                    <img *ngIf="documento.status == 2" src="https://leaseforu-assets.s3.us-east-2.amazonaws.com/assets/images/eye_new_active-red.svg"/>
                    <p [ngClass]="{
                        'status-green': documento.status === 3,
                        'complete': documento.status === 1,
                        'status-red': documento.status === 2
                    }">Ver<br>documento</p>
                </div>
                <div *ngIf="documento.status == 2"  class="btn-upload-doc" (click)="upload()">
                    <img src="https://leaseforu-assets.s3.us-east-2.amazonaws.com/assets/images/upload-1-c.svg"/>
                    <p class="complete">Actualizar </p>
                </div >
                <!-- <svg [ngClass]="{'disabled': documento.status == 3 || documento.status == 1 }" xmlns="http://www.w3.org/2000/svg" width="21.261" height="28.828" viewBox="0 0 21.261 28.828"><defs><style>.upload{fill:#00a5da;}</style></defs><path class="upload" d="M17.631,3.594l-.849.812L8.513,12.674l1.7,1.7,6.238-6.238V27.7h2.362V8.134l6.238,6.238,1.7-1.7L18.48,4.406ZM7,30.059v2.362H28.261V30.059Z" transform="translate(-7 -3.594)"/></svg> -->
                <!-- <svg [ngClass]="{'disabled': (!documento.url.length && (documento.status != 1)) || (documento.status == 3 && documento.tipo_documento == 3)}" (click)="verDocumento()" style="height: 20px; width:50px;" xmlns="http://www.w3.org/2000/svg" width="80" height="10" viewBox="0 0 21.261 30.828"><defs><style>.a,.d{fill:none;}.a{stroke:#3ca2d6;stroke-width:2.5px;}.b{fill:#fff;stroke:#707070;}.c{stroke:none;}</style></defs><g transform="translate(-0.594 -22.973)"><path class="a" d="M22.316,4.5A22.918,22.918,0,0,0,1,19.034a22.9,22.9,0,0,0,42.632,0c-1.281-3.25-.7-5.037-6.026-8.671S28.3,4.5,22.316,4.5Zm0,24.223A9.689,9.689,0,1,1,32,19.034,9.693,9.693,0,0,1,22.316,28.723Zm0-15.5a5.813,5.813,0,1,0,5.813,5.813A5.806,5.806,0,0,0,22.316,13.22Z" transform="translate(0.938 19.723)"/><g class="b" transform="translate(2796.605 1649.469)"><rect class="c" width="1852" height="1104"/><rect class="d" x="0.5" y="0.5" width="1851" height="1103"/></g></g></svg> -->
                <!-- <svg [ngClass]="{'disabled': !documento.url.length}" (click)="verDocumento()" style="height: 20px; width:50px;" xmlns="http://www.w3.org/2000/svg" width="80" height="10" viewBox="0 0 21.261 30.828"><defs><style>.a,.d{fill:none;}.a{stroke:#3ca2d6;stroke-width:2.5px;}.b{fill:#fff;stroke:#707070;}.c{stroke:none;}</style></defs><g transform="translate(-0.594 -22.973)"><path class="a" d="M22.316,4.5A22.918,22.918,0,0,0,1,19.034a22.9,22.9,0,0,0,42.632,0c-1.281-3.25-.7-5.037-6.026-8.671S28.3,4.5,22.316,4.5Zm0,24.223A9.689,9.689,0,1,1,32,19.034,9.693,9.693,0,0,1,22.316,28.723Zm0-15.5a5.813,5.813,0,1,0,5.813,5.813A5.806,5.806,0,0,0,22.316,13.22Z" transform="translate(0.938 19.723)"/><g class="b" transform="translate(2796.605 1649.469)"><rect class="c" width="1852" height="1104"/><rect class="d" x="0.5" y="0.5" width="1851" height="1103"/></g></g></svg> -->
            </div>
        </div>
    </div>
</div>

