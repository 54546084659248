<app-sidenav-v2 [shortMenu]="true"> 
    <div class="container">
        <div class="top">
          <app-go-bak style="padding: 5px; margin-top: 10px;"></app-go-bak>
          <div class="title">Solicitud</div>
        </div>
        <ng-container class="contenedor-ocr">
            <app-ocr-solicitud></app-ocr-solicitud>
        </ng-container>
    </div>
</app-sidenav-v2>