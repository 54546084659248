import { Component, Input, OnInit, SimpleChanges } from '@angular/core';

interface StepState {
  complete: boolean;
  editMode: boolean;
  inactive: boolean;
  name?: string;
  editSrc?: string;
  completeSrc?: string;
  inactiveSrc?: string;
  editSecondSrc?: string;
  completeSecondSrc?: string;
  inactiveSecondSrc?: string;
}

@Component({
  selector: 'app-stepper-nueva-solicitud',
  templateUrl: './stepper-nueva-solicitud.component.html',
  styleUrls: ['./stepper-nueva-solicitud.component.scss']
})
export class StepperNuevaSolicitudComponent implements OnInit {
  @Input() currentStep: number;
 /*  steps: StepState[] = [
    { complete: false, editMode: true, inactive: true, name: "Datos Generales", editSrc: 'https://leaseforu-assets.s3.us-east-2.amazonaws.com/assets/images/datos-personales-2-p.svg', completeSrc: 'https://leaseforu-assets.s3.us-east-2.amazonaws.com/assets/images/datos-personales-1-c.svg', inactiveSrc: '', editSecondSrc: 'https://leaseforu-assets.s3.us-east-2.amazonaws.com/assets/images/step-enproceso-p.svg', completeSecondSrc: 'https://leaseforu-assets.s3.us-east-2.amazonaws.com/assets/images/step-finish-c.svg', inactiveSecondSrc: '' }, 
    { complete: false, editMode: false, inactive: true, name: "Precalifícate", editSrc: 'https://leaseforu-assets.s3.us-east-2.amazonaws.com/assets/images/precalificalte-2-p.svg', completeSrc: 'https://leaseforu-assets.s3.us-east-2.amazonaws.com/assets/images/precalificate-1-c.svg', inactiveSrc: 'https://leaseforu-assets.s3.us-east-2.amazonaws.com/assets/images/precalificar-3-pc.svg', editSecondSrc: 'https://leaseforu-assets.s3.us-east-2.amazonaws.com/assets/images/step-enproceso-p.svg', completeSecondSrc: 'https://leaseforu-assets.s3.us-east-2.amazonaws.com/assets/images/step-finish-c.svg', inactiveSecondSrc: 'https://leaseforu-assets.s3.us-east-2.amazonaws.com/assets/images/step-porcompletar-pc.svg' }, // Paso 2
    { complete: false, editMode: false, inactive: true, name: "Documentos", editSrc: 'https://leaseforu-assets.s3.us-east-2.amazonaws.com/assets/images/documentos-2p.svg', completeSrc: 'https://leaseforu-assets.s3.us-east-2.amazonaws.com/assets/images/documentos-1-c.svg', inactiveSrc: 'https://leaseforu-assets.s3.us-east-2.amazonaws.com/assets/images/documentos-3-pc.svg', editSecondSrc: 'https://leaseforu-assets.s3.us-east-2.amazonaws.com/assets/images/step-enproceso-p.svg', completeSecondSrc: 'https://leaseforu-assets.s3.us-east-2.amazonaws.com/assets/images/step-finish-c.svg', inactiveSecondSrc: 'https://leaseforu-assets.s3.us-east-2.amazonaws.com/assets/images/step-porcompletar-pc.svg' }, // Paso 3
    { complete: false, editMode: false, inactive: true, name: "¡Listo!", editSrc: '', completeSrc: 'https://leaseforu-assets.s3.us-east-2.amazonaws.com/assets/images/listo-1-c.svg', inactiveSrc: 'https://leaseforu-assets.s3.us-east-2.amazonaws.com/assets/images/listo-3-pc.svg', editSecondSrc: '', completeSecondSrc: 'https://leaseforu-assets.s3.us-east-2.amazonaws.com/assets/images/step-finish-c.svg', inactiveSecondSrc: 'https://leaseforu-assets.s3.us-east-2.amazonaws.com/assets/images/step-porcompletar-pc.svg' } // Paso 4
  ]; */
   steps: StepState[] = [
    { complete: false, editMode: true, inactive: true, name: "Documentos", editSrc: 'https://leaseforu-assets.s3.us-east-2.amazonaws.com/assets/images/documentos-2p.svg', completeSrc: 'https://leaseforu-assets.s3.us-east-2.amazonaws.com/assets/images/documentos-1-c.svg', inactiveSrc: 'https://leaseforu-assets.s3.us-east-2.amazonaws.com/assets/images/documentos-3-pc.svg', editSecondSrc: 'https://leaseforu-assets.s3.us-east-2.amazonaws.com/assets/images/step-enproceso-p.svg', completeSecondSrc: 'https://leaseforu-assets.s3.us-east-2.amazonaws.com/assets/images/step-finish-c.svg', inactiveSecondSrc: 'https://leaseforu-assets.s3.us-east-2.amazonaws.com/assets/images/step-porcompletar-pc.svg' }, // Paso 1
    //{ complete: false, editMode: false, inactive: true, name: "Precalifícate", editSrc: 'https://leaseforu-assets.s3.us-east-2.amazonaws.com/assets/images/precalificalte-2-p.svg', completeSrc: 'https://leaseforu-assets.s3.us-east-2.amazonaws.com/assets/images/precalificate-1-c.svg', inactiveSrc: 'https://leaseforu-assets.s3.us-east-2.amazonaws.com/assets/images/precalificar-3-pc.svg', editSecondSrc: 'https://leaseforu-assets.s3.us-east-2.amazonaws.com/assets/images/step-enproceso-p.svg', completeSecondSrc: 'https://leaseforu-assets.s3.us-east-2.amazonaws.com/assets/images/step-finish-c.svg', inactiveSecondSrc: 'https://leaseforu-assets.s3.us-east-2.amazonaws.com/assets/images/step-porcompletar-pc.svg' }, // Paso 2
    { complete: false, editMode: false, inactive: true, name: "¡Listo!", editSrc: '', completeSrc: 'https://leaseforu-assets.s3.us-east-2.amazonaws.com/assets/images/listo-1-c.svg', inactiveSrc: 'https://leaseforu-assets.s3.us-east-2.amazonaws.com/assets/images/listo-3-pc.svg', editSecondSrc: '', completeSecondSrc: 'https://leaseforu-assets.s3.us-east-2.amazonaws.com/assets/images/step-finish-c.svg', inactiveSecondSrc: 'https://leaseforu-assets.s3.us-east-2.amazonaws.com/assets/images/step-porcompletar-pc.svg' } // Paso 4
  ];
  constructor() { }
  ngOnChanges(changes: SimpleChanges) {
    if (changes.currentStep && changes.currentStep.currentValue) {
      console.log("Nuevo valor current step en STEPPER:", this.currentStep);
      this.updateStepState()
      
    }
  }


  ngOnInit(): void {
    this.updateStepState()
  }
  updateStepState() {
    for (let i = 0; i < this.steps.length; i++) {
      const step = this.steps[i];
      step.complete = (i < this.currentStep - 1);
      step.editMode = (i === this.currentStep - 1);
      step.inactive = (i > this.currentStep - 1);
      if(this.currentStep == 4){
        step.editMode = false
        step.complete = true
        step.inactive = null
      }
    }
  }

}
