import { Component, OnInit, ViewChild, ElementRef, AfterViewInit, Inject, ChangeDetectorRef } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { GoogleMap } from '@angular/google-maps';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { constants } from 'buffer';
import { pairs } from 'rxjs';
import { MessageBoxComponent } from 'src/app/_dialogs/message-box/message-box.component';
import { BuroService, SolicitudService } from 'src/app/_services';

@Component({
  selector: 'app-google-maps',
  templateUrl: './google-maps.component.html',
  styleUrls: ['./google-maps.component.scss']
})
export class GoogleMapsComponent implements OnInit, AfterViewInit {
  @ViewChild(GoogleMap) map!: GoogleMap;


  isLoading = true;

  other_col = true;
  center: { lat, lng };
  zoom = 15;
  marker?: { position: google.maps.LatLngLiteral; draggable: boolean };

  location = {
    lat: null,
    lng: null
  }
  fgDireccion: FormGroup;

  aval = {
    direccion: {}
  }

  tipo_vivenda

  direccion = {
    pais: '',
    ciudad: '',
    cp: '',
    calle: '',
    numeroExterior: '',
    colonia: '',
    delegacion: '',
    municipio: '',
    estado: ''
  };
  coloniasDisponibles = [];

  @ViewChild('searchBox', { static: true }) searchBoxRef!: ElementRef;

  constructor(private formBuilder: FormBuilder,
    private buroService: BuroService,
    private dialogRef: MatDialogRef<GoogleMapsComponent>,
    private solicitudService: SolicitudService,
    @Inject(MAT_DIALOG_DATA) public data,
    private dialog: MatDialog,
    private cdr: ChangeDetectorRef
  ) { }

  ngOnInit(): void {
    //this.marker = { position: this.center, draggable: false }; 
    this.isLoading = true;

    console.log('Valores recibidos ::', this.data);

    this.getDynamoDB(this.data.tipo_documento);
    if (this.center?.lat && this.center?.lng) {
      this.setMarker(this.center, true);
    } else {
      this.center = { lat: 19.42847, lng: -99.12766 };
      this.setMarker(this.center, true);
    }

    this.fgDireccion = this.formBuilder.group({
      calle: [''],
      numeroExterior: [''],
      cp: [''],
      colonia: [''],
      municipio: [''],
      ciudad: [''],
      estado: ['']
    });
  }

  ngAfterViewInit(): void {
    const searchBox = new google.maps.places.Autocomplete(this.searchBoxRef.nativeElement);


    searchBox.addListener('place_changed', () => {
      const place = searchBox.getPlace();
      if (place.geometry?.location) {
        this.center = {
          lat: place.geometry.location.lat(),
          lng: place.geometry.location.lng(),
        };
        this.setMarker(this.center, true);
      }

    });

  }

  get fd() { return this.fgDireccion.controls; }

  moveMap(event: google.maps.MapMouseEvent) {

    this.center = event.latLng!.toJSON();
    this.updateAddressOnMove();
  }

  markerDrag(event: google.maps.MapMouseEvent) {
    const newPosition = event.latLng;
    if (this.marker) {
      this.marker.position = newPosition.toJSON();
      this.getAddress(newPosition.lat(), newPosition.lng());
    }
  }


  markerDragEnd(event: google.maps.MouseEvent) {
    const newPosition = event.latLng;
    if (this.marker) {
      this.marker.position = newPosition.toJSON();
      this.getAddress(newPosition.lat(), newPosition.lng());
    }
    // console.log('Nueva posición del marcador: ', newPosition.lat(), newPosition.lng());
    // Actualiza la posición del marcador si es necesario
  }

  setMarker(position: google.maps.LatLngLiteral, draggable: boolean) {
    if (draggable) {
      this.marker = { position, draggable: true };
    } else {
      this.marker = { position, draggable: false };
    }
    this.getAddress(position.lat, position.lng);
  }



  addMarkerOnDblClick(event: google.maps.MapMouseEvent) {
    //console.log('###4####')
    if (event.latLng) {
      this.setMarker(event.latLng.toJSON(), true);
    }
  }

  getAddress(lat: number, lng: number) {
    //this.isLoading = true; 
    const geocoder = new google.maps.Geocoder();
    const latLng = { lat, lng };

    this.location = latLng

    geocoder.geocode({ location: latLng }, (results, status) => {
      if (status === 'OK' && results[0]) {
        // console.log('Respuesta completa de Geocoder:', results[0]); 
        const componentes = results[0].address_components;
        //console.log('Dirección completa:', results[0].formatted_address);
        /* results[0].address_components.forEach(component => {
          console.log(`${component.types[0]}: ${component.long_name}`);
        }); */

        const address = results[0].formatted_address;
        const match = address.match(/,\s([\w\sÁÉÍÓÚáéíóúñ]+),\s\d{5}/i);;
        const delegacion = match ? match[1] : '';

        /* componentes.forEach(component => {
          console.log(`Tipo: ${component.types.join(', ')} - Valor: ${component.long_name}`);
        }); */

        //console.log(componentes)  
        this.direccion = {
          pais: this.getComponente(componentes, 'country'),
          ciudad: this.getComponente(componentes, 'locality') || '',
          cp: this.getComponente(componentes, 'postal_code') || '',
          calle: this.getComponente(componentes, 'route') || '',
          numeroExterior: this.getComponente(componentes, 'street_number') || 'NA',
          colonia: this.getComponente(componentes, 'sublocality') || this.getComponente(componentes, 'neighborhood') || '',
          delegacion: delegacion,
          municipio: this.getComponente(componentes, 'administrative_area_level_2') || '',
          estado: this.getComponente(componentes, 'administrative_area_level_1') || ''
        }

        this.setData();

        //this.isLoading=false;

      } else {
        //this.isLoading=false;
        console.error('Geocoder falló debido a:', status);
      }
    });
  }
  private getComponente(components: google.maps.GeocoderAddressComponent[], type: string): string | undefined {
    const component = components.find(c => c.types.includes(type));
    return component ? component.long_name : undefined;
  }
  getCurrentLocation(): void {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const lat = position.coords.latitude;
          const lng = position.coords.longitude;
          this.center = { lat, lng };
          this.setMarker(this.center, true);
          console.log('Ubicación obtenida:', this.center);
        },
        (error) => {
          if (error.code === error.PERMISSION_DENIED) {
            console.error('El usuario denegó el permiso para acceder a la ubicación.');
            this.dialog.open(MessageBoxComponent, {
              width: '400px',
              data: {
                text: 'Permiso denegado',
                text3: 'Por favor, habilita el permiso de ubicación en la configuración de tu navegador.',
                icon: 'error',
                type: 'error',
              },
            });
          } else {
            console.error('Ocurrió un error al obtener la ubicación:', error);
            this.dialog.open(MessageBoxComponent, {
              width: '400px',
              data: {
                text: 'Error al obtener ubicación',
                text3: 'Revisa la configuración de tu navegador o intenta nuevamente.',
                icon: 'error',
                type: 'error',
              },
            });
          }
        }
      );
    } else {
      console.error('La API de Geolocalización no es soportada por este navegador.');
      this.dialog.open(MessageBoxComponent, {
        width: '400px',
        data: {
          text: 'Función no soportada',
          text3: 'Tu navegador no soporta la geolocalización.',
          icon: 'error',
          type: 'error',
        },
      });
    }
  }


  /*  getCurrentLocation(): void {
     if (navigator.geolocation) {
       navigator.geolocation.getCurrentPosition(
         (position) => {
           const lat = position.coords.latitude;
           const lng = position.coords.longitude;
           this.center = {
             lat: lat,
             lng:lng,
           };
           this.setMarker(this.center, true); 
           
         },
         (error) => {
           console.error('Error obteniendo ubicación:', error);
         }
       );
     } else {
       console.log('Geolocalización no soportada en este navegador.');
     }
   } */
  async onSubmit() {

    if (this.data.folio.length > 0) {
      const direccion = {
        direccionCompleta: `${this.fgDireccion.value.calle} ${this.fgDireccion.value.numeroExterior} 'S/N', ${this.fgDireccion.value.colonia}, ${this.fgDireccion.value.municipio}, ${this.fgDireccion.value.estado}, C.P.: ${this.fgDireccion.value.cp}`.trim(),
        numeroInterior: 'S/N',
        pais: this.direccion.pais,
        calle: this.fgDireccion.value.calle,
        numeroExterior: this.fgDireccion.value.numeroExterior,
        cp: this.fgDireccion.value.cp,
        colonia: this.fgDireccion.value.colonia,
        municipio: this.fgDireccion.value.municipio,
        ciudad: this.fgDireccion.value.ciudad,
        estado: this.fgDireccion.value.estado,
        tipo_vivienda: this.tipo_vivenda,
        lat: this.location.lat,
        lng: this.location.lng
      }
      const direccionActivo = {
        direccionCompleta: `${this.fgDireccion.value.calle} ${this.fgDireccion.value.numeroExterior} 'S/N', ${this.fgDireccion.value.colonia}, ${this.fgDireccion.value.municipio}, ${this.fgDireccion.value.estado}, C.P.: ${this.fgDireccion.value.cp}`.trim(),
        pais: this.direccion.pais,
        calle: this.fgDireccion.value.calle,
        cp: this.fgDireccion.value.cp,
        colonia: this.fgDireccion.value.colonia,
        municipio: this.fgDireccion.value.municipio,
        ciudad: this.fgDireccion.value.ciudad,
        estado: this.fgDireccion.value.estado,
        numInt: 'S/N',
        numExt: this.fgDireccion.value.numeroExterior,
        lat: this.location.lat,
        lng: this.location.lng
      }

      /*  const direccion={
         {
           direccionCompleta:`${this.fgDireccion.value.calle} ${this.fgDireccion.value.numeroExterior} 'S/N', ${this.fgDireccion.value.colonia}, ${this.fgDireccion.value.municipio}, ${this.fgDireccion.value.estado}, C.P.: ${this.fgDireccion.value.cp}`.trim(),
           calle:this.fgDireccion.value.calle,
           ciudad:this.fgDireccion.value.ciudad,
           colonia:this.fgDireccion.value.colonia,
           cp:this.fgDireccion.value.cp,
           estado: this.fgDireccion.value.estado,
           municipio:this.fgDireccion.value.municipio,
           numExt:this.fgDireccion.value.numeroExterior,
           numInt:'S/N',
           pais:this.direccion.pais,
           lat: this.location.lat,
           lng:this.location.lng
         }
       } */


      let data = null



      if (this.data?.solicitante == undefined || this.data?.solicitante == true) {
        data = this.data.tipo_documento == 11 ? { direccion } : this.data.tipo_documento == 12 ? { direccionActivo } : {};
      } else {
        const res = await this.solicitudService.ObtenerSolicitud(this.data.folio);

        this.aval = res?.aval || {}

        if (this.aval) {
          this.aval.direccion = direccion
          const aval = this.aval;
          data = { aval }
        }
      }



      if (data && Object.keys(data).length > 0) {

        await this.solicitudService.GuardarSolicitud(this.data.folio, data);
        const dialogref = this.dialog.open(MessageBoxComponent, {
          width: "500px",
          data: {
            text: "Éxito",
            text3: "tu información se ha guardado",
            icon: "success",
            type: "success"
          }
        })
        dialogref.afterClosed().subscribe(_ => {
          this.salir()
        })
      } else {
        return;
      }
    } else {
      return;
    }
  }

  async getDynamoDB(tipo_documento) {
    if (this.data.folio) {
      try {
        const res = await this.solicitudService.ObtenerSolicitud(this.data.folio);
        this.tipo_vivenda = res?.direccion?.tipo_vivienda || null;
        if (tipo_documento == 11) {
          if (res.direccion && res.direccion.lat && res.direccion.lng) {
            this.center = {
              lat: res.direccion.lat,
              lng: res.direccion.lng,
            };
            this.setMarker(this.center, true);

          } else {
            console.log("Dirección no disponible o vacía");
            this.getCurrentLocation()
          }
        }
        if (tipo_documento == 12) {
          if (res.direccionActivo && res.direccionActivo.lat && res.direccionActivo.lng) {
            this.center = {
              lat: res.direccionActivo.lat,
              lng: res.direccionActivo.lng,
            };
            this.setMarker(this.center, true);
          } else {
            console.log("Dirección no disponible o vacía");
            this.getCurrentLocation()
          }
        }

      } catch (error) {
        console.error("Error al obtener de DynamoDB", error);
      }
    } else {
      return;
    }
  }
  setData() {
    this.fgDireccion.patchValue({
      calle: this.direccion.calle,
      numeroExterior: this.direccion.numeroExterior,
      cp: this.direccion.cp,
      colonia: this.direccion.colonia,
      municipio: this.direccion.delegacion,
      ciudad: this.direccion.ciudad,
      estado: this.direccion.estado,
    });
    this.fgDireccion.markAsDirty();
    this.fgDireccion.markAsTouched();
    //this.cdr.detectChanges();
  }
  updateAddressOnMove() {
    this.getAddress(this.center.lat, this.center.lng);
  }

  salir() {
    this.dialogRef.close()
  }
  async BuscarDireccion() {
    const cp = this.direccion.cp.trim()
    this.direccion.cp = ''
    if (cp.toString().length == 5) {

      const res: any = await this.buroService.buscarEstado(cp.toString())

      this.guardarColonias(res)
      if (res[0].error == false) {
        this.direccion = {
          ...this.direccion,
          cp: cp,
          estado: res[0].response.estado,
          municipio: res[0].response.municipio,
          ciudad: res[0].response.ciudad || '',
          delegacion: res[0].response.municipio
          //colonia: res.colonias?.[0] || '',
        };
        //console.log(this.direccion);
        this.setData();

        this.other_col = false

      }

    } else {
      this.other_col = true
    }

  }
  guardarColonias(direcciones) {
    this.coloniasDisponibles = []
    direcciones.forEach(element => {
      const { response: { asentamiento } } = element
      this.coloniasDisponibles.push(asentamiento)
    });
  }
  setColonia(colonia) {
    if (colonia === 'Otra colonia') {
      this.fd.colonia.setValue(null)
      this.other_col = true

    } else {
      this.other_col = false
      this.fd.colonia.setValue(colonia)
    }

  }
  onMapReady(map: google.maps.Map) {
    this.isLoading = false;
    console.log('El mapa ha terminado de renderizarse.');

  }

}
