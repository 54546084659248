import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-select-colonias',
  templateUrl: './select-colonias.component.html',
  styleUrls: ['./select-colonias.component.scss']
})
export class SelectColoniasComponent implements OnInit {

  @Input() colonias: any = []
  @Input() colonia = null
  @Output() onSelected = new EventEmitter()

  constructor() { }

  ngOnInit(): void {
   //this.colonias.push('Otra colonia')
  }

  Selected() {
    console.log('send data', this.colonia);
    this.onSelected.emit(this.colonia)
  }

}
