<div
  mat-dialog-content
  class="container"
  style="
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: 100vh;
    overflow: hidden;
  "
>
  <div>
    <div class="img_icon_msg">
      <img [src]="data.icon_url" />
    </div>
  </div>
  <div class="new-mat-dialog">
    <mat-dialog-content style="overflow: hidden; height: 100%; max-height: 100% !important;">
      <div class="title">
        <h4>{{ data.text1 }}</h4>
        <div class="container-select">
          <p>Nombre activo</p>
          <div>
            <input style="width: 97%; height: 83%;" type="text" [(ngModel)]="nombre_activo">
          </div>
          <p>{{ data.text2 }}</p>
          <select [(ngModel)]="condicion_activo_select">
            <option *ngFor="let activo of data.option2" [value]="activo">
              {{ activo }}
            </option>
          </select>
          <p>{{ data.text3 }}</p>
          <select [(ngModel)]="vendedor_nuevo_select">
            <option *ngFor="let vendedor of data.option3" [value]="vendedor">
              {{ vendedor }}
            </option>
          </select>
          <ng-container >
            <p>{{ data.text7 }}</p>
            <input type="text" [(ngModel)]="seleccion_vendor"readonly style="padding: 5px;"> 
            <p>¿Desea reasignar trámite?</p>
            <mat-slide-toggle [(ngModel)]="reasignar" [disabled]="reasignar_check"></mat-slide-toggle>
              <!-- Campo de búsqueda para vendedores -->
                  <!-- <p>{{ data.text6 }}</p>
                  <div>
                  <input
                  type="text"
                  [(ngModel)]="searchText"
                  (input)="filterVendors()"
                  placeholder="Buscar vendedor..."
                  class="fixed-width-input"
                  style="width: 97%; height: 83%;"
                />
                  </div> -->
             <!-- Selector de vendedores -->
                  <!--      <p>{{ data.text7 }}</p>
                      <select [(ngModel)]="seleccion_vendor" class="fixed-width-select" (change)="onVendorChange($event)">
                        <option value="">Seleccionar vendor...</option>
                        <option *ngFor="let vendor of filteredVendors" [value]="vendor.user_id">
                          {{ vendor.fullname }}
                        </option>
                      </select> -->
             <!-- Selector de vendedores -->
             <p>{{ data.text8 }}</p>
             <select [(ngModel)]="seleccion_vendedor" class="fixed-width-select" (change)="onVendedorChange($event)" [disabled]="!reasignar">
                <option value="">Seleccionar vendedor...</option>
                <option *ngFor="let vendedor of Vendedores" [value]="vendedor.id">
                  {{ vendedor.fullname }}
             </select>
          </ng-container>
          <p>{{data.text5}}</p>
          <select [(ngModel)]="version_select"  (change)="checkVersion($event.target.value)">
            <option value="">Seleccionar version...</option>
            <option *ngFor="let v of data.option5" [value]="v">{{ v }}</option>
          </select>
          <p>{{data.text4}}</p>
          <select [(ngModel)]="plazo_select">
            <option value="">Seleccionar Plazo...</option>
            <option *ngFor="let plazo of data.option4" [value]="plazo">
              {{ plazo }}
            </option>
          </select>
          <p>Condiciones de pago</p>
            <select [(ngModel)]="condiciones_pago_select"> 
              <option value="48 hrs"> 48 hrs</option>
              <option value="Contra engrega"> Contra entrega</option>
              <option value="Pendiente"> Pendiente</option>
            </select>
            <!-- <input style="width: 97%; height: 83%;" type="text" [(ngModel)]="condiciones_pago_select"> -->
         
          <p>Promoción</p>
          <div>
            <input style="width: 97%; height: 83%;" type="text" [(ngModel)]="promocion_select">
          </div>
          
        </div>
      </div>
      <div *ngIf="errorMsg" class="error-msg">Por favor ingresa todos los datos</div>
      
    </mat-dialog-content>
    <mat-dialog-actions class="footer-btn">
      <button (click)="aceptar()">
        <mat-spinner *ngIf="loading" diameter="20"></mat-spinner>
        <img *ngIf="!loading"
          src="https://leaseforu-assets.s3.us-east-2.amazonaws.com/assets/images/check-green.svg"
        />Aceptar
      </button>
      <button (click)="cerrar()">
        <img
          src="https://leaseforu-assets.s3.us-east-2.amazonaws.com/assets/images/uncheck-red.svg"
        />Cancelar
      </button>
    </mat-dialog-actions>
  </div>
</div>
