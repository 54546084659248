import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UserService } from 'src/app/_services';

@Component({
  selector: 'app-solicitud-completada',
  templateUrl: './solicitud-completada.component.html',
  styleUrls: ['./solicitud-completada.component.scss']
})
export class SolicitudCompletadaComponent implements OnInit {
  @Input() data: any;
  nombre_cliente= ""
  folio = ""
  flujo_cotizador = null

  constructor(
    private router: Router,
    private userService: UserService

  ) { }

  ngOnInit(): void {
    const flujo = localStorage.getItem('flujo_cotizador')
    this.flujo_cotizador = flujo == "false" ? false : true;
    const folio = localStorage.getItem('folio_solicitud') || ''
    this.folio = folio
     this.nombre_cliente ="FERNANDO" + " " + "NUÑEZ"
  }

  enviarDatos(){
    console.log("enviar..")
    this.router.navigate(['/'])
   /* const resChageStatus =  this.userService.CambiarStatusSolicitud(this.folio, 1, 1)
       if(resChageStatus){
        if(this.flujo_cotizador){
          this.router.navigate(['/'])
        }else{
          window.location.href = "https://leaseforu.com"
        }
       } */


  }
}
