<h4>Nuevo Activo</h4>
<div class="container-modal">
  <mat-dialog-content class="container-btn" style="padding: 10px">
    <form (ngSubmit)="GuardarActivo()" style="width: 95%" #activoForm="ngForm" >
      <div class="container-form">
        <!-- <div class="custom-form-field non-empty" >
          <label>Nombre *</label>
          <input
            type="text"
            name="name"
            id="name"
            placeholder=" "
            (ngModelChange)="checkInput(Activo.name)"
            [(ngModel)]="Activo.name"
            style="width: 90%"
          />
        </div> -->
        <div class="custom-form-field non-empty">
          <label>Descripción*</label>
          <input
            type="text"
            name="description"
            id="description"
            placeholder=" "
            (ngModelChange)="checkInput($event)"
            [(ngModel)]="Activo.description"
            style="width: 90%"
          />
        </div>
        <div class="custom-form-field non-empty">
          <label>Valor factura con IVA*</label>
          <input
            mask="separator.2"
            thousandSeparator=","
            prefix="$ "
            name="value"
            id="value"
            maxlength="350"
            (ngModelChange)="checkInput(Activo.value)"
            required
            [(ngModel)]="Activo.value"
            style="width: 90%"
          />
        </div>
        <div class="custom-form-field non-empty">
          <label>Cantidad *</label>
          <input
            type="number"
            name="quantity"
            id="quantity"
            maxlength="350"
            (ngModelChange)="checkInput(Activo.quantity)"
            required
            [(ngModel)]="Activo.quantity"
            style="width: 90%"
          />
        </div>
        
        
        <div class="custom-form-field non-empty" >
          <label>Numero de serie</label>
          <input
            type="text"
            name="serial_number"
            id="serial_number"
            placeholder=" "
            (ngModelChange)="checkInput(Activo.serial_number)"
            [(ngModel)]="Activo.serial_number"
            style="width: 90%"
          />
        </div>
        <div class="custom-form-field non-empty" >
          <label>Proveedor</label>
          <input
            type="text"
            name="proveedor"
            id="proveedor"
            placeholder=" "
            (ngModelChange)="checkInput(Activo.proveedor)"
            [(ngModel)]="Activo.proveedor"
            style="width: 90%"
          />
        </div>
      </div>
    </form>
    <div>
      <div style="position: relative; overflow: hidden; width: 0; height: 0">
        <input
          type="file"
          id="fileInput"
          accept="image/*,.pdf"
          (change)="onFileSelected($event)"
        />
      </div>
      <div style="display: flex; justify-content: center">
        <button
          (click)="selectFile()"
          style="position: relative; z-index: 1"
          class="btn-carga-document"
        >
          <img
            src="https://leaseforu-assets.s3.us-east-2.amazonaws.com/assets/images/upload-doc.svg"
          />
          Cargar factura relacionada
        </button>
      </div>
      <div *ngIf="selectedFileName" class="file-name">
        <p style="margin-bottom: 2px">Archivo seleccionado:</p>
        <p>{{ selectedFileName }}</p>
      </div>
    </div>

    <div *ngIf="error_msg" class="error-msg">
      {{ error_msg_validar }}
    </div>
    <div *ngIf="error_msg_special_character" class="error-msg">
      Por favor verifica los carácteres especiales
    </div>

  </mat-dialog-content>
</div>
<mat-dialog-actions class="footer-btn">
  <div class="error-msg" *ngIf="error_msg"></div>
  <button (click)="GuardarActivo()">
    <img
      *ngIf="!loading"
      src="https://leaseforu-assets.s3.us-east-2.amazonaws.com/assets/images/check-green.svg"
    />
    <mat-progress-spinner
      *ngIf="loading"
      mode="indeterminate"
      diameter="30"
    ></mat-progress-spinner>
    <span *ngIf="!loading">Guardar</span>
  </button>
  <button (click)="cerrar()">
    <img
      src="https://leaseforu-assets.s3.us-east-2.amazonaws.com/assets/images/uncheck-red.svg"
    />Regresar
  </button>
</mat-dialog-actions>
