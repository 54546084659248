<div class="documento" *ngIf="show">
    <div class="container">
        <div class="header-pdf">
            <div class="container-img-pdf">
                <img src="https://leaseforu-assets.s3.us-east-2.amazonaws.com/assets/images/step-porcompletar-pc.svg" *ngIf="!estadosCuentaCompletos"/>
                <img src="https://leaseforu-assets.s3.us-east-2.amazonaws.com/assets/images/step-finish-c.svg" *ngIf="estadosCuentaCompletos"/>
            </div>
            <div class="container-descr">
                <p class="nombre_documento" [ngClass]="{complete: estadosCuentaCompletos}">Estados de Cuenta</p>
                <p class="descr_documento">Ultimos 3 estados de cuenta y recibos de nómina (ambos completos y con cadena digital de sad)</p>
            </div>
            <!-- <div class="container-icons">
                <img src="https://leaseforu-assets.s3.us-east-2.amazonaws.com/assets/images/clock.svg" *ngIf="documento.status == 0"/>
                <img src="../../../../assets/icons/new-icons/check-input.svg" *ngIf="documento.status == 1"/>
            </div> -->
        </div>
        <div class="options">
            
            <div class="actions" *ngFor="let documento of documentos; let i = index">
                <div class="btn-upload-doc"  (click)="upload(i)" [ngClass]="{'disabled': documento.status == 3 || documento.status == 1, 'completed': documento.status != 0 }" >
                    <p [ngClass]="{complete: documento.status != 0}">{{ getOrdinal(i) }} documento</p>
                    <img *ngIf="documento.status == 0" src="https://leaseforu-assets.s3.us-east-2.amazonaws.com/assets/images/upload-3-pc.svg"/>
                    <img *ngIf="documento.status != 0" src="https://leaseforu-assets.s3.us-east-2.amazonaws.com/assets/images/circle-check-white.svg"/>
                </div>
                <div class="btn-see-doc"  (click)="verDocumento(i)"  [ngClass]="{'disabled': !documento.url }" >
                    <img *ngIf="documento.status == 0" src="https://leaseforu-assets.s3.us-east-2.amazonaws.com/assets/images/eye_new_inactive.svg"/>
                    <img *ngIf="documento.status != 0" src="https://leaseforu-assets.s3.us-east-2.amazonaws.com/assets/images/eye_new_active.svg"/>
                </div>
            </div>
        </div>
    </div>
</div>

