import { ChangeDetectorRef, Component, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-documentos-orc-resposivo',
  templateUrl: './documentos-orc-resposivo.component.html',
  styleUrls: ['./documentos-orc-resposivo.component.scss']
})
export class DocumentosOrcResposivoComponent implements OnInit {

    @Input() documento;
    @Input() index;
    @Input() solicitante;
    @Input() show = true;
    @Input() isUploading: boolean = false; 
    @Input() isAval=false;
    @Input() rfcAval:string=null
    @Input() geoLocalizacion=false;
    @Input() geoLocalizacionActivo=false;
    @Input() geoLocalizacionAval=false;
  
    @Output() VerInfo = new EventEmitter();
    @Output() Upload = new EventEmitter();
    @Output() VerDocumento = new EventEmitter();
    @Output() VerComentarios = new EventEmitter();
    @Output() Aceptar = new EventEmitter();
    @Output() Rechazar = new EventEmitter();
    @Output () rfcAvalEvent = new EventEmitter();
    @Output() openDialogo = new EventEmitter()
  
    aceptando = false;
    rechazando = false;
    perfil = null;
    registroFederalContribuyentes:string=null;
    isMobileView: boolean = false;
    
  
    validar_documento;
  
    documentos_estados_cuenta = [];
  constructor(private cdr: ChangeDetectorRef) { }

  ngOnInit(): void {
   this.onResize(null);
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
   // console.log("window.......", document.documentElement.clientWidth);
    this.isMobileView = document.documentElement.clientWidth <= 780;
   // console.log(this.isMobileView)
    this.cdr.detectChanges();
  }

  isMobile() {
    return this.isMobileView;
  }

  verDocumento(): any {
    console.log("this.documento.status :", this.documento.status);
    console.log(
      "this.documento.tipo_documento :",
      this.documento.tipo_documento
    );
    // if( !this.userService.alertService.isOnline() ) return
    console.log(this.index);
    console.log(!this.documento.url);

    console.log("Documento: ", this.documento);
    if (this.documento.status == 0) return;
    if (
      this.documento.tipo_documento == 3 &&
      this.documento.status == 3 &&
      this.perfil == 3
    )
      return;
    if (this.documento.tipo_documento == 3 && this.documento.status == 3)
      return;
    if (
      this.documento.tipo_documento != 3 ||
      this.documento.tipo_documento != 9
    )
      return this.VerDocumento.emit(this.index);
    if (!this.documento.url.length) return;
    this.VerDocumento.emit(this.index);
  }

  upload(): any {
    this.isUploading = true; 
    console.log(this.index);
   // if (this.documento.status == 1 || this.documento.status == 3){
  if(false){
      this.isUploading = false; 
      
      return;
    } 
    this.Upload.emit(this.index);
  }
  info(): any {
    this.VerInfo.emit(this.documento.descripcioncompleta);
  }
  openDialog() {
    this.openDialogo.emit();
  }
  getMarginClass(): string {
    if (!this.isMobile()) {
      if (!this.solicitante && this.documento.tipo_documento === 5) {
        return 'margin-top-15';
      } else if (this.documento.tipo_documento === 11 || this.documento.tipo_documento === 12) {
        return 'margin-top-20';
      } else {
        return 'margin-top-60';
      }
    }
    return ''; 
  }
}
