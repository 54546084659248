<div class="contenedor">
  <div class="header">
    <img
      class="logo"
      mat-button
      width="auto"
      src="https://leaseforu-assets.s3.us-east-2.amazonaws.com/assets/images/NewLogo-L4U.png"
      alt="leaseforu"
    /> <!--  [routerLink]="['/dashboard']" -->
  </div>
  <ng-container *ngIf="solicitante">
    <div>
      <span class="section-title">Datos del solicitante</span>
    </div>
    <div class='contenedor-docs'>
        <div class="contenedor-elementos" *ngIf="true">
          <input type="file" id="document" (click)="resset($event)" accept="application/pdf"
                (change)="SubirDocumento($event)" hidden />
            <app-documentos-orc-resposivo class="componente-doc"
            *ngFor="let documento of Documentos.DocumentosSolicitante; let i = index"
            [documento]="documento"
            [index]="i"
            [solicitante]="true"
            [geoLocalizacion]="geoLocalizacion"
            [geoLocalizacionActivo]="geoLocalizacionActivo"
            [show]="
              (documento && documento.tipo_documento != 21) ||
              (documento.tipo_documento == 21 && documento.url)
            "
            (Upload)="SeleccionarDocumento(documento, true)"
            (VerDocumento)="VerDocumento(documento, true)"
            (openDialogo)="openGoogleMapsDialog(documento)"
          >
          </app-documentos-orc-resposivo>
          <app-documents-estado-cuenta-new 
            [documentos]="documentos_estados_cuenta" 
            [solicitante]="true"
            (Upload)="SeleccionarDocumento($event, true)"
            (VerDocumento)="VerDocumento($event, true)">
          </app-documents-estado-cuenta-new>
        </div>
    </div>
  </ng-container>
  <div>
    <span class="section-title">Datos del aval</span>
  </div>
   <div class='contenedor-docs'>
    <div class="contenedor-elementos" *ngIf="!omitirAval">
        <input type="file" id="documentaval" (click)="resset($event)" accept="application/pdf"
        (change)="SubirDocumentoAval($event)" hidden />
      <app-documentos-orc-resposivo class="componente-doc" *ngFor="let documento of Documentos.DocumentosAval; let i = index" [documento]="documento"
        [index]="i" [solicitante]="documento.solicitante" [show]="
                  (documento && documento.tipo_documento != 21) ||
                  (documento.tipo_documento == 21 && documento.url)
              " (Upload)="SeleccionarDocumentoAval(documento, true)"
        (VerDocumento)="VerDocumento(documento, true)"
        (openDialogo)="openGoogleMapsDialog(documento)" [geoLocalizacionAval]="geoLocalizacionAval">
      </app-documentos-orc-resposivo>
    </div>
   </div>
    
</div>
