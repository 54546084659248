<div class="mapContainer">
    <div class="header" >
       <ng-container>
        <input
          #searchBox
          class="buscar"
          id="search-box"
          type="text"
          placeholder="Buscar lugar"
        /> 
        </ng-container>
    </div>
    <!-- <div class="close-btn" (click)="salir()">
        <p>X</p>
    </div> -->
    <div class="contedor-dir">
           <!--  <mat-progress-spinner
            *ngIf="isLoading"
            mode="indeterminate"
            diameter="50"
            class="spinner"
        ></mat-progress-spinner> -->
        <div class="mapa">
            <div class="loading-spinner" *ngIf="isLoading">
                <mat-progress-spinner 
                mode="indeterminate" 
                diameter="50" 
                class="spinner">
            </mat-progress-spinner>
              </div>
            <div>
                <google-map
            [center]="center"
            [zoom]="zoom"
            (mapClick)="moveMap($event)"
            (mapDblclick)="addMarkerOnDblClick($event)"
            (idle)="onMapReady($event)"
            >
            <map-marker
                *ngIf="marker"
                [position]="marker.position"
                [options]="{ draggable: marker.draggable }"
                (mapDrag)="markerDragEnd($event)"
                >
            </map-marker>
            </google-map>
            </div>
        </div> 
        <div class="form-direccion">
           <form [formGroup]="fgDireccion">
            <mat-form-field appearance="outline">
                <mat-label>Calle</mat-label>
                <input 
                matInput
                formControlName="calle" 
                [value]="direccion.calle"
                name="calle"
                >
            </mat-form-field>
            <div class="campos-combinados">
                <mat-form-field appearance="outline" class="campo">
                    <mat-label>Numero Exterior.</mat-label>
                    <input 
                    matInput
                    formControlName="numeroExterior" 
                    [value]="direccion.numeroExterior"
                    name="numeroExterior"
                    >
                </mat-form-field>
                <mat-form-field appearance="outline" class="campo">
                    <mat-label>C.P.</mat-label>
                    <input 
                    matInput
                    type="text"
                    formControlName="cp" 
                    [value]="direccion.cp"
                    [(ngModel)]="direccion.cp"
                    name="cp"
                    (input)="BuscarDireccion()">
                </mat-form-field>
            </div>
            <mat-label *ngIf="coloniasDisponibles.length && !other_col">Colonia</mat-label>
            <ng-container class="campo-select">
                <app-select-colonias *ngIf="coloniasDisponibles.length && !other_col"
                [colonia]="direccion.colonia"
                [colonias]="coloniasDisponibles"
                (onSelected)="setColonia($event)"
                formControlName="colonia" 
                name="selec-colonia"
                ></app-select-colonias>
            </ng-container>
            <mat-form-field  *ngIf="other_col" appearance="outline">
                <mat-label>Colonia</mat-label>
                <input 
                matInput
                formControlName="colonia" 
                [value]="direccion.colonia"
                name="colonia"
                >
            </mat-form-field>
            <mat-form-field appearance="outline">
                <mat-label>Municipio</mat-label>
                <input 
                matInput
                formControlName="municipio" 
                [value]="direccion.municipio"
                name="municipio"
                >
            </mat-form-field>
            <mat-form-field appearance="outline">
                <mat-label>Ciudad</mat-label>
                <input 
                matInput
                formControlName="ciudad" 
                [value]="direccion.ciudad"
                name="ciudad"
                >
            </mat-form-field>
            <mat-form-field appearance="outline">
                <mat-label>Estado</mat-label>
                <input 
                matInput 
                formControlName="estado" 
                [value]="direccion.estado"
                name="estado">
            </mat-form-field>
        </form>
            <div class="contendor-botones">
                <div class="guardar-btn-div">
                    <button class="guardar-btn" (click)="onSubmit()">Guardar</button>
                </div>
                <div class="guardar-btn-div">
                    <button class="salir-btn" (click)="salir()">Cancelar</button>
                </div>
            </div>
        </div>
    </div>
</div>

