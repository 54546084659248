import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { ModalVistaPreviaComponent } from 'src/app/_dialogs/modal-vista-previa/modal-vista-previa.component';
import { NuevaSolicitudService } from 'src/app/_services/nueva_solicitud.service';

@Component({
  selector: 'app-vistas-previas',
  templateUrl: './vistas-previas.component.html',
  styleUrls: ['./vistas-previas.component.scss']
})
export class VistasPreviasComponent implements OnInit {
  id_user = null
  CotizacionesPrevias = null
  optSelected = 0;

  constructor(
    private nuevaSolicitudService: NuevaSolicitudService,
    private router: Router, 
    private dialog: MatDialog,
    private _snackBar: MatSnackBar,



  ) { }

  async ngOnInit() {
    const { id } = JSON.parse(localStorage.getItem('currentUser'))
    console.log("ID USER", id)
    this.id_user = id
    await this.obtenerVistasPrevias()
  }

  async obtenerVistasPrevias(){
    const data = {
      id_user: this.id_user
    }
    const res: any = await this.nuevaSolicitudService.getVistasPrevias(data)
    console.log('+++VALOR DE VISTA PREVIA+++',res)
    if(res.data){
      this.CotizacionesPrevias = res.data
      console.log("PREVIAS", this.CotizacionesPrevias)
      this.CotizacionesPrevias.forEach(obj => {
        obj.data = JSON.parse(obj.data)
        obj.datos_equipo_arrendar = JSON.parse(obj.datos_equipo_arrendar)
        obj.peticion = JSON.parse(obj.peticion)
      })
      console.log("PREVIAS", this.CotizacionesPrevias)

    }
  }

  async VerVistaPrevia(vista_previa){
    const url = vista_previa.url
    const folio = vista_previa.folio
    if( url ){
      localStorage.setItem('documento', JSON.stringify({url}))
      this.router.navigate(["/dashboard", "cliente", folio, "ver_documento"])
    }

  }
  async AsignarVistaPrevia(folio:null){
    console.log("Vista previa", folio)
    const _folio = folio
    this.dialog.open(ModalVistaPreviaComponent, {
      width: "500px",
      height: "300px",
      data: {
        text: "Asigna una nueva solicitud",   
        folio: folio,

        aceptar: async (correo, folio) => {
          const correo_cli = correo
         if(correo_cli == ""){
          return this._snackBar.open(
            "Ha ocurrido un error, por favor inserta correo ",
            "Ok",
            {
              duration: 8000,
              horizontalPosition: "center",
              verticalPosition: "top",
              panelClass: ["warning-snackbar"],
            }
          );
         }
          const form = {
            folio: _folio ? _folio : folio,
            client_name: "A QUIEN CORRESPONDA",
            client_email: correo_cli,
            client_first_name: " ",
            client_second_name: " ",
            client_phone: " ",
            id: 0,

          };
          const resp:any = await this.nuevaSolicitudService.crearCotizacionVistaPrevia(
            form
          );
          if (resp) {
            console.log("***********RES", resp)
            // await this.obtenerVistasPrevias()
            if(resp.folio){
              this.dialog.closeAll();
              this.router.navigate(['main-ocr'],{queryParams: {folio: resp.folio, correo: correo_cli, flujo_cotizador : true}})//ocr_solicitud
            }else{
              this._snackBar.open(
                "Ha ocurrido un error, por favor intenta con otro correo ",
                "Ok",
                {
                  duration: 8000,
                  horizontalPosition: "center",
                  verticalPosition: "top",
                  panelClass: ["warning-snackbar"],
                }
              );
            }
            // window.location.reload();
          }
        },
        cerrar: async () => {
          this.dialog.closeAll();

          // this.fecha_compromiso = new Date();
        },
        // confirmopt: (val: number) => (this.optSelected = val),
      },
    });

  }

}
