import { ChangeDetectorRef, Component, EventEmitter, HostListener, Input, OnInit, Output, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-documents-estado-cuenta-new',
  templateUrl: './documents-estado-cuenta-new.component.html',
  styleUrls: ['./documents-estado-cuenta-new.component.scss']
})
export class DocumentsEstadoCuentaNewComponent implements OnInit {
  @Input() documentos: any[];
  @Input() index;
  @Input() solicitante;
  @Input() show = true;

  @Output() VerInfo = new EventEmitter();
  @Output() Upload = new EventEmitter();
  @Output() VerDocumento = new EventEmitter();
  @Output() VerComentarios = new EventEmitter();
  aceptando = false;
  rechazando = false;
  perfil = null;

  validar_documento;

  algun_cancelado
  validados
  incluidos
  isMobileView
  

  documentos_estados_cuenta = [];
  estadosCuentaCompletos:boolean;
  constructor(private cdr: ChangeDetectorRef) { }
  ngOnChanges(changes: SimpleChanges){
    console.log("ESTADOS", this.documentos )
    this.filtrarEstatusDocumentos();
    this.onResize(null);
  }

  ngOnInit(): void {
    console.log("DOCUMENTOS ESTADOS", this.documentos)
    this.filtrarEstatusDocumentos();
   
  }
  filtrarEstatusDocumentos(){
    const documentosCompletos =  this.documentos.filter(documento => documento.status === 1);
    this.estadosCuentaCompletos = this.validarStatusDoc() //documentosCompletos.length > 0
    console.log("DOCUMENTOS ESTADOS COMPLETO", documentosCompletos)
    console.log("DOCUMENTOS ESTADOS COMPLETO", this.estadosCuentaCompletos)
  }
  verDocumento(documentoIndex: number): void {
    const documento = this.documentos[documentoIndex];
    if (!documento) return;
    console.log("documento.status :", documento.status);
    console.log("documento.tipo_documento :", documento.tipo_documento);
    console.log("DOc", documento)
    this.VerDocumento.emit(documento);
  }

  upload(documentoIndex: number): void {
    const documento = this.documentos[documentoIndex];
    if (!documento) return;
    // Agrega las condiciones lógicas necesarias según tus requerimientos
    this.Upload.emit(documento);
  }

  info(descripcion: string): void {
    this.VerInfo.emit(descripcion);
  }
  getOrdinal(index: number): string {
    const suffixes = ['er', 'do', 'er']; 
    const suffix = suffixes[index];
    return (index + 1) + suffix;
  }

  validarStatusDoc(){
  const algun_cancelado=  this.documentos.filter(documento => documento.status === 2);
  const validados= this.documentos.filter(documento => documento.status === 3);

  this.algun_cancelado=algun_cancelado.length;
  this.validados=validados.length;

  console.log('******VALIDADOS****',this.validados);

  if(this.validados < 3 || this.algun_cancelado === 0){
    const incluido =this.documentos.filter(documento => documento.status === 1);
    this.incluidos = incluido.length>0;
  }
  
 return (this.validados === 3 || this.algun_cancelado !== 0);
  }

  @HostListener('window:resize', ['$event'])
    onResize(event) {
     /* console.log("window.......", document.documentElement.clientWidth); */
      this.isMobileView = document.documentElement.clientWidth <= 780;
     /*  console.log(this.isMobileView) */
      this.cdr.detectChanges();
    }
  
    isMobile() {
      return this.isMobileView;
    }
  


}
