import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class NuevaSolicitudService {
  constructor(private http: HttpClient) {}

  async validarIdentificacion(
    frontImage: string | null,
    backImage: string | null,
    uuid: string | null
  ): Promise<any> {
    try {

      const apiUrl = `${environment.config.apiUrl}verificar/identificacion`;
      const formData = new FormData();

      formData.append("uuid", uuid || "");

      if (frontImage) {
        const fileFrontImage = await this.getBlobFromFile(frontImage);
        formData.append("frontImage", fileFrontImage, "frontImage.jpg");
      }
      if (backImage) {
        const fileBackImage = await this.getBlobFromFile(backImage);
        formData.append("backImage", fileBackImage, "backImage.jpg");
      }

      console.log("Archivo adjunto");

      const response = await this.http.post(apiUrl, formData).toPromise();

      return response;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  checkUUIDstatus(uuid: string): Observable<any> {
    const url = `${environment.config.apiUrl}sse/verification/status/${uuid}`;
    const eventSource = new EventSource(url);

    return new Observable((observer) => {
      eventSource.onmessage = async (event) => {
        const response = JSON.parse(event.data);

        if (response.data.status == "Checked") {
          try {
            const result = await this.validarIdentificacion("", "", uuid);
            console.log("Validation response:", result);
            observer.next(result);
            eventSource.close();
          } catch (error) {
            console.error("Error sending validation request:", error);
            observer.error(error);
            eventSource.close();
          }
        }
      };

      eventSource.onerror = (error) => {
        console.error("SSE error:", error);
        observer.error(error);
        eventSource.close();
      };
    });
  }

  async guardarDatosSolicitud(data, ineFrontal, ineReverso) {
    const formData = new FormData();

    // Función para aplanar objetos anidados
    const flattenObject = (obj, parentKey = "", res = {}) => {
      for (let key in obj) {
        if (obj.hasOwnProperty(key)) {
          let propName = parentKey ? `${parentKey}_${key}` : key;
          if (
            typeof obj[key] === "object" &&
            obj[key] !== null &&
            !(obj[key] instanceof File)
          ) {
            flattenObject(obj[key], propName, res);
          } else {
            res[propName] = obj[key];
          }
        }
      }
      return res;
    };

    // Aplanar los datos
    const flatData = flattenObject(data);

    // Agregar todas las propiedades del objeto aplanado al FormData
    for (const key in flatData) {
      if (flatData.hasOwnProperty(key)) {
        formData.append(key, flatData[key]);
      }
    }
    if (ineFrontal) {
      const fileFrontImage = await this.getBlobFromFile(ineFrontal);
      formData.append("ine_frontal", fileFrontImage, "frontImage.jpg");
    }
    if (ineReverso) {
      const fileBackImage = await this.getBlobFromFile(ineReverso);
      formData.append("ine_reverso", fileBackImage, "backImage.jpg");
    }

    try {
      const response = await fetch(
        `${environment.config.apiUrl}solicitud/guardar_datos_generales`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
          },
          body: formData,
        }
      );

      if (response.ok) {
        const responseBody = await response.json();
        return true;
      } else {
        console.error(
          "Error en la respuesta:",
          response.status,
          response.statusText
        );
        return false;
      }
    } catch (e) {
      console.error("Error en la solicitud:", e);
      return false;
    }
  }
  private async getBlobFromFile(filePath: string): Promise<Blob> {
    const response = await fetch(filePath);
    const blob = await response.blob();
    return blob;
  }

  getVistasPrevias( id_user ) {
    console.log( 'Buscando', id_user );

    return this.http.post(`${environment.config.apiUrl}dashboard/vistas_previas`,
    id_user ).toPromise();
  }

  getIdUser(folio){
    console.log( 'Buscando', folio );

    return this.http.post(`${environment.config.apiUrl}get_id_user`,
      folio ).toPromise();
  }
  crearCotizacionVistaPrevia( form ) {
    console.log( 'Buscando', form );

    return this.http.post(`${environment.config.apiUrl}cotizacion/crear_vista_previa`,
    form ).toPromise();
  }
  guardarTodosDatosSolicitud(email, rfc,  data) {
		return this.http.post(`${environment.config.apiUrl}dynamodb/guardar`, {
			data,
			tableName: 'clientes',
			keys: {
				email,
        rfc
			}
		}).toPromise()
	}

  GenerarURLEnvioDocOCR(data){
    return this.http.post(`${environment.config.apiUrl}getUrl`,
      data ).toPromise();
  }
}
