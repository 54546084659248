import { Component, EventEmitter, Inject, Input, OnInit, Output } from "@angular/core";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { NuevaSolicitudService } from "src/app/_services/nueva_solicitud.service";
import { Clipboard } from '@angular/cdk/clipboard';

@Component({
  selector: "app-generacion-envio-url",
  templateUrl: "./generacion-envio-url.component.html",
  styleUrls: ["./generacion-envio-url.component.scss"],
})
export class GeneracionEnvioUrlComponent implements OnInit {
  @Input() folio: string;
  @Input () celular:string;
  @Output() closeMenu = new EventEmitter<void>();
  
  solicitante: boolean = true;
 
  url: string = null;
  isSubmitted:boolean = false;

  isLoading: boolean = false;
  

  constructor(
    private nuevaSolicitudService: NuevaSolicitudService,
     private clipboard: Clipboard,
     private snackBar: MatSnackBar
  ) {}

  ngOnInit(): void {

    this.celular=null
 
  }

async generacionURL() {
    console.log("Valor de foli",this.folio);
    if (this.folio.length > 0) {
      this.isLoading = true;
      const data = {
        folio: this.folio,
        solicitante: this.solicitante,
      };
      try {
        const res:any = await this.nuevaSolicitudService.GenerarURLEnvioDocOCR(
          data
        );

        if(res?.code===200){
          this.url = res.url;
          this.snackBar.open(res.message, 'Cerrar', 
            { 
              duration: 2000,
              horizontalPosition: "center",
              verticalPosition: "top",
             });
        }

        console.log("Respuesta de URL",res);
      } catch (error) {
        console.error(error);
      } finally {
        this.isLoading = false;
      }
    }else{
      return;
    }

  }
  copy() {
    if (this.url) {
      this.clipboard.copy(this.url);
      this.snackBar.open('URL copiada al portapapeles', 'Cerrar', 
        { 
          duration: 2000,
          horizontalPosition: "center",
          verticalPosition: "top",
        });
    }else{
      this.snackBar.open('Es necesario generar URL', 'Cerrar', 
        { 
          duration: 2000,
          horizontalPosition: "center",
          verticalPosition: "top",
        });
    }
  } 

  enviarURL(){
    console.log(this.celular)

    console.log(this.celular)

    if(this.celular != null && this.celular.length){
      const whatsappBaseUrl = 'https://api.whatsapp.com/send';
      const message = encodeURIComponent(`Para continuar con su trámite, por favor suba sus documentos en el siguiente enlace: ${this.url}`);
      const whatsappUrl = `${whatsappBaseUrl}?phone=${this.celular}&text=${message}`;
      window.open(whatsappUrl, '_blank');
    }else{
      this.snackBar.open('Es necesario ', 'Cerrar', 
        { 
          duration: 2000,
          horizontalPosition: "center",
          verticalPosition: "top",
        });
    }
  }

  validarYEnviarURL() {
    this.isSubmitted = true; 

    console.log(this.url)

    if(this.url==null){
      this.snackBar.open('Es necesario generar URL', 'Cerrar', 
        { 
          duration: 2000,
          horizontalPosition: "center",
          verticalPosition: "top",
        });
        return
    }

    if (!this.celular) {
      this.snackBar.open('Por favor, ingrese un teléfono válido', 'Cerrar', {
        duration: 2000,
        horizontalPosition: 'center',
        verticalPosition: 'top',
      });
      return;
    }

    this.enviarURL();
  }

    cerrarMenu() {
      this.celular = null;
      this.isSubmitted = false;
      this.url = null;
      this.closeMenu.emit();
    }
}
