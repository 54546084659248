import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { GoogleMapsComponent } from 'src/app/_components/google-maps/google-maps.component';
import { SolicitudService, UserService } from 'src/app/_services';
import { NuevaSolicitudService } from 'src/app/_services/nueva_solicitud.service';

@Component({
  selector: 'app-solicitud-resposiva-ocr',
  templateUrl: './solicitud-resposiva-ocr.component.html',
  styleUrls: ['./solicitud-resposiva-ocr.component.scss']
})
export class SolicitudResposivaOcrComponent implements OnInit {
  id_cliente = null;
  data_get_document: any = {};
  documentos_necesarios = 0;
  faltanDocumentos = false;
  perfil = 0;
  folio = "";
  tipo_persona = 1;
  tipo_aval = 1;
  cancelDoc = false;
  completado = false;
  documentos_estados_cuenta = [];
  solicitud_prellenada = false;
  omitirAval=false;
  id_usuario

  solicitante= true;

  isUploading

  formData: FormData;
  formDataAval: FormData;
  Documentos = {
    DocumentosSolicitante: [],
    DocumentoComprobanteDomicilio: [],
    DocumentosSolicitanteLegal: [],
    DocumentosAval: [],
    DocumentosAvalDomicilio:[],
    DocumentosAvalLegal: [],
    
  };
  geoLocalizacion:boolean=false;
  geoLocalizacionActivo:boolean=false;
  geoLocalizacionAval:boolean=false;


  tipoDocumento
  constructor(
     private nuevaSolicitudService: NuevaSolicitudService,
        private userService: UserService,
        private _snackBar: MatSnackBar,
        private route: ActivatedRoute,
        public dialog: MatDialog,
        private solicitudService: SolicitudService,
        private cdr: ChangeDetectorRef,
        private router: Router
  ) { 
    
  }

  async ngOnInit() {

    await this.processQueryParams();

    this.id_cliente = localStorage.getItem("current_id_cliente");
    /* this.folio = localStorage.getItem("folio_solicitud");
    this.folio = "715997" */
    this.data_get_document =
      this.tipo_persona == 1
        ? {
            folio: this.folio.toString(),
            id_cliente: this.id_cliente,
            tipo_persona: this.tipo_persona,
            tipo_aval: this.tipo_aval,
            empleado: false,
            nuevo_flujo: true,
          }
        : {
            folio: this.folio,
            tipo_persona: this.tipo_persona,
            tipo_aval: this.tipo_aval,
          };
  
  await this.getIdUser();
   await this.ObtenerStatus();
 
  }
  async processQueryParams() {

    let parametro=null;
    
    this.route.queryParams.subscribe(params => {
      parametro=params['data'];
      if (!parametro) {
       return
      }
    });
   await this.desEncriptacion(parametro);
  }
  async desEncriptacion(dataEncryption){

    try{

      const parametros:any= await this.solicitudService.ObtenerParametrosSolicitud(dataEncryption);

      //console.log("----------",parametros);

      this.folio=parametros?.data?.folio;
      this.solicitante=parametros?.data?.solicitante;

      console.log("Valor folio parametros : ", this.folio);
      console.log("Valor solicitante : ", this.solicitante)


    }catch(e){
      console.log("Error",e)
    }

  }

  async ObtenerStatus() {
    this.Documentos = await this.getDocumentosData();

    this.data_get_document.id_cliente = this.id_cliente;

    try {
      const res = await this.userService
        .ObtenerStatusDocuments(this.data_get_document)
        .toPromise();

      this._actualizarDocumentos(
        this.Documentos.DocumentosSolicitante,
        res?.DocumentosSolicitante
      );
      /* this._actualizarDocumentos(
        this.Documentos.DocumentoComprobanteDomicilio,
        res.DocumentosSolicitante
      ); */

      // Actualizar los datos de los documentos del aval
      this._actualizarDocumentos(
        this.Documentos.DocumentosAval,
        res.DocumentosAval
      );

      this._actualizarDocumentos(
        this.Documentos.DocumentosAvalDomicilio,
        res.DocumentosAval
      );

      this.documentos_necesarios =
        this.Documentos.DocumentosSolicitante.length +
        this.Documentos.DocumentosAval.length;
      this.documentos_estados_cuenta =
        this.Documentos.DocumentosSolicitante.filter((documento) =>
          [4, 17, 18].includes(documento.tipo_documento)
        );

        const sinEstadosCuenta={
          DocumentosSolicitante:  this.Documentos.DocumentosSolicitante.filter(
            (documento) => ![4, 17, 18].includes(documento.tipo_documento)
          ),
        }

        this.Documentos.DocumentosSolicitante=sinEstadosCuenta.DocumentosSolicitante;

      if (Number(this.perfil) === 4) {
        const result1 = this.Documentos.DocumentosSolicitante.filter(
          (doc: any) => {
            return doc.status === 2;
          }
        );

        const result2 = this.Documentos.DocumentosAval.filter(
          (doc: any) => {
            return doc.status === 2;
          }
        );
        this.cancelDoc = result1.length + result2.length > 0;
      }

      this.faltanDocumentos = false;
      this._hayDocumentosSinCargar(this.Documentos.DocumentosSolicitante);
      this._hayDocumentosSinCargar(this.Documentos.DocumentosAval);
      this.DocumentosCompletos();
    } catch (error) {
      console.error('Error al obtener los documentos:', error);
      this._snackBar.open(
        "Ha ocurrido un error, por favor vuelve a intentarlo. ",
        "Ok",
        {
          duration: 10000,
          horizontalPosition: "center",
          verticalPosition: "top",
          panelClass: ["warning-snackbar"],
        }
      );
    }
  }

  private _actualizarDocumentos(original: any[], nuevos: any[]) {

    for (const documento of original) {
      const actualizado = nuevos.find(
        (doc: any) => doc.tipo_documento === documento.tipo_documento
      );
      if (actualizado) {
        documento.comentarios = actualizado.comentarios || documento.comentarios;
        documento.status = actualizado.status || documento.status;
        documento.url = actualizado.url || documento.url;
        documento.solicitante =documento?.solicitante;
        if (actualizado.tipo_documento == 11 || actualizado.tipo_documento == 12) {
          this.getDynamoDB(documento);
        }
      }
    }
  }

  private _hayDocumentosSinCargar(documentos) {
    if (!this.faltanDocumentos) {
      for (const documento of documentos) {
        if (documento.tipo_documento != 21 && documento.status == 0) {
          this.faltanDocumentos = true;
          break;
        }
      }
    }
  }
  DocumentosCompletos() {
    if (!this.userService.alertService.isOnline()) return;
    this.completado = true;
    let status = 0;

    this.Documentos.DocumentosSolicitante.forEach((documento) => {
      if (documento.requerido == true && documento.status != 3) {
        this.completado = false;
      }
      this.solicitud_prellenada =
        documento.tipo_documento == 21 && documento.url.length > 0;
    });
    this.Documentos.DocumentosAval.forEach((documento) => {
      if (documento.requerido == true && documento.status != 3) {
        this.completado = false;
      }
    });

    if (this.Documentos.DocumentosSolicitanteLegal)
      this.Documentos.DocumentosSolicitanteLegal.forEach((documento) => {
        if (documento.requerido == true && documento.status != 3) {
          this.completado = false;
        }
        this.solicitud_prellenada =
          documento.tipo_documento == 21 && documento.url.length > 0;
      });
    if (this.Documentos.DocumentosAvalLegal)
      this.Documentos.DocumentosAvalLegal.forEach((documento) => {
        if (documento.requerido == true && documento.status != 3) {
          this.completado = false;
        }
      });

    if (!this.solicitud_prellenada) {
      this.documentos_necesarios -= 1;
    }
  }
  SeleccionarDocumento(doc, solicitante) {
    console.log("***********Solicitante**************");
    console.log("folio : ", this.folio);
    console.log("Cliente : ", this.id_cliente.toString());
    console.log("Tipo de documento : ", doc.tipo_documento);
    console.log("Solicitante", solicitante);
    console.log("ID Cliente : ", this.id_cliente.toString());
    console.log("validacion : ", (doc.status == 3 || doc.status == 1));

    this.tipoDocumento = doc.tipo_documento;

    solicitante = true;
    this.formData = new FormData();
    this.formData.append("folio", this.folio);
    this.formData.append("id_cliente", this.id_cliente.toString());
    this.formData.append("tipo_documento", doc.tipo_documento);
    this.formData.append("solicitante", solicitante);
    this.formData.append("id_usuario_mod", this.id_cliente.toString());
    this.formData.append("type", doc.tipo_documento);
    this.formData.append("nombre", doc.tipo_documento == 3 ? "ine_1" : "constancia_1");

    const $document: HTMLInputElement = document.querySelector("#document");
    $document.value = "";
    $document.click(); 
    setTimeout(() => {
     // console.log("No se selecciono ningun archivo");
      this.ObtenerStatus();
    }, 30000); 
}
  async SubirDocumento({ target: { files } }) {
      const file = files[0];
  
      if (file) {
        this.formData.append("document", file);
  
        if (this.tipoDocumento == 3 || this.tipoDocumento == 5) {
          // Ejecutar el proceso Textract
          const statusTextract = await this.textract(this.formData);

          console.log("VALOR TESTRACT :::",statusTextract);
  
          if (statusTextract?.statusCode === 400) {
            this._snackBar.open(
              statusTextract.message,
              "Ok",
              {
                duration: 10000,
                horizontalPosition: "center",
                verticalPosition: "top",
                panelClass: ["warning-snackbar"],
              }
            );
            this.ObtenerStatus();
            this.isUploading=false;
            this.cdr.detectChanges();
            return;
          }
  
          if (statusTextract?.statusCode === 500) {
            this._snackBar.open(
              statusTextract?.message,
            "Ok",
            {
              duration: 10000,
              horizontalPosition: "center",
              verticalPosition: "top",
              panelClass: ["error-snackbar"],
            }
          
          )
          
            this.isUploading=false;
            this.cdr.detectChanges();
            return;
          }
          if (statusTextract?.statusCode === 200) {
            if(this.tipoDocumento == 3){
              await this.cambioStatus();
              this.isUploading=false;
            }
          }
  
        }
  
  
        this.userService.SubirDocumento(this.formData).subscribe(
          (res) => {
            this._snackBar.open(
              "Se ha cargado correctamente el documento",
              "Ok",
              {
                duration: 5000,
                horizontalPosition: "center",
                verticalPosition: "top",
                panelClass: ["success-snackbar"],
              }
            );
            this.ObtenerStatus();
          },
          (err) => {
            this.isUploading=false;
            this._snackBar.open(
              "Ha ocurrido un error, por favor vuelve a intentarlo.",
              "Ok",
              {
                duration: 10000,
                horizontalPosition: "center",
                verticalPosition: "top",
                panelClass: ["warning-snackbar"],
              }
            );
          }
        );
      }
      else{
        console.log("No se selecciono ningun archivo");
        this.ObtenerStatus();
      }
  }

    SeleccionarDocumentoAval(doc, solicitante) {
      // if (!this.userService.alertService.isOnline()) return;
      // const doc = (solicitante) ? this.Documentos.DocumentosSolicitante[idx] : this.Documentos.DocumentosAval[idx]
      console.log("::::AVAL::::")
      console.log("folio : ", this.folio)
      console.log("Cliente : ", this.id_cliente.toString())
      console.log("Tipo de documento : ", doc.tipo_documento)
      console.log("Solicitante", false)
      console.log("ID Cliente : ", this.id_cliente.toString())
      console.log("validacion : ", (doc.status == 3 || doc.status == 1))
  
      this.tipoDocumento = doc.tipo_documento;
      if (doc.status == 3 || doc.status == 1) return;
  
      solicitante = false
      this.formDataAval = new FormData();
      this.formDataAval.append("folio", this.folio);
      this.formDataAval.append("id_cliente", this.id_cliente.toString());
      this.formDataAval.append("tipo_documento", doc.tipo_documento);
      this.formDataAval.append("solicitante", solicitante);
      this.formDataAval.append("id_usuario_mod", this.id_cliente.toString() ?? this.id_usuario);
      this.formDataAval.append("type", doc.tipo_documento);
      this.formDataAval.append("nombre", "ine_aval");
  
  
      const $document: any = document.querySelector("#documentaval");
      $document.value = "";
  
      let changeDetected = false;
  
      $document.addEventListener("change", (event: Event) => {
          const input = event.target as HTMLInputElement;
          if (input.files && input.files.length > 0) {
              console.log("Archivo seleccionado:");
              changeDetected = true; 
          }
      }, { once: true }); // Se ejecuta solo una vez para evitar múltiples llamadas
      $document.click();
  
      // Verificar después de un tiempo si el evento `change` no ocurrió
      setTimeout(() => {
          if (!changeDetected) {
              console.log("El usuario canceló la selección del archivo.");
              this.ObtenerStatus();
          }
      }, 10000); 
     
    }
  
    async SubirDocumentoAval({ target: { files } }) {
  
      // if (!this.userService.alertService.isOnline()) return;
      const file = files[0];
  
      if (file) {
        this.formDataAval.append("document", file);
  
        if (this.tipoDocumento == 9 ) {
          // Ejecutar el proceso Textract
          const statusTextract = await this.textract(this.formDataAval);
  
          if (statusTextract?.statusCode === 400) {
            this._snackBar.open(
              statusTextract.message,
              "OK",
              {
                duration: 5000,
                horizontalPosition: "center",
                verticalPosition: "top",
                panelClass: ["warning-snackbar"],
              }
            )
            this.isUploading=false;
            this.cdr.detectChanges();
            return;
          }
  
          if (statusTextract?.statusCode === 500) {
            this.isUploading=false;
            this.cdr.detectChanges();
            return;
          }
        }
  
        this.userService.SubirDocumento(this.formDataAval).subscribe(
          (res) => {
            const $document = document.querySelector("#documentaval");
            this._snackBar.open(
              "Se ha cargado correctamente el documento",
              "Ok",
              {
                duration: 5000,
                horizontalPosition: "center",
                verticalPosition: "top",
                panelClass: ["success-snackbar"],
              }
            );
            // this.userService.changeStatus(this.folio, 2, 1) // ya se realiza desde el servicio
            this.ObtenerStatus();
          },
          (err) => {
            this._snackBar.open(
              "Ha ocurrido un error, por favor vuelve a intentarlo. ",
              "Ok",
              {
                duration: 10000,
                horizontalPosition: "center",
                verticalPosition: "top",
                panelClass: ["warning-snackbar"],
              }
            );
            this.ObtenerStatus();
          }
        );
      }
    }

  resset = (event) => {
    event.target.value = null;
  };
  VerDocumento(documento, solicitante) {
    localStorage.setItem("documento", JSON.stringify(documento));

    if (documento.url) {
      const url = `#/dashboard/cliente/${this.folio}/ver_documento`;
      window.open(url, "_blank");
    }
  }
  openGoogleMapsDialog(documento): void {
  
      const dialogRef = this.dialog.open(GoogleMapsComponent, {
        data: {
          folio: this.folio,
          tipo_documento: documento.tipo_documento,
          solicitante: documento.solicitante
        },
        width: '100%', // 90% del ancho de la ventana
        height: '100%', 
      });
      dialogRef.afterClosed().subscribe(result => {
        this.ObtenerStatus();
      });
    }
    async getIdUser() {
      const data = {
        folio: this.folio
      }
      const res: any = await this.nuevaSolicitudService.getIdUser(data)
      this.id_cliente = res.data[0].id_cliente
      localStorage.setItem('current_id_cliente', this.id_cliente)
    }

     /* TESTRACT***************************************************************** */
      async textract(formaData): Promise<{ statusCode: number; message: string } | null> {
        console.log("##########--Textract--##############");
    
        let res;
    
        try {
    
          if(this.tipoDocumento == 3 || this.tipoDocumento == 9){
            res = await this.userService.SubirTextract(formaData);
            console.log("Respuesta de INE ...", res);
          }
          
          if(this.tipoDocumento == 5){
            res = await this.userService.SubirTextractOCR(formaData);
    
            console.log("Respuesta de constancia ...", res);
          }
    
    
          if (res.statusCode === 200) {
            this.ObtenerStatus();
            return {
              statusCode: 200,
              message: res?.message || "Documento cargado y procesado con éxito",
            };
          }
    
          if (res.statusCode === 400) {
            this.isUploading=false;
            return {
              statusCode: 400,
              message: res?.message || "El documento no contiene suficiente información válida. Por favor, intente subir otro documento.",
            };
          }
    
          if (res.statusCode === 500) {
            this.isUploading=false;
            this._snackBar.open(
              "Ha ocurrido un error, por favor vuelve a intentarlo. ",
              "Ok",
              {
                duration: 10000,
                horizontalPosition: "center",
                verticalPosition: "top",
                panelClass: ["error-snackbar"],
              }
            );

            return {
              statusCode: 500,
              message: res?.message || "Error: No se pudo guardar la información.",
            };
          }
          return {
            statusCode: res.statusCode || 500,
            message: res?.message || "Error inesperado. Inténtalo más tarde.",
          };
    
        } catch (error) {
          console.error("Error en el proceso Textract:", error);
          if (error.status === 400) {
            this.isUploading=false;
            return {
              statusCode: 400,
              message:  error.error?.message || "El documento no contiene suficiente información válida. Por favor, intente subir otro documento.",
            };
          }
    
          return {
            statusCode: 500,
            message:   error.error?.message || "Error interno del servidor. Inténtalo más tarde.",
          };
        } finally {
          console.log("##########--Textract--##############");
        }
      }

      async cambioStatus(){
        try {        
          let nombres: string='';
          const datosDynamo:any = await this.datosDynamoDB();
    
          if (datosDynamo?.contacto?.nombreCompleto.length>0) {
            nombres = datosDynamo.contacto.nombreCompleto;
          } else {
            const clientDetails = localStorage.getItem('client_details');
            if (clientDetails) {
              const parsedClientDetails = JSON.parse(clientDetails);
              nombres = parsedClientDetails.cliente_nombre || 'A QUIEN CORRESPONDA';
            } else {
              nombres = 'A QUIEN CORRESPONDA';
            }
          }
          
          const data ={folio:this.folio, id_cliente:this.id_cliente, nombres:nombres}
          await this.solicitudService.GuardarStatusInfoGeneral(data);
    
          await this.userService.changeStatus(this.folio,1,3).toPromise();
      
        } catch (error) {
          console.error("Error en la lógica de guardado o cambio de estado:", error);
        }
      }
      async datosDynamoDB() {
        try {
          if (!this.folio) {
            console.log("Folio inválido o no definido.");
            return;
          }
          const res = await this.solicitudService.ObtenerSolicitud(this.folio);
         
          if (!res || Object.keys(res).length === 0) {
            console.warn("No se encontraron datos en DynamoDB.");
            return;
          }
          return res;
        } catch (error) {
          console.error("Error al obtener los datos de DynamoDB:", error);
        }
      }
    
    //Validacion de geolocalizacion

    async getDynamoDB(doc) {
   
      if (this.folio.length > 0) {
        try {
          const res = await this.datosDynamoDB();
         
          if (doc.tipo_documento == 11 ) {
  
            if(doc?.solicitante==true || doc?.solicitante==undefined){
             
              if (res.direccion && res.direccion.lat && res.direccion.lng) {
                this.geoLocalizacion = true;
                this.cdr.detectChanges();
              }
            }else{
              if (res.aval?.direccion && res.aval.direccion.lat && res.aval.direccion.lng) {
                this.geoLocalizacionAval = true;
                this.cdr.detectChanges();
              }
            }
          }
          if (doc.tipo_documento == 12) {
  
            if (res.direccionActivo && res.direccionActivo.lat && res.direccionActivo.lng) {
              this.geoLocalizacionActivo = true;
              this.cdr.detectChanges();
        
  
            }
          } else {
            return
          }
        } catch (error) {
          console.error("Error al obtener de DynamoDB", error);
        }
      } else {
        return
      }
  
    }

    getDocumentosData() {
      return {
        DocumentosSolicitante: [
          {
            tipo_documento: 3,
            nombre_documento: "Identificación oficial",
            descripcioncompleta: "Copia de identificación oficial VIGENTE (INE, Pasaporte)",
            descripcion_corta: "INE o Pasaporte",
            comentarios: "",
            requerido: true,
            status: 0,
            url: ""
          },
          {
            tipo_documento: 5,
            nombre_documento: "Constancia fiscal actual",
            descripcioncompleta: "Constancia de situación fiscal actualizada con antigüedad no mayor a 6 meses.",
            descripcion_corta: "Menor a 3 meses",
            comentarios: "",
            requerido: false,
            status: 0,
            url: ""
  
          },
          {
            tipo_documento: 4,
            nombre_documento: "Estados de cuenta",
            descripcioncompleta: "Últimos 3 estados de cuenta y recibos de nómina (ambos completos y con cadena digital del SAT)",
            descripcion_corta: "Menor a 3 meses",
            comentarios: "",
            requerido: true,
            status: 0,
            url: ""
          },
          {
            comentarios: "",
            descripcion_corta: "",
            descripcion_completa: "",
            nombre_documento: "Buro PDF",
            requerido: false,
            status: 0,
            tipo_documento: 20,
            url: ""
          },
          {
            tipo_documento: 17,
            nombre_documento: "Estados de cuenta adicional",
            descripcioncompleta: "Últimos 3 estados de cuenta y recibos de nómina (ambos completos y con cadena digital del SAT)",
            descripcion_corta: "Menor a 3 meses",
            comentarios: "",
            requerido: true,
            status: 0,
            url: ""
          },
          {
            tipo_documento: 18,
            nombre_documento: "Estados de cuenta adicional",
            descripcioncompleta: "Últimos 3 estados de cuenta y recibos de nómina (ambos completos y con cadena digital del SAT)",
            descripcion_corta: "Menor a 3 meses",
            comentarios: "",
            requerido: true,
            status: 0,
            url: ""
          },
          {
            comentarios: "",
            descripcion_corta: "Cotización",
            descripcioncompleta: "Cotización creada del vendor",
            nombre_documento: "Cotización vendor",
            requerido: true,
            status: 0,
            tipo_documento: 22,
            url: ""
          },
          {
            tipo_documento: 11,
            nombre_documento: "Comprobante de domicilio",
            descripcioncompleta: "Copia del comprobante de de domicilio actual con antigüedad no mayor a 3 meses.",
            descripcion_corta: "Documento personal",
            comentarios: "",
            requerido: false,
            status: 0,
            url: ""
          },
          {
            tipo_documento: 12,
            nombre_documento: "Comprobante de domicilio donde se encontrará el equipo",
            descripcioncompleta: "Comprobante de domicilio donde se encontrará el equipo",
            descripcion_corta: "Donde se encontrará el equipo",
            comentarios: "",
            requerido: false,
            status: 0,
            url: ""
          },
  
        ],
        DocumentoComprobanteDomicilio: [],
        DocumentosSolicitanteLegal: [],
        DocumentosAval: [
          {
            tipo_documento: 9,
            nombre_documento: "Identificación oficial del representante legal",
            descripcioncompleta: "Copia de identificación official VIGENTE con fotografía y firma del representante legal (INE, Pasaporte)",
            descripcion_corta: "INE o Passaporte",
            comentarios: "",
            requerido: false,
            status: 0,
            url: ""
          },
          {
            comentarios: "",
            descripcion_corta: "",
            descripcion_completa: "",
            nombre_documento: "Buro PDF",
            requerido: false,
            status: 0,
            tipo_documento: 20,
            url: ""
          },
          {
            tipo_documento: 11,
            nombre_documento: "Comprobante de domicilio",
            descripcioncompleta: "Copia del comprobante de de domicilio actual con antigüedad no mayor a 3 meses.",
            descripcion_corta: "Documento personal",
            comentarios: "",
            requerido: true,
            status: 0,
            solicitante:false,
            url: ""
          }
        ],
        DocumentosAvalDomicilio: [
          {
            tipo_documento: 11,
            nombre_documento: "Comprobante de domicilio",
            descripcioncompleta: "Copia del comprobante de de domicilio actual con antigüedad no mayor a 3 meses.",
            descripcion_corta: "Documento personal",
            comentarios: "",
            requerido: true,
            status: 0,
            solicitante:false,
            url: ""
          }
        ],
        DocumentosAvalLegal: []
      }
    }
    
}
