import { Component, Input, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSidenav } from '@angular/material/sidenav';
import { Route, Router } from '@angular/router';
import { SelectorFechasComponent } from 'src/app/_components/selector-fechas/selector-fechas.component';
import { Perfil } from 'src/app/_models/perfiles';
import { Tramite } from 'src/app/_models/tramite.model';
import { AuthenticationService, SolicitudService } from 'src/app/_services';
import { ComentariosService } from 'src/app/_services/comentarios.service';
import { PerfilService } from 'src/app/_services/perfil.service';
import { VendorsService } from 'src/app/_services/vendors.service';
import { WsService } from 'src/app/_services/ws.service';


@Component({
  selector: 'app-solicitudes-dashboard',
  templateUrl: './solicitudes-dashboard.component.html',
  styleUrls: ['./solicitudes-dashboard.component.scss']
})
export class SolicitudesDashboardComponent implements OnInit {
  @ViewChild('filtroMenu') filtroMenu!: MatSidenav;
  commentsUnreadLength: number = 0;
  date = Date.now()


  PERFIL_VENDOR = 2
  country = ''
  new_home = false
  sumar_procesos = ['expediente', 'confirmacion', 'comite']
  tipo_dashboard = ''
  importe = 0
  proceso_seleccionado = 0
  perfil = null
  id_usuario = null

  verCienteAllowed = [2,3,6,12,7,10,25,27,28]
  verCliente = false
  updateStatus = false
  updateStatusList = false
  precalificar = false
  ver_productos = true
  cotizar = true
  loading = false

  filtro = {
    hijos_director_comercial: [],
    hijos_gerente: [],
    hijos_comercial: [],
    hijos_vendor: [],
    hijos_vendedor: []
  }

  busqueda = {
    id_cliente: null, 
    tipo_cliente: null, 
    fecha_inicio: null, 
    fecha_final: null, 
    numero_pagina: 1, 
    tamano_pagina: 50,
    is_demo: false,
    country: '',
    semana_compromiso: null as number[] | null 
  }

  tramites_origen:any = {}
  tramites:any = null
  tramites_solicitud: any = {}

  comments_unread = []
  show_notifications = false
  extended = true
  total_unread_comments = 0
  proceso:any = {
    solicitud: {
      cantidad: 0,
      importe: 0
    },
    expediente: {
      cantidad: 0,
      importe: 0
    },
    confirmacion: {
      cantidad: 0,
      importe: 0
    },
    comite: {
      cantidad: 0,
      importe: 0
    },
    aprobadas: {
      cantidad: 0,
      importe: 0
    },
    aprobadas_con: {
      cantidad: 0,
      importe: 0
    },
    info_adicional: {
      cantidad: 0,
      importe: 0
    },
    contrato: {
      cantidad: 0,
      importe: 0
    },
    contrato_enviado: {
      cantidad: 0,
      importe: 0
    },
    contrato_recibido: {
      cantidad: 0,
      importe: 0
    },
    contrato_activado: {
      cantidad: 0,
      importe: 0
    },
    en_colocacion: {
      cantidad: 0,
      importe: 0
    }
  }
  importe_total = 0
  tramites_resolucion:any = 0
  tramites_resolucion_importe:any = 0
  contratos_realizados_enviados: any = 0
  contratos_realizados_enviados_importe: any = 0
  filtroMenuOpened: boolean = false;


  nombre_usuario:any
  apellido_paterno:any

  lastFilter

  hayTramites = false

  constructor(
    private authenticationService: AuthenticationService,
    private router: Router,
    private perfilService: PerfilService,
    private comentariosServicios: ComentariosService,
    private vendorService: VendorsService,
    private solicitudService: SolicitudService,
    private webSocket: WsService,
    
  ) {
    this.webSocket.messages$.subscribe(msg => this.wsSubscriber(JSON.parse(msg)))
    // this.perfilService.wsSubject.subscribe((msg) => {
    //   console.log("WS: listening...");
    //   this.wsSubcriber(msg)
    // })
  }

  ngOnInit(): void {
    localStorage.removeItem('client_details')
    localStorage.removeItem('solicitud')
    localStorage.removeItem('update')
    localStorage.removeItem('actualizar_informacion')
    localStorage.removeItem('ver_comentarios')
    localStorage.removeItem('vendor')
    localStorage.removeItem('cotizar_producto')
    const {tramitesOrigen, tramites} = this.solicitudService.ObtenerTramitesOrigen()
    if (tramitesOrigen) {
      this.tramites_origen = tramitesOrigen
      this.tramites = tramites
      this.GuardarTramites()
      this.Filtrar(this.lastFilter || '')
      this.ImporteProceso();
      this.ImporteTotal();
      this.hayTramites = true
    }
    const { id, perfil, is_demo = false, id_master, nombre_usuario, apellido_parterno } = JSON.parse(localStorage.getItem('currentUser'))
    this.nombre_usuario = nombre_usuario;
    this.apellido_paterno = apellido_parterno
    this.perfil = Number(perfil)
    this.new_home = true
    this.precalificar = [2,3,4,6,7,12].includes(Number(this.perfil))
    this.ver_productos = ![13,14,15,16,17,18,19,20].includes(this.perfil)
    this.cotizar = ![13,14,15,16,17,18,19,20].includes(this.perfil)
    this.id_usuario = Number(id)
    this.tipo_dashboard = Perfil[this.perfil]
    this.verCliente = this.verCienteAllowed.includes( this.perfil )
    this.updateStatus = this.perfil === 4
    this.updateStatusList = this.perfil === 4
    this.busqueda.is_demo = is_demo
    this.ObtenerSubperfil( this.perfil, this.id_usuario, 'hijos_director_comercial')
    this.comentariosServicios.ObtenerComentariosSinLeer('', this.id_usuario).then( value => {
      console.log(`Comentarios: `, value);
      this.comments_unread = value
      this.comments_unread.forEach(comment => this.total_unread_comments += Number(comment.unread_comments))
    })

    if ([2,7].includes(this.perfil)) {
      this.BuscarVendor( this.perfil == this.PERFIL_VENDOR ? id : id_master)
    }

  
  }

 

  async ObtenerSubperfil(perfil, id_usuario, path){

    // this.tramites = null
    
    this.filtro[path] = []
    const usuarios = await this.perfilService.getUsuarios( perfil, id_usuario );
    this.filtro[path] = usuarios['children'][path]

    this.busqueda.id_cliente = id_usuario
    this.busqueda.tipo_cliente = perfil
    console.log("obtener subperfil")
    this.ObtenerTramites()
  }

  async ObtenerTramites(){
    console.log("ENTRo A OBTENER TRAMITES")
    this.loading = true
    if(!this.busqueda.id_cliente || !this.busqueda.tipo_cliente){
      return null
    }
    const tramites = await this.perfilService.getTramitesComite( this.busqueda )
    if (tramites) {
      this.loading = false
      this.tramites_origen = tramites['cuentas'] || {solicitud: [], expediente: [],confirmacion: [],comite: [], cerradas: [], contrato:[], contrato_enviado:[], contrato_recibido:[], contrato_activo:[], en_colocacion:[]}
      this.tramites = {}
      this.tramites_origen.todas = []
      
      this.GuardarTramites()
      this.Filtrar(this.lastFilter || '')
      this.ImporteProceso();
      this.ImporteTotal();
      
      this.solicitudService.GuardarTramitesOrigen(this.tramites_origen, this.tramites)
    }
  }
  mergeArrays(aprobadas: any[] = [], info_adicional: any[], aprobadas_con: any[]): any[] {
    return [...aprobadas, ...info_adicional, ...aprobadas_con];
  }

  mergeContratos(contrato: any[], contrato_cancelado: any[]){
    return [...contrato, ...contrato_cancelado]
  }
  // Solicitudes(){
  //   const filterSolicitud = this.tramites.solicitud.filter(function(e){
  //     return e.dias_tramite.days < 90
  //   })    
  //   this.tramites.solicitud = filterSolicitud
  // }

  ImporteTotal(){
    let importeTotal = 0
    const contratos_realizados_enviados_importe = this.contratos_realizados_enviados_importe || 0
    const tramites_resolucion_importe = this.tramites_resolucion_importe || 0

    for( const proceso in this.tramites_origen){
      this.tramites[proceso].forEach( (item:Tramite) => importeTotal += this.sumar_procesos.includes(proceso) ? Number(item.precio_con_iva) : 0)
    }
    importeTotal += tramites_resolucion_importe + contratos_realizados_enviados_importe + this.proceso.contrato.importe + this.proceso.contrato_activado.importe
    this.importe = importeTotal
  }
  ImporteProceso(){

    this.proceso.solicitud.importe = 0
    this.proceso.expediente.importe = 0
    this.proceso.confirmacion.importe = 0
    this.proceso.comite.importe = 0
    
    this.tramites_resolucion_importe = 0
    this.proceso.contrato.importe = 0
    this.proceso.contrato_enviado.importe = 0
    this.proceso.contrato_activado.importe = 0
    this.proceso.en_colocacion.importe = 0

    this.proceso.solicitud.cantidad = this.tramites.solicitud.length
    this.proceso.expediente.cantidad = this.tramites.expediente.length
    this.proceso.confirmacion.cantidad = this.tramites.confirmacion.length
    this.proceso.comite.cantidad = this.tramites.comite.length
    this.proceso.aprobadas.cantidad = this.tramites.aprobadas.length
    this.proceso.aprobadas_con.cantidad = this.tramites.aprobadas_con.length
    this.proceso.info_adicional.cantidad = this.tramites.info_adicional.length
    this.tramites_resolucion = this.proceso.aprobadas.cantidad +
    this.proceso.aprobadas_con.cantidad +
    this.proceso.info_adicional.cantidad
    
    this.proceso.contrato.cantidad = this.tramites.contrato.length
    this.proceso.contrato_enviado.cantidad = this.tramites.contrato_enviado.length
    this.proceso.contrato_recibido.cantidad = this.tramites.contrato_recibido.length
    this.contratos_realizados_enviados = this.proceso.contrato.cantidad + this.proceso.contrato_recibido.cantidad
    // this.contratos_realizados_enviados_importe = 1// this.proceso.contrato.importe + this.proceso.contrato_enviado.importe + this.proceso.contrato_recibido.importe
    this.proceso.contrato_activado.cantidad = this.tramites.contrato_activo.length
    this.proceso.en_colocacion.cantidad = this.tramites.en_colocacion.length

    // this.contratos_realizados_enviados = this.proceso.contrato.cantidad + this.proceso.contrato_enviado.cantidad + this.proceso.contrato_recibido.cantidad

console.log("CONTRATOS REALIZADOS ENVIADOS",  )
console.log("TRAMITES", this.proceso.contrato)

    this.proceso.aprobadas.importe = 0
    this.proceso.aprobadas_con.importe = 0
    this.proceso.info_adicional.importe = 0
    
    this.tramites.solicitud.forEach( item => this.proceso.solicitud.importe += item.precio_con_iva)
    this.tramites.expediente.forEach( item => this.proceso.expediente.importe += item.precio_con_iva)
    this.tramites.confirmacion.forEach( item => this.proceso.confirmacion.importe += item.precio_con_iva)
    this.tramites.comite.forEach( item => this.proceso.comite.importe += item.precio_con_iva)
    this.tramites.aprobadas.forEach( item => this.proceso.aprobadas.importe += item.precio_con_iva)
    this.tramites.aprobadas_con.forEach( item => this.proceso.aprobadas_con.importe += item.precio_con_iva)
    this.tramites.info_adicional.forEach( item => this.proceso.info_adicional.importe += item.precio_con_iva)
    // this.tramites.contrato.forEach( item => this.proceso.contrato.importe += item.precio_con_iva)
    this.contratos_realizados_enviados_importe = this.tramites.contrato.forEach( item => this.proceso.contrato.importe += item.precio_con_iva) + this.tramites.contrato_enviado.forEach( item => this.proceso.contrato_enviado.importe += item.precio_con_iva)  + this.tramites.contrato_recibido.forEach( item => this.proceso.contrato_recibido.importe += item.precio_con_iva) 
    this.tramites.contrato_activo.forEach( item => this.proceso.contrato_activado.importe += item.precio_con_iva)
    this.tramites.en_colocacion.forEach( item => this.proceso.en_colocacion.importe += item.precio_con_iva)

    this.tramites_resolucion_importe = this.proceso.aprobadas.importe +
    this.proceso.aprobadas_con.importe +
    this.proceso.info_adicional.importe
  }
  


  GuardarTramites(){
    for( const proceso in this.tramites_origen){
      this.tramites[proceso] = this.tramites_origen[proceso].map( i=> i)
    }
  }

  Filtrar( word ) {
    this.lastFilter = word
    console.log("BUSCAR",word)
    for (const proceso in this.tramites_origen) {
      this.tramites[proceso] = this.tramites_origen[proceso].filter( (item:Tramite) => 
        (item.folio.toString().includes(word) || 
        item.nombre_cliente.toString().toLocaleLowerCase().includes(word.toLocaleLowerCase()))
      )

    }
   
  }
  cerrarSidenav() {
    this.filtroMenu.close();
  }

  CambiarStatus() {
    this.updateStatusList = !this.updateStatusList
  }

  MostrarSolicitudes() {
    this.router.navigate(['/dashboard/solicitudes']);
  }

  async ChangeCountry( country ) {
    console.log(`Cambiar país: ${country}`);
    this.busqueda.country = country
    await this.ObtenerTramites()
  }
  async ChangeSemanaCompromiso(semana: number) {
    console.log("semana compromiso", semana);
    
    if (this.busqueda.semana_compromiso === null) {
      this.busqueda.semana_compromiso = [];
    }
    
    const index = this.busqueda.semana_compromiso.indexOf(semana);
    console.log(index);
    
    if (index === -1) {
      this.busqueda.semana_compromiso.push(semana);
    } else {
      this.busqueda.semana_compromiso.splice(index, 1);
    }
    
    if (this.busqueda.semana_compromiso.length === 0) {
      this.busqueda.semana_compromiso = null;
    }
  
    setTimeout(async () => {
      await this.ObtenerTramites();
    }, 10);
  }
  

  async BuscarVendor(id) {
    console.log(`Vendor: ${id}`);
    const {data: {country, name, porcentajes_msi, pago_inicial_msi}} = await this.vendorService.ObtenerVendorPorId(id).toPromise()
    localStorage.setItem('vendor', JSON.stringify({country, name, porcentajes_msi,pago_inicial_msi }))
  }
  toggleNotifiocations() { this.show_notifications = !this.show_notifications }
  openFilters() {
    if (this.filtroMenuOpened) {
      this.filtroMenu.close();
    } else {
      this.filtroMenu.open();
    }
    this.filtroMenuOpened = !this.filtroMenuOpened;
  }
  openNotifications(){
    this.show_notifications = !this.show_notifications
  }

  wsSubscriber(msg) {
    console.log('wsSubscriber: ', {msg});
    
    //   const { event, body } = msg
    //   switch(event) {
    //     case 'new_request':
    //       this.ObtenerTramites().then(()=>{
    //         console.log("Todo ok");
    //       });
    //       break;
    // }
  }

 
  logout() {
    this.authenticationService.logout();
    this.router.navigate(['/login']);
  }
}
