import { Component, OnInit, HostListener, Input } from '@angular/core';
import { AuthenticationService, UserService } from 'src/app/_services';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { MessageBoxComponent } from 'src/app/_dialogs/message-box/message-box.component';

/* tslint:disable */
@Component({
  selector: 'app-crear-cotizacion',
  templateUrl: './crear-cotizacion.component.html',
  styleUrls: ['./crear-cotizacion.component.scss']
})
export class CrearCotizacionComponent implements OnInit {

  constructor(
    private authenticationService: AuthenticationService,
    private router: Router,
    private userService: UserService,
	private dialog: MatDialog,
	private route: ActivatedRoute,

  ) { 
  }

  new_flow = true
  tramite = false;
  creada = false;
  loading = false;
  perfil = null;
  parte = 1;
  precio_equipo = 0;
  user;
  cliente;
  data = {
    folio: null,
    leas: true,
    meses60: false,
    perfil: null,
    poliza: false,
    datosCliente: {
      idCliente: null,
      nombreCliente: null,
      correo: null,
      IDResponsable: null
    },
    datosEquipoArrendar: {} as any,
    generalidadesCotizacion: {} as any,
    arrendamientoPuro: {} as any,
    latitude: '0',
    longitude: '0',
  };


  @HostListener('window:popstate', ['$event'])
  onpopstate(event) {
    if ( this.parte == 1 || this.creada ) {
      console.log('Regresando al dashboard');
      this.router.navigate(['dashboard']);
    } else {
      this.parte--;
    }
    return false;
  }

  ngOnInit(): void {
  console.log('Entrndo a crear cotizacion');
  
  this.creada = localStorage.getItem('cotizacion_creada') == 'true'
  if( this.creada ){
    localStorage.removeItem('cotizacion_creada')
    this.router.navigate(['dashboard'])
    return
  }
	this.user = JSON.parse(localStorage.getItem('currentUser'));
  this.perfil = this.user.perfil_master == 11 ? 11 : this.user.perfil;
  this.data.perfil = this.perfil
	console.log(this.user);
	const id_usuario = this.user.id;
	
	console.log('params:  ', this.route.snapshot.queryParamMap.get('tramite'));
	// console.log('params223:  ', this.router.getCurrentNavigation().extras.state.tramite);
	this.tramite = this.route.snapshot.queryParamMap.get('tramite') == 'true'

  console.log('params:  ', this.route.snapshot.queryParamMap.get('tramite') == 'true') 
  console.log('params:  ', this.tramite)
 
	if (this.tramite) {
		console.log('nuevo trámite');
		this.parte = 0;
    localStorage.removeItem('cotizar_producto')
	} else {
		this.cliente = JSON.parse(localStorage.getItem('client_details'));
		this.data.folio = this.cliente.folio;
		this.data.datosCliente = {
		idCliente: this.cliente.id_cliente,
		nombreCliente: this.cliente.cliente,
		correo: '',
		IDResponsable: Number(id_usuario)
		};
	}
 console.log('Perfil: ', this.perfil, Number(this.perfil) != 3);

    // if( Number(this.perfil) != 3 ) {
    //   console.log("Cambiando");

    //     this.router.navigate(['dashboard'])
    // }
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(position => {
          this.data.latitude = position.coords.latitude.toString();
          this.data.longitude = position.coords.longitude.toString();
      });
    }
  }

  NextPage(data) {
    const SUPER_VENDOR = 11
    history.pushState(null, null, location.href);
    switch (this.parte) {
	  case 0:
		  console.log('alsdnfaslnfiowenf ');
		  this.data.datosCliente = data.datosCliente;
		  this.data.folio = null;
		  break;
      case 1:
        this.data.datosEquipoArrendar = data.equipo;
        this.precio_equipo = data.equipo.precioEquipoIva;
        break;
      case 2:
        this.data.generalidadesCotizacion = data.cotizacion;
        break;
      case 3:
        if ( !this.userService.alertService.isOnline() ) { return; }
        this.loading = true;
        this.data.arrendamientoPuro = data.residual;
        this.data.arrendamientoPuro.factor = this.data.generalidadesCotizacion.factor;
        this.data.meses60 = this.precio_equipo > 1000000 || this.perfil == SUPER_VENDOR
        console.log(this.data);
        const DescuentoCiego = this.data.datosEquipoArrendar.descuentoCiego > 0
        const CrearCotizacion = DescuentoCiego ? this.userService.CrearCotizacionTasaPreferencial.bind(this.userService) : this.userService.CrearCotizacion.bind(this.userService)
        this.data.poliza = this.perfil == 11
        return CrearCotizacion(this.data)
        .subscribe(res => {
          this.loading = false;
          this.creada = true;
          localStorage.setItem("cotizacion_creada", this.creada ? 'true' : 'false')
          const dialogref = this.dialog.open(MessageBoxComponent, {
            width: '500px',
            data: {
              text: 'Éxito',
              text2: 'Se ha enviado la cotización',
              type: 'cotizacion',
              icon: 'success'
            }
          });
          console.log('Cotizacion creada: ', res);
          dialogref.afterClosed().subscribe( verCotizacion => {
            console.log('Ver Cotización???', verCotizacion);
			
            if ( verCotizacion ) {
			  console.log('navegando a cotizacion', verCotizacion);
              localStorage.setItem('cotizacion', res.url_cotizacion);
              return this.router.navigate(['crear_cotizacion', 'ver_cotizacion']);
            }
            this.router.navigate(['dashboard']
          ); });
        }, error => {
          this.loading = false;
          console.log('*** Error: ', error);
        });
    }
    this.parte++;
  }

  logout() {
    this.authenticationService.logout();
    this.router.navigate(['/login']);
  }
}
