import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SolicitudService, UserService } from 'src/app/_services';

@Component({
  selector: 'app-solicitud-ocr-completa',
  templateUrl: './solicitud-ocr-completa.component.html',
  styleUrls: ['./solicitud-ocr-completa.component.scss']
})
export class SolicitudOcrCompletaComponent implements OnInit {

  @Input() data: any;
    nombre_cliente= ""
    folio = ""
    flujo_cotizador = true

  constructor(
        private router: Router,
        private userService: UserService,
         private solicitudService: SolicitudService,
  ) { }

  ngOnInit(): void {
   if(!this.folio?.length){
    this.folio = localStorage.getItem('folio_solicitud');
   }
    console.log(this.folio)
    this.getDynamoDB();
  }

  async getDynamoDB(){
    if(this.folio?.length>0){
      try {
        const res = await this.solicitudService.ObtenerSolicitud(this.folio);
        console.log(res)
        this.nombre_cliente= res.contacto?.nombreCompleto + " " + res.contacto?.apellidoPaterno
      } catch (error) {
        console.error("Error al obtener de DynamoDB", error);
      }
    }else{
      return
    }
		
	}

  enviarDatos(){
    this.router.navigate(['/'])
    /* const resChageStatus =  this.userService.CambiarStatusSolicitud(this.folio, 1, 1)
      console.log(resChageStatus) */
       /*  if(resChageStatus){
         if(this.flujo_cotizador){
           this.router.navigate(['/'])
         }else{
           window.location.href = "https://leaseforu.com"
         }
        } */
 
 
   }
}
