<form #direccionForm="ngForm">
    <div class="content">
      <div class="title">Indicar dirección</div>
      <div class="subtitle">Donde se encontrará el activo</div>
  
      <div class="input">
        <label for="calle">Calle *</label>
        <input type="text" [(ngModel)]="direccion.calle" name="calle"  #calle="ngModel">
        <div class="error" *ngIf="calle.invalid && calle.touched">Campo requerido</div>
      </div>
  
      <div class="col-2">
        <div class="input">
          <label for="numExt">Número exterior *</label>
          <input type="text" [(ngModel)]="direccion.numExt" name="numExt"  #numExt="ngModel">
          <div class="error" *ngIf="numExt.invalid && numExt.touched">Campo requerido</div>
        </div>
        <div class="input">
          <label for="numInt">Número interior</label>
          <input type="text" [(ngModel)]="direccion.numInt" name="numInt">
        </div>
      </div>
  
      <div class="col-2">
        <div class="input">
          <label for="cp">Código postal *</label>
          <input type="text" (input)="BuscarCP(direccion.cp)" maxlength="5" [(ngModel)]="direccion.cp" name="cp"  #cp="ngModel">
          <div class="error" *ngIf="cp.invalid && cp.touched">Campo requerido</div>
        </div>
        <div class="input">
          <label for="estado">Estado *</label>
          <input type="text" [(ngModel)]="direccion.estado" name="estado"  #estado="ngModel">
          <div class="error" *ngIf="estado.invalid && estado.touched">Campo requerido</div>
        </div>
      </div>
  
      <div class="col-2">
        <div class="input">
          <label for="municipio">Municipio *</label>
          <input type="text" [(ngModel)]="direccion.municipio" name="municipio"  #municipio="ngModel">
          <div class="error" *ngIf="municipio.invalid && municipio.touched">Campo requerido</div>
        </div>
        <div class="input">
          <label for="ciudad">Ciudad *</label>
          <input type="text" [(ngModel)]="direccion.ciudad" name="ciudad"  #ciudad="ngModel">
          <div class="error" *ngIf="ciudad.invalid && ciudad.touched">Campo requerido</div>
        </div>
      </div>
  
      <div class="col-2">
        <div class="input">
          <label for="colonia">Colonia *</label>
          <mat-select [(ngModel)]="direccion.colonia" name="colonia"  #colonia="ngModel">
            <mat-option [value]="''">Selecciona una colonia</mat-option>
            <mat-option *ngFor="let colonia of colonias" [value]="colonia">{{ colonia }}</mat-option>
          </mat-select>
          <div class="error" *ngIf="colonia.invalid && colonia.touched">Campo requerido</div>
        </div>
      </div>
      <div>
        <p *ngIf="!formvalid" class="error">Por favor ingresa todos los datos</p>
      </div>
      <div class="btn-actions">
      <button class="btn-primary-new" (click)="GuardarDireccionActivo()" 
      [disabled]="!direccion.calle || !direccion.numExt || !direccion.cp || 
      !direccion.estado || !direccion.municipio || !direccion.ciudad || 
      !direccion.colonia">
        Guardar
      </button>
      </div>

    </div>
  </form>
  