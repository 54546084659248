<div class="container-principal">
    <!--   <div class="close-btn" (click)="salir()">
        <p>X</p>
    </div> -->
    <div class="view-campos">
        <div class="informacion">
            <label class="section-title">Datos del solicitante</label>
            <div class="container-panel">
                <div class="panel">
                    <div class="panel-header"> <!-- (click)="expandedInfoIdOficial = !expandedInfoIdOficial"  -->
                        <div class="panel-title"> <!-- [ngClass]="{ complete: expandedInfoIdOficial }"> -->
                            <!-- <div style="display: flex">
                            <img style="width: 15px; margin-right: 10px"
                                src="https://leaseforu-assets.s3.us-east-2.amazonaws.com/assets/images/step-enproceso-p.svg" />
                        </div> -->
                            <div>Identificación Oficial</div>
                        </div>
                        <div class="panel-icon" (click)="toggleExpand('ido')" style="cursor: pointer;">
                            <span></span>
                            <img *ngIf="expandedInfoIdOficial" style="width: 15px; margin-left: 10px;"
                                src="https://leaseforu-assets.s3.us-east-2.amazonaws.com/assets/images/flecha_arriba.svg" />
                            <img *ngIf="!expandedInfoIdOficial" style="width: 15px;"
                                src="https://leaseforu-assets.s3.us-east-2.amazonaws.com/assets/images/flecha_abajo.svg" />
                        </div>
                    </div>
                    <div [@expandCollapse]="expandedInfoIdOficial ? 'expanded' : 'collapsed'" class="panel-content">
                        <form [formGroup]="info_IDO_FG">
                            <div>
                                <div class="contenido">
                                    <mat-form-field appearance="outline">
                                        <mat-label>Nombre (s)</mat-label>
                                        <input matInput formControlName="nombres">
                                    </mat-form-field>
                                </div>
                                <div class="contenido-div">
                                    <mat-form-field appearance="outline">
                                        <mat-label>Apellido Paterno</mat-label>
                                        <input matInput formControlName="ap_paterno" >
                                    </mat-form-field>
                                    <mat-form-field appearance="outline">
                                        <mat-label>Apellido Materno</mat-label>
                                        <input matInput formControlName="ap_materno">
                                    </mat-form-field>
                                </div>
                                <div class="contenido-div">
                                    <mat-form-field appearance="outline">
                                        <mat-label>Fecha de nacimiento</mat-label>
                                        <input matInput type="date" formControlName="fecha_nac">
                                    </mat-form-field>
                                    <mat-form-field appearance="outline">
                                        <mat-label>Nacionalidad</mat-label>
                                        <mat-select formControlName="nacionalidad">
                                            <mat-option [value]="'Mexicana'">Mexicana</mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                                <div class="contenido-div">
                                    <mat-form-field appearance="outline">
                                        <mat-label>Tipo de identificación</mat-label>
                                        <mat-select formControlName="tipo_identificacion">
                                            <mat-option default value="Credencial para votar">Credencial para
                                                votar</mat-option>
                                            <mat-option value="Pasaporte">Pasaporte</mat-option>
                                            <mat-option value="FM2">FM2</mat-option>
                                            <mat-option value="FM3">FM3</mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                    <mat-form-field appearance="outline">
                                        <mat-label>Número de identificación</mat-label>
                                        <input matInput formControlName="numero_identificacion">
                                    </mat-form-field>
                                </div>
                                <div class="contenido-div">
                                    <div>
                                        <mat-form-field appearance="outline">
                                            <mat-label>CURP</mat-label>
                                            <input matInput formControlName="curp" maxlength="18" >
                                        </mat-form-field>
                                    </div>
                                    <div class="div-genero">
                                        <mat-label>Género</mat-label>

                                        <div class="contenido" class="genero-radio">
                                            <mat-radio-group formControlName="genero">
                                                <mat-radio-button labelPosition="before"
                                                    value="M">Masculino</mat-radio-button>
                                                <mat-radio-button labelPosition="before"
                                                    value="F">Femenino</mat-radio-button>
                                            </mat-radio-group>
                                        </div>
                                    </div>
                                </div>
                                <div class="contenido">
                                    <div class="contendor-botones">
                                        <div class="guardar-btn-div">
                                            <button class="guardar-btn" (click)="guardarIdOficial()">Guardar</button>
                                        </div>
                                        <div class="guardar-btn-div">
                                            <button class="salir-btn" (click)="toggleExpand('ido')">Cancelar</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <div class="container-panel">
                <div class="panel">
                    <div class="panel-header">
                        <!-- (click)="expandedConstanciaSituacionFiscal = !expandedConstanciaSituacionFiscal" -->
                        <div class="panel-title" [ngClass]="{ complete: expandedConstanciaSituacionFiscal }">
                            <!-- <div style="display: flex">
                            <img style="width: 15px; margin-right: 10px"
                                src="https://leaseforu-assets.s3.us-east-2.amazonaws.com/assets/images/step-enproceso-p.svg" />
                        </div> -->
                            <div>Constancia de Situación Fiscal</div>
                        </div>
                        <div class="panel-icon" (click)="toggleExpand('csf')" style="cursor: pointer;">
                            <span></span>
                            <img *ngIf="expandedConstanciaSituacionFiscal" style="width: 15px; margin-left: 10px;"
                                src="https://leaseforu-assets.s3.us-east-2.amazonaws.com/assets/images/flecha_arriba.svg" />
                            <img *ngIf="!expandedConstanciaSituacionFiscal" style="width: 15px;"
                                src="https://leaseforu-assets.s3.us-east-2.amazonaws.com/assets/images/flecha_abajo.svg" />
                        </div>
                    </div>
                    <div [@expandCollapse]="expandedConstanciaSituacionFiscal ? 'expanded' : 'collapsed'"
                        class="panel-content">
                        <form [formGroup]="info_CSF_FG">
                            <div>
                                <div class="contenido-div">
                                    <mat-form-field appearance="outline">
                                        <mat-label>R.F.C.</mat-label>
                                        <input matInput formControlName="rfc" mask="SSSS000000 AAA">
                                    </mat-form-field>
                                    <mat-form-field appearance="outline" *ngIf="arcusService.sectoresEconomicos.length">
                                        <mat-label>Sector económico</mat-label>
                                        <mat-select formControlName="sector" (selectionChange)="ChangeActividad()">
                                            <mat-option value="">Seleciona el sector económico</mat-option>
                                            <mat-option *ngFor="let ocupacion of arcusService.sectoresEconomicos"
                                                [value]="ocupacion">{{ocupacion}}</mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                                <div class="contenido-div">
                                    <div class="list" *ngIf="array_regimen.length">
                                        <ul *ngFor="let regimen of array_regimen">
                                            <li>{{regimen}}</li>
                                        </ul>
                                    </div>
                                    <mat-form-field appearance="outline">
                                        <mat-label>Regimen contribución</mat-label>
                                        <mat-select formControlName="regimen_contribucion">
                                            <!-- <mat-option value="">Seleciona un regimen</mat-option> -->
                                            <mat-option *ngFor="let regimen of regimenes"
                                                [value]="regimen.id">{{regimen.nombre}}</mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                                <div class="contenido-div">
                                    <div class="list" *ngIf="array_actividad_economica.length">
                                        <ul *ngFor="let actividad of array_actividad_economica">
                                            <li>{{actividad}}</li>
                                        </ul>
                                    </div>
                                    <mat-form-field appearance="outline" *ngIf="actividades.length">
                                        <mat-label>Actividad principal de la empresa</mat-label>
                                        <mat-select formControlName="actividad_principal">
                                            <!-- <mat-option value="">Seleciona una actividad principal</mat-option> -->
                                            <mat-option *ngFor="let actividad of actividades"
                                                [value]="actividad.actividadPrincipal">{{actividad.actividadPrincipal}}</mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                                <div class="contenido-div">
                                    <mat-form-field appearance="outline">
                                        <mat-label>Correo electrónico para facturas</mat-label>
                                        <input matInput placeholder="@" type="email" formControlName="email">
                                    </mat-form-field>
                                    <mat-form-field appearance="outline">
                                        <mat-label>Teléfono Móvil</mat-label>
                                        <input matInput mask="00 0000 0000" formControlName="tel_cel">
                                    </mat-form-field>
                                </div>
                                <div class="contenido panel-title">
                                    <div>Domicilio Fiscal</div>
                                </div>
                                <div class="contenido-div">
                                    <mat-form-field appearance="outline">
                                        <mat-label>Calle</mat-label>
                                        <input matInput formControlName="calle">
                                    </mat-form-field>
                                    <mat-form-field appearance="outline" class="campo">
                                        <mat-label>C.P.</mat-label>
                                        <input matInput type="text" formControlName="codigoPostal"
                                            (input)="BuscarDireccion('csf',csf.codigoPostal.value)">
                                    </mat-form-field>
                                </div>
                                <div class="contenido-div">
                                    <mat-form-field appearance="outline">
                                        <mat-label>Numero Exterior</mat-label>
                                        <input matInput formControlName="numExterior">
                                    </mat-form-field>
                                    <mat-form-field appearance="outline">
                                        <mat-label>Numero Interior</mat-label>
                                        <input matInput formControlName="numInterior">
                                    </mat-form-field>
                                </div>
                                <mat-label *ngIf="coloniasDisponibles_csf.length">Colonia</mat-label>
                                <div class="contenido-div">
                                    <ng-container>
                                        <app-select-colonias *ngIf="coloniasDisponibles_csf.length" [colonia]=""
                                            [colonias]="coloniasDisponibles_csf" (onSelected)="setColonia($event)"
                                            formControlName="colonia" style="width: 50%;"></app-select-colonias>
                                        <mat-form-field *ngIf="!coloniasDisponibles_csf.length" appearance="outline"
                                            style="width: 50%;">
                                            <mat-label>Colonia</mat-label>
                                            <input matInput formControlName="colonia">
                                        </mat-form-field>
                                    </ng-container>
                                    <mat-form-field appearance="outline" style="width: 50%;">
                                        <mat-label>Municipio</mat-label>
                                        <input matInput formControlName="municipio">
                                    </mat-form-field>
                                </div>
                                <div class="contenido-div">
                                    <mat-form-field appearance="outline">
                                        <mat-label>Ciudad</mat-label>
                                        <input matInput formControlName="ciudad">
                                    </mat-form-field>
                                    <mat-form-field appearance="outline">
                                        <mat-label>Estado</mat-label>
                                        <input matInput formControlName="estado">
                                    </mat-form-field>
                                </div>
                                <div class="contenido" style="justify-content: flex-end;">
                                    <div class="contendor-botones">
                                        <div class="guardar-btn-div">
                                            <button class="guardar-btn"
                                                (click)="guardarCosntaciFiscal()">Guardar</button>
                                        </div>
                                        <div class="guardar-btn-div">
                                            <button class="salir-btn" (click)="toggleExpand('csf')">Cancelar</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <div class="container-panel">
                <div class="panel">
                    <div class="panel-header">
                        <!-- (click)="expandedComprobanteDomicilio = !expandedComprobanteDomicilio" -->
                        <div class="panel-title" [ngClass]="{ complete: expandedComprobanteDomicilio }">
                            <!-- <div style="display: flex">
                            <img style="width: 15px; margin-right: 10px"
                                src="https://leaseforu-assets.s3.us-east-2.amazonaws.com/assets/images/step-enproceso-p.svg" />
                        </div> -->
                            <div>Comprobante de domicilio</div>
                        </div>
                        <div class="panel-icon" (click)="toggleExpand('cd')" style="cursor: pointer;">
                            <span></span>
                            <img *ngIf="expandedComprobanteDomicilio" style="width: 15px; margin-left: 10px;"
                                src="https://leaseforu-assets.s3.us-east-2.amazonaws.com/assets/images/flecha_arriba.svg" />
                            <img *ngIf="!expandedComprobanteDomicilio" style="width: 15px;"
                                src="https://leaseforu-assets.s3.us-east-2.amazonaws.com/assets/images/flecha_abajo.svg" />
                        </div>
                    </div>
                    <div [@expandCollapse]="expandedComprobanteDomicilio ? 'expanded' : 'collapsed'"
                        class="panel-content">
                        <form [formGroup]="info_CD_FG">
                            <div>
                                <div class="contenido-div">
                                    <mat-form-field appearance="outline">
                                        <mat-label>Calle</mat-label>
                                        <input matInput formControlName="calle">
                                    </mat-form-field>
                                    <mat-form-field appearance="outline" class="campo">
                                        <mat-label>C.P.</mat-label>
                                        <input matInput type="text" formControlName="codigoPostal"
                                            (input)="BuscarDireccion('cd',cd.codigoPostal.value)">
                                    </mat-form-field>
                                </div>
                                <div class="contenido-div">
                                    <mat-form-field appearance="outline">
                                        <mat-label>Numero Exterior</mat-label>
                                        <input matInput formControlName="numExterior">
                                    </mat-form-field>
                                    <mat-form-field appearance="outline">
                                        <mat-label>Numero Interior</mat-label>
                                        <input matInput formControlName="numInterior">
                                    </mat-form-field>
                                </div>
                                <mat-label *ngIf="coloniasDisponibles_cd.length">Colonia</mat-label>
                                <div class="contenido-div">
                                    <ng-container>
                                        <app-select-colonias *ngIf="coloniasDisponibles_cd.length" [colonia]=""
                                            [colonias]="coloniasDisponibles_cd" (onSelected)="setColonia($event)"
                                            formControlName="colonia" style="width: 50%;"></app-select-colonias>
                                        <mat-form-field *ngIf="!coloniasDisponibles_cd.length" appearance="outline"
                                            style="width: 50%;">
                                            <mat-label>Colonia</mat-label>
                                            <input matInput formControlName="colonia">
                                        </mat-form-field>
                                    </ng-container>
                                    <mat-form-field appearance="outline" style="width: 50%;">
                                        <mat-label>Municipio</mat-label>
                                        <input matInput formControlName="municipio">
                                    </mat-form-field>
                                </div>
                                <div class="contenido-div">
                                    <mat-form-field appearance="outline">
                                        <mat-label>Ciudad</mat-label>
                                        <input matInput formControlName="ciudad">
                                    </mat-form-field>
                                    <mat-form-field appearance="outline">
                                        <mat-label>Estado</mat-label>
                                        <input matInput formControlName="estado">
                                    </mat-form-field>
                                </div>
                                <div class="contenido-div">
                                    <div class="div-genero">
                                        <mat-label>Tipo de Vivienda</mat-label>
                                        <div class="contenido" class="genero-radio">
                                            <mat-radio-group formControlName="tipo_vivienda">
                                                <mat-radio-button labelPosition="before"
                                                    value="PROPIA">Propia</mat-radio-button>
                                                <mat-radio-button labelPosition="before"
                                                    value="RENTADA">Rentada</mat-radio-button>
                                            </mat-radio-group>
                                        </div>
                                    </div>
                                    <div *ngIf="info_CD_FG.get('tipo_vivienda').value === 'RENTADA'">
                                        <mat-form-field appearance="outline">
                                            <mat-label>Pago Mensual</mat-label>
                                            <input matInput mask="separator.2" thousandSeparator="," prefix="$ "
                                                formControlName="pago_mensual">
                                        </mat-form-field>
                                    </div>
                                </div>
                                <div class="contenido" style="justify-content: flex-end;">
                                    <div class="contendor-botones">
                                        <div class="guardar-btn-div">
                                            <button class="guardar-btn"
                                                (click)="guardarComprobanteDomicilio()">Guardar</button>
                                        </div>
                                        <div class="guardar-btn-div">
                                            <button class="salir-btn" (click)="toggleExpand('cd')">Cancelar</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <div class="container-panel">
                <div class="panel">
                    <div class="panel-header">
                        <!-- (click)="expandedComprobanteDomicilioActivo = !expandedComprobanteDomicilioActivo" -->
                        <div class="panel-title" [ngClass]="{ complete: expandedComprobanteDomicilioActivo }">
                            <!-- <div style="display: flex">
                            <img style="width: 15px; margin-right: 10px"
                                src="https://leaseforu-assets.s3.us-east-2.amazonaws.com/assets/images/step-enproceso-p.svg" />
                        </div> -->
                            <div>Comprobante de domicilio del Activo</div>
                        </div>
                        <div class="panel-icon" (click)="toggleExpand('cda')" style="cursor: pointer;">
                            <span></span>
                            <img *ngIf="expandedComprobanteDomicilioActivo" style="width: 15px; margin-left: 10px;"
                                src="https://leaseforu-assets.s3.us-east-2.amazonaws.com/assets/images/flecha_arriba.svg" />
                            <img *ngIf="!expandedComprobanteDomicilioActivo" style="width: 15px;"
                                src="https://leaseforu-assets.s3.us-east-2.amazonaws.com/assets/images/flecha_abajo.svg" />
                        </div>
                    </div>
                    <div [@expandCollapse]="expandedComprobanteDomicilioActivo ? 'expanded' : 'collapsed'"
                        class="panel-content">
                        <form [formGroup]="info_CDA_FG">
                            <div>
                                <div class="contenido">
                                    <mat-form-field appearance="outline">
                                        <mat-label>Calle</mat-label>
                                        <input matInput formControlName="calle">
                                    </mat-form-field>
                                </div>
                                <div class="contenido-div">
                                    <mat-form-field appearance="outline">
                                        <mat-label>Numero Exterior</mat-label>
                                        <input matInput formControlName="numExterior">
                                    </mat-form-field>
                                    <mat-form-field appearance="outline">
                                        <mat-label>Numero Interior</mat-label>
                                        <input matInput formControlName="numInterior">
                                    </mat-form-field>
                                    <mat-form-field appearance="outline" class="campo">
                                        <mat-label>C.P.</mat-label>
                                        <input matInput type="text" formControlName="codigoPostal"
                                            (input)="BuscarDireccion('cda',cda.codigoPostal.value)">
                                    </mat-form-field>
                                </div>
                                <mat-label *ngIf="coloniasDisponibles_cda.length">Colonia</mat-label>
                                <div class="contenido-div">
                                    <ng-container>
                                        <app-select-colonias *ngIf="coloniasDisponibles_cda.length" [colonia]=""
                                            [colonias]="coloniasDisponibles_cda" (onSelected)="setColonia($event)"
                                            formControlName="colonia" style="width: 50%;"></app-select-colonias>
                                        <mat-form-field *ngIf="!coloniasDisponibles_cda.length" appearance="outline"
                                            style="width: 50%;">
                                            <mat-label>Colonia</mat-label>
                                            <input matInput formControlName="colonia">
                                        </mat-form-field>
                                    </ng-container>
                                    <mat-form-field appearance="outline" style="width: 50%;">
                                        <mat-label>Municipio</mat-label>
                                        <input matInput formControlName="municipio">
                                    </mat-form-field>
                                </div>
                                <div class="contenido-div">
                                    <mat-form-field appearance="outline">
                                        <mat-label>Ciudad</mat-label>
                                        <input matInput formControlName="ciudad">
                                    </mat-form-field>
                                    <mat-form-field appearance="outline">
                                        <mat-label>Estado</mat-label>
                                        <input matInput formControlName="estado">
                                    </mat-form-field>
                                </div>
                                <div class="contenido" style="justify-content: flex-end;">
                                    <div class="contendor-botones">
                                        <div class="guardar-btn-div">
                                            <button class="guardar-btn"
                                                (click)="guardardDireccionActivo()">Guardar</button>
                                        </div>
                                        <div class="guardar-btn-div">
                                            <button class="salir-btn" (click)="toggleExpand('cda')">Cancelar</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <div class="container-panel">
                <div class="panel">
                    <div class="panel-header"><!--  (click)="expandedEstadoCuenta = !expandedEstadoCuenta" -->
                        <div class="panel-title" [ngClass]="{ complete: expandedEstadoCuenta }">
                            <!-- <div style="display: flex">
                            <img style="width: 15px; margin-right: 10px"
                                src="https://leaseforu-assets.s3.us-east-2.amazonaws.com/assets/images/step-enproceso-p.svg" />
                        </div> -->
                            <div>Estados de Cuenta</div>
                        </div>
                        <div class="panel-icon" (click)="toggleExpand('ec')" style="cursor: pointer;">
                            <span></span>
                            <img *ngIf="expandedEstadoCuenta" style="width: 15px; margin-left: 10px;"
                                src="https://leaseforu-assets.s3.us-east-2.amazonaws.com/assets/images/flecha_arriba.svg" />
                            <img *ngIf="!expandedEstadoCuenta" style="width: 15px;"
                                src="https://leaseforu-assets.s3.us-east-2.amazonaws.com/assets/images/flecha_abajo.svg" />
                        </div>
                    </div>
                    <div [@expandCollapse]="expandedEstadoCuenta ? 'expanded' : 'collapsed'" class="panel-content">
                        <div>
                            <form [formGroup]="info_ED_FG">
                                <div class="contenido">
                                    <mat-form-field appearance="outline">
                                        <mat-label>Ingreso</mat-label>
                                        <input matInput formControlName="ingreso">
                                    </mat-form-field>
                                </div>
                                <div class="contenido">
                                    <mat-form-field appearance="outline">
                                        <mat-label>Descripción del ingreso</mat-label>
                                        <input matInput formControlName="descripcion">
                                    </mat-form-field>
                                </div>
                                <div class="contenido">
                                    <mat-form-field appearance="outline">
                                        <mat-label>CLAVE domiciliación</mat-label>
                                        <input matInput formControlName="clave">
                                    </mat-form-field>
                                </div>
                                <div class="contenido" style="justify-content: flex-end;">
                                    <div class="contendor-botones">
                                        <div class="guardar-btn-div">
                                            <button class="guardar-btn"
                                                (click)="guardarEstadosCuents()">Guardar</button>
                                        </div>
                                        <div class="guardar-btn-div">
                                            <button class="salir-btn" (click)="toggleExpand('ec')">Cancelar</button>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            <div class="container-panel">
                <div class="panel">
                    <div class="panel-header">
                        <div class="panel-title" [ngClass]="{ complete: expandedInformacionConsultorio }">
                            <!-- <div style="display: flex">
                            <img style="width: 15px; margin-right: 10px"
                                src="https://leaseforu-assets.s3.us-east-2.amazonaws.com/assets/images/step-enproceso-p.svg" />
                        </div> -->
                            <div>Información del Consultorio</div>
                        </div>
                        <div class="panel-icon" (click)="toggleExpand('ic')" style="cursor: pointer;">
                            <span></span>
                            <img *ngIf="expandedInformacionConsultorio" style="width: 15px; margin-left: 10px;"
                                src="https://leaseforu-assets.s3.us-east-2.amazonaws.com/assets/images/flecha_arriba.svg" />
                            <img *ngIf="!expandedInformacionConsultorio" style="width: 15px;"
                                src="https://leaseforu-assets.s3.us-east-2.amazonaws.com/assets/images/flecha_abajo.svg" />
                        </div>
                    </div>
                    <div [@expandCollapse]="expandedInformacionConsultorio ? 'expanded' : 'collapsed'"
                        class="panel-content">
                        <form [formGroup]="info_IC_FG">
                            <div class="contenido-div">
                                <div class="div-genero">
                                    <mat-label>Tipo de consultorio ¿Propio o privado?</mat-label>
                                    <div class="contenido" class="genero-radio">
                                        <mat-radio-group formControlName="tipo_consultorio">
                                            <mat-radio-button labelPosition="before"
                                                value="PROPIO">Propio</mat-radio-button>
                                            <mat-radio-button labelPosition="before"
                                                value="RENTADO">Rentado</mat-radio-button>
                                        </mat-radio-group>
                                    </div>
                                </div>
                                <div>
                                    <mat-form-field appearance="outline">
                                        <mat-label>Total Gasto Mensual Consultorio</mat-label>
                                        <input matInput mask="separator.2" thousandSeparator="," prefix="$ "
                                            formControlName="total_gasto">
                                    </mat-form-field>
                                </div>
                            </div>
                            <div class="contenido-div">
                                <div>
                                    <mat-form-field appearance="outline">
                                        <mat-label>Ingreso Mensual Promedio</mat-label>
                                        <input matInput mask="separator.2" thousandSeparator="," prefix="$ "
                                            formControlName="ingreso_mensual_promedio">
                                    </mat-form-field>
                                </div>
                                <div>
                                    <mat-form-field appearance="outline">
                                        <mat-label>Equipo Solicitado de Arrendamiento</mat-label>
                                        <input matInput formControlName="equipo_solicitado">
                                    </mat-form-field>

                                </div>
                            </div>
                            <div class="contenido-div">
                                <div>
                                    <mat-form-field appearance="outline">
                                        <mat-label>Número de Dependientes Económicos</mat-label>
                                        <mat-select formControlName="numero_dependientes">
                                            <mat-option>Seleccionar</mat-option>
                                            <mat-option value="0">0</mat-option>
                                            <mat-option value="1">1</mat-option>
                                            <mat-option value="2">2</mat-option>
                                            <mat-option value="3">3</mat-option>
                                            <mat-option value="4">4</mat-option>
                                            <mat-option value="5">5</mat-option>
                                            <mat-option value="6">6</mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                                <div>
                                    <div *ngIf="info_IC_FG.get('numero_dependientes').value > 0">
                                        <mat-form-field appearance="outline"
                                            *ngFor="let i of dependientesArray; let index = index">
                                            <mat-label>Edad del dependiente {{ index + 1 }}</mat-label>
                                            <input matInput mask="00"
                                                [formControlName]="'edad_dependiente' + (index + 1)">
                                        </mat-form-field>
                                    </div>
                                </div>
                            </div>
                            <div class="contenido" style="justify-content: flex-end;">
                                <div class="contendor-botones">
                                    <div class="guardar-btn-div">
                                        <button class="guardar-btn"
                                            (click)="guardarInformacionConsultorio()">Guardar</button>
                                    </div>
                                    <div class="guardar-btn-div">
                                        <button class="salir-btn" (click)="toggleExpand('ic')">Cancelar</button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <div class="container-panel">
                <div class="panel">
                    <div class="panel-header">
                        <div class="panel-title" [ngClass]="{ complete: expandeCotizacionVendor }">
                            <!-- <div style="display: flex">
                            <img style="width: 15px; margin-right: 10px"
                                src="https://leaseforu-assets.s3.us-east-2.amazonaws.com/assets/images/step-enproceso-p.svg" />
                        </div> -->
                            <div>Cotizacón de Vandor</div>
                        </div>
                        <div class="panel-icon" (click)="toggleExpand('cv')" style="cursor: pointer;">
                            <span></span>
                            <img *ngIf="expandeCotizacionVendor" style="width: 15px; margin-left: 10px;"
                                src="https://leaseforu-assets.s3.us-east-2.amazonaws.com/assets/images/flecha_arriba.svg" />
                            <img *ngIf="!expandeCotizacionVendor" style="width: 15px;"
                                src="https://leaseforu-assets.s3.us-east-2.amazonaws.com/assets/images/flecha_abajo.svg" />
                        </div>
                    </div>
                    <div [@expandCollapse]="expandeCotizacionVendor ? 'expanded' : 'collapsed'"
                        class="panel-content">
                    </div>
                </div>
            </div>
            <div class="container-panel">
                <div class="panel">
                    <div class="panel-header">
                        <div class="panel-title" [ngClass]="{ complete: expandeBuroSolicitante }">
                            <!-- <div style="display: flex">
                            <img style="width: 15px; margin-right: 10px"
                                src="https://leaseforu-assets.s3.us-east-2.amazonaws.com/assets/images/step-enproceso-p.svg" />
                        </div> -->
                            <div>Buro de Crédito</div>
                        </div>
                        <div class="panel-icon" (click)="toggleExpand('bs')" style="cursor: pointer;">
                            <span></span>
                            <img *ngIf="expandeBuroSolicitante" style="width: 15px; margin-left: 10px;"
                                src="https://leaseforu-assets.s3.us-east-2.amazonaws.com/assets/images/flecha_arriba.svg" />
                            <img *ngIf="!expandeBuroSolicitante" style="width: 15px;"
                                src="https://leaseforu-assets.s3.us-east-2.amazonaws.com/assets/images/flecha_abajo.svg" />
                        </div>
                    </div>
                    <div [@expandCollapse]="expandeBuroSolicitante ? 'expanded' : 'collapsed'"
                        class="panel-content">
                    </div>
                </div>
            </div>
        </div>
        <div *ngIf="!omitirAval" class="informacion">
            <label class="section-title">Datos del aval</label>
            <div class="container-panel">
                <div class="panel">
                    <div class="panel-header"><!-- (click)="expandedInfoIdOficialAval = !expandedInfoIdOficialAval" -->
                        <div class="panel-title"><!-- [ngClass]="{ complete: expandedInfoIdOficialAval }" -->
                            <!-- <div style="display: flex">
                            <img style="width: 15px; margin-right: 10px"
                                src="https://leaseforu-assets.s3.us-east-2.amazonaws.com/assets/images/step-enproceso-p.svg" />
                        </div> -->
                            <div>Identificación Oficial</div>
                        </div>
                        <div class="panel-icon" (click)="toggleExpand('idoA')" style="cursor: pointer;">
                            <span></span>
                            <img *ngIf="expandedInfoIdOficialAval" style="width: 15px; margin-left: 10px;"
                                src="https://leaseforu-assets.s3.us-east-2.amazonaws.com/assets/images/flecha_arriba.svg" />
                            <img *ngIf="!expandedInfoIdOficialAval" style="width: 15px;"
                                src="https://leaseforu-assets.s3.us-east-2.amazonaws.com/assets/images/flecha_abajo.svg" />
                        </div>
                    </div>
                    <div [@expandCollapse]="expandedInfoIdOficialAval ? 'expanded' : 'collapsed'" class="panel-content">
                        <form [formGroup]="info_IDO_AVAL_FG">
                            <div>
                                <div class="contenido">
                                    <mat-form-field appearance="outline">
                                        <mat-label>Nombre (s)</mat-label>
                                        <input matInput formControlName="nombres">
                                    </mat-form-field>
                                </div>
                                <div class="contenido-div">
                                    <mat-form-field appearance="outline">
                                        <mat-label>Apellido Paterno</mat-label>
                                        <input matInput formControlName="ap_paterno">
                                    </mat-form-field>
                                    <mat-form-field appearance="outline">
                                        <mat-label>Apellido Materno</mat-label>
                                        <input matInput formControlName="ap_materno" >
                                    </mat-form-field>
                                </div>
                                <div class="contenido-div">
                                    <mat-form-field appearance="outline">
                                        <mat-label>Fecha de nacimiento</mat-label>
                                        <input matInput type="date" formControlName="fecha_nac">
                                    </mat-form-field>
                                    <mat-form-field appearance="outline">
                                        <mat-label>Nacionalidad</mat-label>
                                        <mat-select formControlName="nacionalidad">
                                            <mat-option [value]="'Mexicana'">Mexicana</mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                                <div class="contenido-div">
                                    <mat-form-field appearance="outline">
                                        <mat-label>Correo electrónico para facturas</mat-label>
                                        <input matInput placeholder="@" type="email" formControlName="email">
                                    </mat-form-field>
                                    <mat-form-field appearance="outline">
                                        <mat-label>Teléfono Móvil</mat-label>
                                        <input matInput mask="00 0000 0000" formControlName="telefono">
                                    </mat-form-field>
                                </div>

                                
                                <!-- <div class="contenido-div">
                                <mat-form-field appearance="outline">
                                    <mat-label>Tipo de identificación</mat-label>
                                    <mat-select formControlName="tipo_identificacion">
                                        <mat-option default value="Credencial para votar">Credencial para
                                            votar</mat-option>
                                        <mat-option value="Pasaporte">Pasaporte</mat-option>
                                        <mat-option value="FM2">FM2</mat-option>
                                        <mat-option value="FM3">FM3</mat-option>
                                    </mat-select>
                                </mat-form-field>
                                <mat-form-field appearance="outline">
                                    <mat-label>Número de identificación</mat-label>
                                    <input matInput formControlName="numero_identificacion">
                                </mat-form-field>
                            </div> -->
                                <div class="contenido-div">
                                    <div>
                                        <mat-form-field appearance="outline">
                                            <mat-label>R.F.C.</mat-label>
                                            <input matInput formControlName="rfc" mask="SSS000000 AAA">
                                        </mat-form-field>
                                    </div>
                                    <div>
                                        <div class="div-genero">
                                            <mat-label>Género</mat-label>
    
                                            <div class="contenido" class="genero-radio">
                                                <mat-radio-group formControlName="genero">
                                                    <mat-radio-button labelPosition="before"
                                                        value="M">Masculino</mat-radio-button>
                                                    <mat-radio-button labelPosition="before"
                                                        value="F">Femenino</mat-radio-button>
                                                </mat-radio-group>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="contenido">
                                    <div class="contendor-botones">
                                        <div class="guardar-btn-div">
                                            <button class="guardar-btn"
                                                (click)="guardarDatosAval(info_IDO_AVAL_FG)">Guardar</button>
                                        </div>
                                        <div class="guardar-btn-div">
                                            <button class="salir-btn" (click)="toggleExpand('idoA')">Cancelar</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <div class="container-panel">
                <div class="panel">
                    <div class="panel-header">
                        <!--  (click)="expandedComprobanteDomicilioAval = !expandedComprobanteDomicilioAval" -->
                        <div class="panel-title"><!-- [ngClass]="{ complete: expandedComprobanteDomicilioAval }" -->
                            <div>Comprobante de domicilio</div>
                        </div>
                        <div class="panel-icon" (click)="toggleExpand('cdA')" style="cursor: pointer;">
                            <span></span>
                            <img *ngIf="expandedComprobanteDomicilioAval" style="width: 15px; margin-left: 10px;"
                                src="https://leaseforu-assets.s3.us-east-2.amazonaws.com/assets/images/flecha_arriba.svg" />
                            <img *ngIf="!expandedComprobanteDomicilioAval" style="width: 15px;"
                                src="https://leaseforu-assets.s3.us-east-2.amazonaws.com/assets/images/flecha_abajo.svg" />
                        </div>
                    </div>
                    <div [@expandCollapse]="expandedComprobanteDomicilioAval ? 'expanded' : 'collapsed'"
                        class="panel-content">
                        <form [formGroup]="info_CD_AVAL_FG">
                            <div>
                                <div class="contenido-div">
                                    <mat-form-field appearance="outline">
                                        <mat-label>Calle</mat-label>
                                        <input matInput formControlName="calle">
                                    </mat-form-field>
                                    <mat-form-field appearance="outline" class="campo">
                                        <mat-label>C.P.</mat-label>
                                        <input matInput type="text" formControlName="codigoPostal"
                                            (input)="BuscarDireccion('cdA',cd_Aval.codigoPostal.value)">
                                    </mat-form-field>
                                </div>
                                <div class="contenido-div">
                                    <mat-form-field appearance="outline">
                                        <mat-label>Numero Exterior</mat-label>
                                        <input matInput formControlName="numExterior">
                                    </mat-form-field>
                                    <mat-form-field appearance="outline">
                                        <mat-label>Numero Interior</mat-label>
                                        <input matInput formControlName="numInterior">
                                    </mat-form-field>
                                </div>
                                <mat-label *ngIf="coloniasDisponibles_cd_aval.length">Colonia</mat-label>
                                <div class="contenido-div">
                                    <ng-container>
                                        <app-select-colonias *ngIf="coloniasDisponibles_cd_aval.length" [colonia]=""
                                            [colonias]="coloniasDisponibles_cd_aval" (onSelected)="setColonia($event)"
                                            formControlName="colonia" style="width: 50%;"></app-select-colonias>
                                        <mat-form-field *ngIf="!coloniasDisponibles_cd_aval.length" appearance="outline"
                                            style="width: 50%;">
                                            <mat-label>Colonia</mat-label>
                                            <input matInput formControlName="colonia">
                                        </mat-form-field>
                                    </ng-container>
                                    <mat-form-field appearance="outline" style="width: 50%;">
                                        <mat-label>Municipio</mat-label>
                                        <input matInput formControlName="municipio">
                                    </mat-form-field>
                                </div>
                                <div class="contenido-div">
                                    <mat-form-field appearance="outline">
                                        <mat-label>Ciudad</mat-label>
                                        <input matInput formControlName="ciudad">
                                    </mat-form-field>
                                    <mat-form-field appearance="outline">
                                        <mat-label>Estado</mat-label>
                                        <input matInput formControlName="estado">
                                    </mat-form-field>
                                </div>
                                <div class="contenido-div">
                                    <div class="div-genero">
                                        <mat-label>Tipo de Vivienda</mat-label>
                                        <div class="contenido" class="genero-radio">
                                            <mat-radio-group formControlName="avalF_domicilio">
                                                <mat-radio-button labelPosition="before"
                                                    value="PROPIA">Propia</mat-radio-button>
                                                <mat-radio-button labelPosition="before"
                                                    value="RENTADA">Rentada</mat-radio-button>
                                            </mat-radio-group>
                                        </div>
                                    </div>
                                </div>
                                <div class="contenido" style="justify-content: flex-end;">
                                    <div class="contendor-botones">
                                        <div class="guardar-btn-div">
                                            <button class="guardar-btn"
                                                (click)="guardarDatosAval(info_CD_AVAL_FG)">Guardar</button>
                                        </div>
                                        <div class="guardar-btn-div">
                                            <button class="salir-btn" (click)="toggleExpand('cdA')">Cancelar</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <div class="container-panel">
                <div class="panel">
                    <div class="panel-header">
                        <div class="panel-title" [ngClass]="{ complete: expandeBuroAval }">
                            <!-- <div style="display: flex">
                            <img style="width: 15px; margin-right: 10px"
                                src="https://leaseforu-assets.s3.us-east-2.amazonaws.com/assets/images/step-enproceso-p.svg" />
                        </div> -->
                            <div>Buro de Crédito</div>
                        </div>
                        <div class="panel-icon" (click)="toggleExpand('bA')" style="cursor: pointer;">
                            <span></span>
                            <img *ngIf="expandeBuroAval" style="width: 15px; margin-left: 10px;"
                                src="https://leaseforu-assets.s3.us-east-2.amazonaws.com/assets/images/flecha_arriba.svg" />
                            <img *ngIf="!expandeBuroAval" style="width: 15px;"
                                src="https://leaseforu-assets.s3.us-east-2.amazonaws.com/assets/images/flecha_abajo.svg" />
                        </div>
                    </div>
                    <div [@expandCollapse]="expandeBuroAval ? 'expanded' : 'collapsed'"
                        class="panel-content">
                    </div>
                </div>
            </div>
            <!-- <div class="container-panel">
                <div class="panel">
                    <div class="panel-header">
                        <div class="panel-title">
                            <div>Constancia de Situación Fiscal</div>
                        </div>
                        <div class="panel-icon" (click)="toggleExpand('csfA')" style="cursor: pointer;">
                            <span></span>
                            <img *ngIf="expandedConstanciaSituacionFiscalAval" style="width: 15px; margin-left: 10px;"
                                src="https://leaseforu-assets.s3.us-east-2.amazonaws.com/assets/images/flecha_arriba.svg" />
                            <img *ngIf="!expandedConstanciaSituacionFiscalAval" style="width: 15px;"
                                src="https://leaseforu-assets.s3.us-east-2.amazonaws.com/assets/images/flecha_abajo.svg" />
                        </div>
                    </div>
                    <div [@expandCollapse]="expandedConstanciaSituacionFiscalAval ? 'expanded' : 'collapsed'"
                        class="panel-content">
                        <form [formGroup]="info_CSF_AVAL_FG">
                            <div>
                                <div class="contenido-div">
                                    <mat-form-field appearance="outline">
                                        <mat-label>R.F.C.</mat-label>
                                        <input matInput formControlName="rfc" mask="SSS000000 AAA">
                                    </mat-form-field>
                                    <mat-form-field appearance="outline">
                                        <mat-label>Regimen contribución</mat-label>
                                        <mat-select formControlName="regimen_contribucion">
                                            <mat-option *ngFor="let regimen of regimenes"
                                                [value]="regimen.id">{{regimen.nombre}}</mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                                <div class="contenido-div">
                                    <mat-form-field appearance="outline" *ngIf="arcusService.sectoresEconomicos.length">
                                        <mat-label>Sector económico</mat-label>
                                        <mat-select formControlName="sector" (selectionChange)="ChangeActividad()">
                                            <mat-option value="">Seleciona el sector económico</mat-option>
                                            <mat-option *ngFor="let ocupacion of arcusService.sectoresEconomicos"
                                                [value]="ocupacion">{{ocupacion}}</mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                    <mat-form-field appearance="outline" *ngIf="actividades.length">
                                        <mat-label>Actividad principal de la empresa</mat-label>
                                        <mat-select formControlName="actividad_principal">
                                             <mat-option *ngFor="let actividad of actividades"
                                                [value]="actividad.actividadPrincipal">{{actividad.actividadPrincipal}}</mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                                <div class="contenido-div">
                                    <mat-form-field appearance="outline">
                                        <mat-label>Correo electrónico para facturas</mat-label>
                                        <input matInput placeholder="@" type="email" formControlName="email">
                                    </mat-form-field>
                                    <mat-form-field appearance="outline">
                                        <mat-label>Teléfono Móvil</mat-label>
                                        <input matInput mask="00 0000 0000" formControlName="telefono">
                                    </mat-form-field>
                                </div>
                                <div class="contenido-div">
                                    <mat-form-field appearance="outline">
                                        <mat-label>Calle</mat-label>
                                        <input matInput formControlName="calle">
                                    </mat-form-field>
                                    <mat-form-field appearance="outline" class="campo">
                                        <mat-label>C.P.</mat-label>
                                        <input matInput type="text" formControlName="codigoPostal"
                                            (input)="BuscarDireccion('csfA',csf_Aval.codigoPostal.value)">
                                    </mat-form-field>
                                </div>
                                <div class="contenido-div">
                                    <mat-form-field appearance="outline">
                                        <mat-label>Numero Exterior</mat-label>
                                        <input matInput formControlName="numExterior">
                                    </mat-form-field>
                                    <mat-form-field appearance="outline">
                                        <mat-label>Numero Interior</mat-label>
                                        <input matInput formControlName="numInterior">
                                    </mat-form-field>
                                </div>
                                <mat-label *ngIf="coloniasDisponibles_csf_aval.length">Colonia</mat-label>
                                <div class="contenido-div">
                                    <ng-container>
                                        <app-select-colonias *ngIf="coloniasDisponibles_csf_aval.length" [colonia]=""
                                            [colonias]="coloniasDisponibles_csf_aval" (onSelected)="setColonia($event)"
                                            formControlName="colonia" style="width: 50%;"></app-select-colonias>
                                        <mat-form-field *ngIf="!coloniasDisponibles_csf_aval.length"
                                            appearance="outline" style="width: 50%;">
                                            <mat-label>Colonia</mat-label>
                                            <input matInput formControlName="colonia">
                                        </mat-form-field>
                                    </ng-container>
                                    <mat-form-field appearance="outline" style="width: 50%;">
                                        <mat-label>Municipio</mat-label>
                                        <input matInput formControlName="municipio">
                                    </mat-form-field>
                                </div>
                                <div class="contenido-div">
                                    <mat-form-field appearance="outline">
                                        <mat-label>Ciudad</mat-label>
                                        <input matInput formControlName="ciudad">
                                    </mat-form-field>
                                    <mat-form-field appearance="outline">
                                        <mat-label>Estado</mat-label>
                                        <input matInput formControlName="estado">
                                    </mat-form-field>
                                </div>
                                <div class="contenido" style="justify-content: flex-end;">
                                    <div class="contendor-botones">
                                        <div class="guardar-btn-div">
                                            <button class="guardar-btn"
                                                (click)="guardarDatosAval(info_CSF_AVAL_FG)">Guardar</button>
                                        </div>
                                        <div class="guardar-btn-div">
                                            <button class="salir-btn" (click)="toggleExpand('csfA')">Cancelar</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div> -->
        </div>
        <div class="informacion">
            <label class="section-title">Referencias personales</label>
            <div class="container-panel">
                <div class="panel">
                    <div class="panel-header">
                        <!-- (click)="expandedReferenciasPersonales = !expandedReferenciasPersonales" -->
                        <div class="panel-title"><!--  [ngClass]="{ complete: expandedReferenciasPersonales }" -->
                            <!-- <div style="display: flex">
                            <img style="width: 15px; margin-right: 10px"
                                src="https://leaseforu-assets.s3.us-east-2.amazonaws.com/assets/images/step-enproceso-p.svg" />
                        </div> -->
                            <div>Referencia personal</div>
                        </div>
                        <div class="panel-icon" (click)="toggleExpand('refp')" style="cursor: pointer;">
                            <span></span>
                            <img *ngIf="expandedReferenciasPersonales" style="width: 15px; margin-left: 10px;"
                                src="https://leaseforu-assets.s3.us-east-2.amazonaws.com/assets/images/flecha_arriba.svg" />
                            <img *ngIf="!expandedReferenciasPersonales" style="width: 15px;"
                                src="https://leaseforu-assets.s3.us-east-2.amazonaws.com/assets/images/flecha_abajo.svg" />
                        </div>
                    </div>
                    <div [@expandCollapse]="expandedReferenciasPersonales ? 'expanded' : 'collapsed'"
                        class="panel-content">
                        <form [formGroup]="info_REF_PER">
                            <div>
                                <div class="contenido-div">
                                    <mat-form-field appearance="outline">
                                        <mat-label>Nombre (s)</mat-label>
                                        <input matInput formControlName="rp_nombre1">
                                    </mat-form-field>
                                    <mat-form-field appearance="outline">
                                        <mat-label>Ocupación</mat-label>
                                        <input matInput formControlName="ocupacion1">
                                    </mat-form-field>
                                </div>
                                <div class="contenido-div">
                                    <mat-form-field appearance="outline">
                                        <mat-label>Teléfono</mat-label>
                                        <input matInput mask="00 0000 0000" formControlName="rp_telefono1">
                                    </mat-form-field>
                                    <mat-form-field appearance="outline">
                                        <mat-label>Parentesco/Relación</mat-label>
                                        <input matInput formControlName="rp_parentesco1">
                                    </mat-form-field>
                                </div>
                                <div class="contenido">
                                    <mat-form-field appearance="outline">
                                        <mat-label>Domicilio</mat-label>
                                        <textarea matInput formControlName="rp_direccion1"></textarea>
                                    </mat-form-field>
                                </div>
                                <div class="contenido" style="justify-content: flex-end;">
                                    <div class="contendor-botones">
                                        <div class="guardar-btn-div">
                                            <button class="guardar-btn"
                                                (click)="guardarReferenciasPersonales()">Guardar</button>
                                        </div>
                                        <div class="guardar-btn-div">
                                            <button class="salir-btn" (click)="toggleExpand('refp')">Cancelar</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>

                </div>
            </div>
        </div>

    </div>
    <ng-container style="display: flex; flex-direction: column;">
        <div class="view-pdf">
            <div class="mostrar-pdf">
                <!--  <label class="section-title-pdf">PDF</label> -->
                <div class="zoom-controls" *ngIf="urlPrecente">
                    <button (click)="zoomIn()">+</button>
                    <button (click)="zoomOut()">-</button>
                    <span>{{ zoomLevel * 100 | number: '1.0-0' }}%</span>
                </div>
                <div class="pdf-container">
                    <ng-container *ngIf="urlPrecente else noPDF">
                        <pdf-viewer [src]="url" [render-text]="true" [original-size]="false" [fit-to-page]="true"
                            [zoom]="zoomLevel">
                        </pdf-viewer>
                    </ng-container>
                    <div style="height: 400px;">
                        <ng-template class="noPDF" #noPDF>
                            <p class="textoNoPDF">No hay un documento disponible para mostrar</p>
                        </ng-template>
                    </div>
                </div>
                <div class="button-controls">
                    <button mat-button (click)="regresarDocumento()">
                        Regresar
                    </button>
                    <button mat-button (click)="siguientelDocumento()">
                        Siguiente
                    </button>
                </div>
            </div>
            <div class="acciones-documento">
                <div (click)="descargarDocumento()">
                    <img src="https://leaseforu-assets.s3.us-east-2.amazonaws.com/assets/images/file-download-azul.svg">
                </div>
                <div **ngIf="false"><img src="https://leaseforu-assets.s3.us-east-2.amazonaws.com/assets/images/file-zip-azul.svg">
                </div>
                <div class="iconos-validacion" *ngIf="urlPrecente && perfil==15">
                    <div (click)="validacionDocumento()"><img
                            src="https://leaseforu-assets.s3.us-east-2.amazonaws.com/assets/images/file-check-verde.svg">
                    </div>
                    <div (click)="cancelacionDocumento()"><img
                            src="https://leaseforu-assets.s3.us-east-2.amazonaws.com/assets/images/file-x-rojo.svg">
                    </div>
                </div>
                <!--  <div (click)="descargarZip()">
                    <svg style="height: 20px; width:auto;" xmlns="http://www.w3.org/2000/svg" width="21.262" height="26.466" viewBox="0 0 21.262 26.466"><defs><style>.upload{fill:#3ca2d6;}</style></defs><path class="upload" d="M17.631,32.422l-.849-.812L8.513,23.342l1.7-1.7,6.238,6.238V8.318h2.362V27.882l6.238-6.238,1.7,1.7L18.48,31.61ZM7,5.956H7Z" transform="translate(-7 -5.956)"/></svg>
                </div> -->
                <div class="contenedor-botones-ec" *ngIf="expandedEstadoCuenta">
                    <button class="botones-ec" (click)="asignacionDocumentos(4)">1</button>
                    <button class="botones-ec" (click)="asignacionDocumentos(17)">2</button>
                    <button class="botones-ec" (click)="asignacionDocumentos(18)">3</button>
                </div>
            </div>
            <div class="seccion-validacion" *ngIf="urlPrecente && perfil==15">
                <label class="section-title-validacion">Validación</label>
                <div class="seccion-botones">
                    <button mat-button class="btn-documento"
                        [class.btn-azul]="statusDucumento === 0 || statusDucumento === 1"
                        [class.btn-secundario]="statusDucumento === 2 " [class.btn-verde]="statusDucumento=== 3"
                        (click)="validacionDocumento()">
                        <span>{{(statusDucumento==1 || statusDucumento==2)?'Validar':'Validado'}}</span>
                    </button>
                    <button mat-button class="btn-documento"
                        [class.btn-secundario]="statusDucumento === 0 || statusDucumento === 1 || statusDucumento=== 3"
                        [class.btn-rojo]="statusDucumento === 2" (click)="cancelacionDocumento()">
                        <span>{{(statusDucumento==1 || statusDucumento==3)?'Cancelar':'Cancelado'}}</span>
                    </button>
                </div>
                <div class="seccion-cancelacion">
                    <form class="campo-comentario" [formGroup]="validacion_FG">
                        <mat-form-field appearance="outline">
                            <textarea matInput formControlName="motivo_cancelacion"
                                placeholder="Escribe tu comentario de cancelación aquí"></textarea>
                        </mat-form-field>
                    </form>
                </div>
            </div>
            <div class="guar-formularios" *ngIf="perfil==15">
                <div>
                    <button class="guardar-btn" (click)="CompletarExpediente()">Guardar</button>
                </div>
                <div>
                    <button class="salir-btn" (click)="salir()">Cancelar</button>
                </div>
            </div>
        </div>
    </ng-container>
</div>