import { AfterViewInit, ChangeDetectorRef, Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { expandedSection } from "src/app/_animations/animations";
import { MessageBoxComponent } from 'src/app/_dialogs/message-box/message-box.component';
import { BuroService, SolicitudService, UserService } from 'src/app/_services';
import { ArcusService } from 'src/app/_services/arcus.service';

import * as JSZip from 'jszip';
import { saveAs } from 'file-saver';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';


@Component({
  selector: 'app-validacion-campos-ocr',
  templateUrl: './validacion-campos-ocr.component.html',
  styleUrls: ['./validacion-campos-ocr.component.scss'],
  animations: [expandedSection],
})

export class ValidacionCamposOcrComponent implements OnInit {


  expandedInfoIdOficial = false;
  expandedConstanciaSituacionFiscal = false;
  expandedComprobanteDomicilio = false;
  expandedComprobanteDomicilioActivo = false;
  expandedEstadoCuenta = false;
  expandedInformacionConsultorio=false
  expandeBuroSolicitante=false;
  expandeCotizacionVendor=false;

  expandedInfoIdOficialAval = false;
  expandedConstanciaSituacionFiscalAval = false;
  expandedComprobanteDomicilioAval = false;
  expandeBuroAval=false;
  expandedReferenciasPersonales = false;

  arrayViewDocument = [];
  arrayViewDocumentAval = ['ido', 'csf', 'cd', 'cda', 'ec','ic' ,'cv','bs','idoA', 'cdA','bA','refp'];
  arrayViewDocumentOmitirAval = ['ido', 'csf', 'cd', 'cda', 'ec','ic','cv','bs','refp'];
  omitirAval:boolean=false;
  posicionArreglo: number = 0;
  solicitante: boolean = true;
  tipoDocumento: number = null;
  cancelDoc: boolean = false;
  completado: boolean = false;
  id_cliente = null;
  tipo_persona = 1;
  tipo_aval = 1;
  documentos_necesarios = 0;
  documentos_estados_cuenta = [];
  perfil:number=0
  faltanDocumentos: boolean = false;
  solicitud_prellenada: boolean = false;
  statusDucumento: number = 0;
  urlPrecente:boolean=false;

  documentos = {
    DocumentosSolicitante: [],
    DocumentoComprobanteDomicilio: [],
    DocumentosSolicitanteLegal: [],
    DocumentosAval: [],
    DocumentosAvalDomicilio:[],
    DocumentosAvalLegal: [],
  };



  coloniasDisponibles_csf = [];
  coloniasDisponibles_cd = [];
  coloniasDisponibles_cda = [];
  coloniasDisponibles_cd_aval = [];
  coloniasDisponibles_csf_aval = [];

  Documentos = []

  array_actividad_economica = [];
  array_regimen = [];

  formularioElegido: string = null;

  url = ''


  folio: string = null;

  colonias_csf = false;
  colonias_cd = false;
  colonias_cda = false;
  colonias_cd_aval = false;
  colonias_csf_aval = false;

  colonia = 'Selecciona una colonia'

  direccion = {
    pais: '',
    ciudad: '',
    cp: '',
    calle: '',
    numeroExterior: '',
    colonia: '',
    delegacion: '',
    municipio: '',
    estado: '',
    lat: '',
    lng:''
  };

  data = {
    contacto: {
      curp: '',
    },
    aval: {},
    direccionFiscal: {},
    direccion: {},
    direccionActivo: {},
    datosAdicionales: {},
    infoComplementaria:{},
    referencias: [],
    estadoCuenta:{},
    informacionAdicional:{},
    infoComplementariaDomicilio:{}

  };

  data_get_document: any = {};

  actividades = []
  regimenes = [
    {
      "nombre": "General de Ley Personas Morales",
      "id": "1650398981458x218692573462708760"
    },
    {
      "nombre": "Personas Morales con Fines no Lucrativos",
      "id": "1650398981458x329108365758673700"
    },
    {
      "nombre": "Personas Físicas con Actividades Empresariales y Profesionales",
      "id": "1650398981459x198069605886681100"
    },
    {
      "nombre": "Ingresos por Dividendos (socios y accionistas)",
      "id": "1650398981459x211103908695358120"
    },
    {
      "nombre": "Residentes en el Extranjero sin Establecimiento Permanente en México",
      "id": "1650398981459x437946474161068160"
    },
    {
      "nombre": "Demás ingresos",
      "id": "1650398981459x522021446067531200"
    },
    {
      "nombre": "Consolidación",
      "id": "1650398981459x743341538815614800"
    },
    {
      "nombre": "Arrendamiento",
      "id": "1650398981459x769312061157237900"
    },
    {
      "nombre": "Sueldos y Salarios e Ingresos Asimilados a Salarios",
      "id": "1650398981459x871991104860487800"
    },
    {
      "nombre": "Régimen de Enajenación o Adquisición de Bienes",
      "id": "1650398981459x986648174462116200"
    },
    {
      "nombre": "Ingresos por intereses",
      "id": "1650398981460x423486058288840450"
    },
    {
      "nombre": "Régimen de los ingresos por obtención de premios",
      "id": "1650398981460x801095824257317000"
    },
    {
      "nombre": "Sociedades Cooperativas de Producción que optan por diferir sus ingresos",
      "id": "1650398981461x178202637037240030"
    },
    {
      "nombre": "Incorporación Fiscal",
      "id": "1650398981461x194608203056072500"
    },
    {
      "nombre": "Régimen Simplificado de Confianza",
      "id": "1650398981461x283510780211937900"
    },
    {
      "nombre": "Opcional para Grupos de Sociedades",
      "id": "1650398981461x294901337918111550"
    },
    {
      "nombre": "Coordinados",
      "id": "1650398981461x367677311882572700"
    },
    {
      "nombre": "Actividades Agrícolas, Ganaderas, Silvícolas y Pesqueras",
      "id": "1650398981461x759229525028249100"
    },
    {
      "nombre": "Régimen de las Actividades Empresariales con ingresos a través de Plataformas Tecnológicas",
      "id": "1650398981461x852495051636040300"
    },
    {
      "nombre": "Sin obligaciones fiscales",
      "id": "1650398981461x943776525975512200"
    },
    {
      "nombre": "Hidrocarburos",
      "id": "1650398981462x161677904012120800"
    },
    {
      "nombre": "De los Regímenes Fiscales Preferentes y de las Empresas Multinacionales",
      "id": "1650398981462x531689845653666500"
    },
    {
      "nombre": "Enajenación de acciones en bolsa de valores",
      "id": "1650398981462x600817349128310000"
    }
  ]
  info_IDO_FG: FormGroup;
  info_CSF_FG: FormGroup;
  info_CD_FG: FormGroup;
  info_CDA_FG: FormGroup;
  info_ED_FG: FormGroup;
  info_IC_FG: FormGroup;

  validacion_FG: FormGroup;

  info_IDO_AVAL_FG: FormGroup;
  info_CSF_AVAL_FG: FormGroup;
  info_CD_AVAL_FG: FormGroup;

  info_REF_PER: FormGroup;

  zoomLevel = 0.8;

  constructor(public arcusService: ArcusService,
    private buroService: BuroService,
    private formBuilder: FormBuilder,
    private solicitudService: SolicitudService,
    private dialog: MatDialog,
    private dialogRef: MatDialogRef<ValidacionCamposOcrComponent>,
    @Inject(MAT_DIALOG_DATA) public doc,
    private userService: UserService,
    private _snackBar: MatSnackBar,
     private router: Router,
    private http: HttpClient,
     private cdr: ChangeDetectorRef,
  ) { }
  async ngOnInit() {

    console.log(this.doc)
    this.id_cliente = this.doc.idCliente;
    this.omitirAval=this.doc.omitirAval
    if(this.omitirAval){
      this.arrayViewDocument = [...this.arrayViewDocumentOmitirAval]
    }else{
      this.arrayViewDocument=[...this.arrayViewDocumentAval]
    }
   
    this.grupoFormulariosSolicitante();
    this.grupoFormulariosAval();
    this.grupoFormulariosReferencias();
    this.grupoFormulariosvalidacion();
    this.grupoEstadoCuenta();
    this.getInfoArcus()


    const currentUser =JSON.parse(localStorage.getItem('currentUser'));
    const clientDetails = JSON.parse(localStorage.getItem('client_details'));
    this.perfil=currentUser?.perfil??0;
    this.folio = clientDetails?.folio ?? null;

    if (!this.folio?.length) {
      this.folio = localStorage.getItem('folio_solicitud')
    }

    this.data = await this.obtenerData(this.folio);

    console.log(this.data)

    if (this.data?.contacto) {
      this.setDataSolicitante(this.data?.contacto);
    }
    if (this.data?.aval) {
      this.setDataAval(this.data?.aval)
    }
    if (this.data?.direccionFiscal) {
      this.setDataConstancia(this.data?.direccionFiscal, this.data?.contacto ? this.data?.contacto : null,this.data?.infoComplementaria?this.data?.infoComplementaria:null);
      //
    }
    if (this.data?.direccion) {
      this.setDataDireccion(this.data?.direccion,this.data?.infoComplementariaDomicilio);
    }
    if (this.data?.direccionActivo) {
      this.setDataDireccionActivo(this.data?.direccionActivo);
    }
    if(this.data?.estadoCuenta){
      this.setDataEstadoCuenta(this.data?.estadoCuenta);
    }
    if(this.data?.referencias){
      this.setDataReferenciasPersonales(this.data?.referencias);
    }
    if(this.data?.informacionAdicional){
      this.setDataInformacionConsultorio(this.data?.informacionAdicional);
    }
    this.toggleExpand('ido')
    //   this.actividades = this.arcusService.CargarActividadesPrincipales(this.f.sector.value)
    this.data_get_document =
      this.tipo_persona == 1
        ? {
          folio: this.folio.toString(),
          id_cliente: this.id_cliente,
          tipo_persona: this.tipo_persona,
          tipo_aval: this.tipo_aval,
          empleado: false,
          nuevo_flujo: true,
        }
        : {
          folio: this.folio,
          tipo_persona: this.tipo_persona,
          tipo_aval: this.tipo_aval,
        };
       
  }

  get ido() { return this.info_IDO_FG.controls; };
  get csf() { return this.info_CSF_FG.controls; };
  get cd() { return this.info_CD_FG.controls; };
  get cda() { return this.info_CDA_FG.controls; };
  get ed() { return this.info_ED_FG.controls; };
  get ic(){return this.info_IC_FG.controls;};

  get vc() { return this.validacion_FG; };

  get ido_Aval() { return this.info_IDO_AVAL_FG.controls; };
  get csf_Aval() { return this.info_CSF_AVAL_FG.controls; };
  get cd_Aval() { return this.info_CD_AVAL_FG.controls; };

  get ref_per() { return this.info_REF_PER.controls }

 async toggleExpand(section: string) {
  this.validacion_FG.reset();
  this.validacion_FG.updateValueAndValidity();
    //this.url=null;
    switch (section) {
      case "ido":
        this.expandedInfoIdOficial = !this.expandedInfoIdOficial;
        if (this.expandedInfoIdOficial) {
          this.expandedConstanciaSituacionFiscal = false;
          this.expandedComprobanteDomicilio = false;
          this.expandedComprobanteDomicilioActivo = false;
          this.expandedEstadoCuenta = false;
          this.expandedInfoIdOficialAval = false;
          this.expandedConstanciaSituacionFiscalAval = false;
          this.expandedComprobanteDomicilioAval = false;
          this.expandedReferenciasPersonales = false;
          this.expandeBuroSolicitante=false;
          this.expandeBuroAval=false;
          this.expandeCotizacionVendor=false;
          console.log('**1**',this.url)
          this.url = this.asignacionDocumentos(3);
          this.posicionArreglo = this.arrayViewDocument.indexOf(section);

        }
        break;
      case "csf":
        this.expandedConstanciaSituacionFiscal = !this.expandedConstanciaSituacionFiscal
        if (this.expandedConstanciaSituacionFiscal) {
          this.expandedInfoIdOficial = false;
          this.expandedComprobanteDomicilio = false;
          this.expandedComprobanteDomicilioActivo = false;
          this.expandedEstadoCuenta = false;
          this.expandedInfoIdOficialAval = false;
          this.expandedConstanciaSituacionFiscalAval = false;
          this.expandedComprobanteDomicilioAval = false;
          this.expandedReferenciasPersonales = false;
          this.expandedInformacionConsultorio=false;
          this.expandeBuroSolicitante=false;
          this.expandeBuroAval=false;
          this.expandeCotizacionVendor=false;
          console.log('**2**',this.url)
          this.url = this.asignacionDocumentos(5);
          this.posicionArreglo = this.arrayViewDocument.indexOf(section);

        }
        break;
      case "cd":
        this.expandedComprobanteDomicilio = !this.expandedComprobanteDomicilio;
        if (this.expandedComprobanteDomicilio) {
          this.expandedInfoIdOficial = false;
          this.expandedConstanciaSituacionFiscal = false;
          this.expandedComprobanteDomicilioActivo = false;
          this.expandedEstadoCuenta = false;
          this.expandedInfoIdOficialAval = false;
          this.expandedConstanciaSituacionFiscalAval = false;
          this.expandedComprobanteDomicilioAval = false;
          this.expandedReferenciasPersonales = false;
          this.expandedInformacionConsultorio=false;
          this.expandeBuroSolicitante=false;
          this.expandeBuroAval=false;
          this.expandeCotizacionVendor=false;
          console.log('**3**',this.url)
          this.url = this.asignacionDocumentos(11);
          this.posicionArreglo = this.arrayViewDocument.indexOf(section);
         

        }
        break;
      case "cda":
        this.expandedComprobanteDomicilioActivo = !this.expandedComprobanteDomicilioActivo;
        if (this.expandedComprobanteDomicilioActivo) {
          this.expandedInfoIdOficial = false;
          this.expandedConstanciaSituacionFiscal = false;
          this.expandedComprobanteDomicilio = false;
          this.expandedEstadoCuenta = false;
          this.expandedInfoIdOficialAval = false;
          this.expandedConstanciaSituacionFiscalAval = false;
          this.expandedComprobanteDomicilioAval = false;
          this.expandedReferenciasPersonales = false;
          this.expandedInformacionConsultorio=false;
          this.expandeBuroSolicitante=false;
          this.expandeBuroAval=false;
          this.expandeCotizacionVendor=false;
          this.url = this.asignacionDocumentos(12);
          this.posicionArreglo = this.arrayViewDocument.indexOf(section);
        


        }
        break;
      case "ec":
        this.expandedEstadoCuenta = !this.expandedEstadoCuenta;
        if (this.expandedEstadoCuenta) {
          this.expandedInfoIdOficial = false;
          this.expandedConstanciaSituacionFiscal = false;
          this.expandedComprobanteDomicilio = false;
          this.expandedComprobanteDomicilioActivo = false;
          this.expandedInfoIdOficialAval = false;
          this.expandedConstanciaSituacionFiscalAval = false;
          this.expandedComprobanteDomicilioAval = false;
          this.expandedReferenciasPersonales = false;
          this.expandedInformacionConsultorio=false;
          this.expandeBuroSolicitante=false;
          this.expandeBuroAval=false;
          this.expandeCotizacionVendor=false;
          this.url = this.asignacionDocumentos(4);
          this.posicionArreglo = this.arrayViewDocument.indexOf(section);
    
        }
        break;
        case "ic":
          this.expandedInformacionConsultorio = !this.expandedInformacionConsultorio;
          if (this.expandedInformacionConsultorio) {
            this.expandedConstanciaSituacionFiscal = false;
            this.expandedComprobanteDomicilio = false;
            this.expandedComprobanteDomicilioActivo = false;
            this.expandedEstadoCuenta = false;
            this.expandedInfoIdOficialAval = false;
            this.expandedConstanciaSituacionFiscalAval = false;
            this.expandedComprobanteDomicilioAval = false;
            this.expandedReferenciasPersonales = false;
            this.expandedInfoIdOficial=false;
            this.expandeBuroSolicitante=false;
            this.expandeBuroAval=false;
            this.expandeCotizacionVendor=false;
            this.posicionArreglo = this.arrayViewDocument.indexOf(section);
            this.urlPrecente=false;
  
          }
          break;
          case "cv":
            this.expandeCotizacionVendor = !this.expandeCotizacionVendor;
            if (this.expandeCotizacionVendor) {
              this.expandedConstanciaSituacionFiscal = false;
              this.expandedComprobanteDomicilio = false;
              this.expandedComprobanteDomicilioActivo = false;
              this.expandedEstadoCuenta = false;
              this.expandedInfoIdOficialAval = false;
              this.expandedConstanciaSituacionFiscalAval = false;
              this.expandedComprobanteDomicilioAval = false;
              this.expandedReferenciasPersonales = false;
              this.expandedInfoIdOficial=false;
              this.expandedInformacionConsultorio=false;
              this.expandeBuroAval=false;
              this.expandeBuroSolicitante=false;
              this.url = this.asignacionDocumentos(22);
              this.posicionArreglo = this.arrayViewDocument.indexOf(section);
    
            }
            break;
          case "bs":
            this.expandeBuroSolicitante = !this.expandeBuroSolicitante;
            if (this.expandeBuroSolicitante) {
              this.expandedConstanciaSituacionFiscal = false;
              this.expandedComprobanteDomicilio = false;
              this.expandedComprobanteDomicilioActivo = false;
              this.expandedEstadoCuenta = false;
              this.expandedInfoIdOficialAval = false;
              this.expandedConstanciaSituacionFiscalAval = false;
              this.expandedComprobanteDomicilioAval = false;
              this.expandedReferenciasPersonales = false;
              this.expandedInfoIdOficial=false;
              this.expandedInformacionConsultorio=false;
              this.expandeBuroAval=false;
              this.expandeCotizacionVendor=false;
              this.url = this.asignacionDocumentos(20);
              this.posicionArreglo = this.arrayViewDocument.indexOf(section);
    
            }
            break;
      case "idoA":
        this.expandedInfoIdOficialAval = !this.expandedInfoIdOficialAval;
        if (this.expandedInfoIdOficialAval) {
          this.expandedConstanciaSituacionFiscal = false;
          this.expandedComprobanteDomicilio = false;
          this.expandedComprobanteDomicilioActivo = false;
          this.expandedConstanciaSituacionFiscalAval = false;
          this.expandedComprobanteDomicilioAval = false;
          this.expandedInfoIdOficial = false;
          this.expandedReferenciasPersonales = false;
          this.expandedEstadoCuenta = false;
          this.expandedInformacionConsultorio=false;
          this.expandeBuroSolicitante=false;
          this.expandeBuroAval=false;
          this.expandeCotizacionVendor=false;
          this.url = this.asignacionDocumentosAval(9);
          this.posicionArreglo = this.arrayViewDocument.indexOf(section);

        }
        break;
      case "cdA":
        this.expandedComprobanteDomicilioAval = !this.expandedComprobanteDomicilioAval;
        if (this.expandedComprobanteDomicilioAval) {
          this.expandedInfoIdOficial = false;
          this.expandedConstanciaSituacionFiscal = false;
          this.expandedComprobanteDomicilioActivo = false;
          this.expandedEstadoCuenta = false;
          this.expandedComprobanteDomicilio = false;
          this.expandedInfoIdOficialAval = false;
          this.expandedConstanciaSituacionFiscalAval = false;
          this.expandedReferenciasPersonales = false;
          this.expandedInformacionConsultorio=false;
          this.expandeBuroSolicitante=false;
          this.expandeBuroAval=false;
          this.expandeCotizacionVendor=false;
          this.url = this.asignacionDocumentosAval(11);
          this.posicionArreglo = this.arrayViewDocument.indexOf(section);
  
        }
        break;
        case "bA":
          this.expandeBuroAval = !this.expandeBuroAval;
          if (this.expandeBuroAval) {
            this.expandedConstanciaSituacionFiscal = false;
            this.expandedComprobanteDomicilio = false;
            this.expandedComprobanteDomicilioActivo = false;
            this.expandedEstadoCuenta = false;
            this.expandedInfoIdOficialAval = false;
            this.expandedConstanciaSituacionFiscalAval = false;
            this.expandedComprobanteDomicilioAval = false;
            this.expandedReferenciasPersonales = false;
            this.expandedInfoIdOficial=false;
            this.expandedInformacionConsultorio=false;
            this.expandeBuroSolicitante=false;
            this.expandeCotizacionVendor=false;
            this.url = this.asignacionDocumentosAval(20);
            this.posicionArreglo = this.arrayViewDocument.indexOf(section);
          }
          break;
      /* case "csfA":
        this.expandedConstanciaSituacionFiscalAval = !this.expandedConstanciaSituacionFiscalAval
        if (this.expandedConstanciaSituacionFiscalAval) {
          this.expandedInfoIdOficial = false;
          this.expandedComprobanteDomicilio = false;
          this.expandedComprobanteDomicilioActivo = false;
          this.expandedEstadoCuenta = false;
          this.expandedInfoIdOficialAval = false;
          this.expandedConstanciaSituacionFiscal = false;
          this.expandedReferenciasPersonales = false;
          this.expandedComprobanteDomicilioAval = false;
          this.expandedInformacionConsultorio=false;
          this.expandeBuroSolicitante=false;
          this.url = this.asignacionDocumentosAval(5);
          this.posicionArreglo = this.arrayViewDocument.indexOf(section);

        }
      break; */
      case "refp":
        this.expandedReferenciasPersonales = !this.expandedReferenciasPersonales;
        if (this.expandedReferenciasPersonales) {
          this.expandedInfoIdOficial = false;
          this.expandedComprobanteDomicilio = false;
          this.expandedComprobanteDomicilioActivo = false;
          this.expandedEstadoCuenta = false;
          this.expandedInfoIdOficialAval = false;
          this.expandedConstanciaSituacionFiscalAval = false;
          this.expandedConstanciaSituacionFiscal = false;
          this.expandedComprobanteDomicilioAval = false;
          this.expandedInformacionConsultorio=false;
          this.expandeBuroSolicitante=false;
          this.expandeBuroAval=false;
          this.expandeCotizacionVendor=false;
          this.posicionArreglo = this.arrayViewDocument.indexOf(section);
          this.urlPrecente=false;
        }
      break;

      default:
        break;
    }



  }

  getInfoArcus() {
    this.arcusService.obtenerOcupaciones().then(res => {

      this.actividades = this.arcusService.CargarActividadesPrincipales(this.csf.sector.value)
    })
  }

  async obtenerData(folio) {
    try {
      const data = await this.solicitudService.ObtenerSolicitud(folio)
      return data;
    } catch (error) {
      console.error(error)
      return null;
    }

  }

  grupoFormulariosSolicitante() {
    //Inicializacion de FormGroup Identificacion Oficial
    this.info_IDO_FG = this.formBuilder.group({
      nombres: ['',Validators.required],
      ap_materno: ['',Validators.required],
      ap_paterno: ['',Validators.required],
      fecha_nac: ['',Validators.required],
      nacionalidad: ['',Validators.required],
      tipo_identificacion: ['Credencial para votar'],
      numero_identificacion: ['',Validators.required],
      curp:['',Validators.required],
      genero: ['',Validators.required],
      tituloPersona:['']


    });


    //Inicializacion de FormGroup Constancia Situacion fiscal
    this.info_CSF_FG = this.formBuilder.group({
      rfc: ['',Validators.required],
      regimen: [''],
      sector: ['',Validators.required],
      regimen_contribucion: ['',Validators.required],
      actividad_principal: ['',Validators.required],
      calle: ['',Validators.required],
      numExterior: ['',Validators.required],
      numInterior: [''],
      colonia: ['',Validators.required],
      ciudad: ['',Validators.required],
      municipio: ['',Validators.required],
      estado: ['',Validators.required],
      codigoPostal: ['',Validators.required],
      email: ['',Validators.required],
      tel_cel: ['',Validators.required],
      lat: [''],
      lng:['']
    });
    //Inicializacion de FormGroup Comprobante de domicilio
    this.info_CD_FG = this.formBuilder.group({
      calle: ['',Validators.required],
      numExterior: ['',Validators.required],
      numInterior: [''],
      colonia: ['',Validators.required],
      ciudad: ['',Validators.required],
      municipio: ['',Validators.required],
      estado: ['',Validators.required],
      codigoPostal: ['',Validators.required],
      tipo_vivienda:[''],
      pago_mensual:[''],
      lat: [''],
      lng:['']
    });
    //Inicializacion de FormGroup Comprobante de domicilio del activo
    this.info_CDA_FG = this.formBuilder.group({
      calle: ['',Validators.required],
      numExterior: ['',Validators.required],
      numInterior: [''],
      colonia: ['',Validators.required],
      ciudad: ['',Validators.required],
      municipio: ['',Validators.required],
      estado: ['',Validators.required],
      codigoPostal: ['',Validators.required],
      lat: [''],
      lng:['']
    });
    //Inicializacion de FormGroup Estado Cuenta
    this.info_ED_FG = this.formBuilder.group({
      ingreso: [''],
      desc_ingreso: [''],
      clabe: [''],
    });

    //Inicializacion de FormGroup Informacion Consultorio
    this.info_IC_FG= this.formBuilder.group({
      tipo_consultorio:[''],
      total_gasto:[''],
      ingreso_mensual_promedio:[''],
      equipo_solicitado:[''],
      numero_dependientes:[''],
      edad_dependiente1: [''],
      edad_dependiente2: [''],
      edad_dependiente3: [''],
      edad_dependiente4: [''],
      edad_dependiente5: [''],
      edad_dependiente6: ['']
    })

  }

  //Metodo para el arreglo de dependientes
  get dependientesArray(): number[] {
    const num = this.info_IC_FG.get('numero_dependientes')?.value || 0;
    return Array.from({ length: num }, (_, i) => i + 1);
  }
  grupoFormulariosvalidacion() {
    this.validacion_FG = this.formBuilder.group({
      motivo_cancelacion: ['', Validators.required],
    });
  }
  grupoFormulariosAval() {
    //Inicializacion de FormGroup Identificacion Oficial
    this.info_IDO_AVAL_FG = this.formBuilder.group({
      nombres: ['',Validators.required],
      ap_materno: ['',Validators.required],
      ap_paterno: ['',Validators.required],
      fecha_nac: ['',Validators.required],
      nacionalidad: ['',Validators.required],
      tipo_identificacion: ['Credencial para votar'],
      numero_identificacion: [''],
      genero: ['',Validators.required],
      email:['',Validators.required],
      telefono:['',Validators.required],
      rfc: ['',Validators.required],
    });
    //Inicializacion de FormGroup Constancia Situacion fiscal Aval
    this.info_CSF_AVAL_FG = this.formBuilder.group({
      
      regimen: [''],
      sector: [''],
      regimen_contribucion: [''],
      calle: [''],
      numExterior: [''],
      numInterior: [''],
      colonia: [''],
      ciudad: [''],
      municipio: [''],
      estado: [''],
      codigoPostal: [''],
      actividad_principal: [''],
      lat: [''],
      lng:['']

    });
    //Inicializacion de FormGroup Comprobante de domicilio Aval
    this.info_CD_AVAL_FG = this.formBuilder.group({
      calle: ['',Validators.required],
      numExterior: ['',Validators.required],
      numInterior: [''],
      codigoPostal: ['',Validators.required],
      colonia: ['',Validators.required],
      municipio: ['',Validators.required],
      ciudad: ['',Validators.required],
      estado: ['',Validators.required],
      avalF_domicilio:[''],
      lat: [''],
      lng:['']
    });
  }

  grupoFormulariosReferencias() {
    this.info_REF_PER = this.formBuilder.group({
      rp_nombre1: ['',Validators.required],
      ocupacion1: ['',Validators.required],
      rp_direccion1: ['',Validators.required],
      rp_telefono1: ['',Validators.required],
      rp_parentesco1: ['',Validators.required],
    });
  }

  grupoEstadoCuenta(){
    this.info_ED_FG=this.formBuilder.group({
      ingreso:[''],
      descripcion:[''],
      clave:['']
    })
  }

  setDataAval(aval) {

    if (aval?.nombreCompleto) {
      this.ido_Aval.nombres.setValue(aval?.nombreCompleto || null);
      this.ido_Aval.ap_materno.setValue(aval?.apellidoMaterno || null);
      this.ido_Aval.ap_paterno.setValue(aval?.apellidoPaterno || null);
      this.ido_Aval.fecha_nac.setValue(aval?.fechaNacimiento || null);
      this.ido_Aval.nacionalidad.setValue(aval?.nacionalidad === '1595863462706x437395425278820350' ? 'Mexicana' : null);
      this.ido_Aval.tipo_identificacion.setValue(aval?.tipoIdentificacion || null);
      this.ido_Aval.numero_identificacion.setValue(aval?.numeroIdentificacion || null);
      this.ido_Aval.genero.setValue(aval?.genero ? (aval.genero === "Hombre" ? "M" : "F") : null);
      this.ido_Aval.rfc.setValue(aval?.rfc || null)
      this.ido_Aval.email.setValue(aval?.email ||null)
      this.ido_Aval.telefono.setValue(aval?.telefono ||null)
    }
    if (aval?.direccion) {
      this.cd_Aval.calle.setValue(aval?.direccion?.calle || null);
      this.cd_Aval.ciudad.setValue(aval?.direccion?.ciudad || null);
      this.cd_Aval.colonia.setValue(aval?.direccion?.colonia || null);
      this.cd_Aval.codigoPostal.setValue(aval?.direccion?.cp || null);
      this.cd_Aval.estado.setValue(aval?.direccion?.estado || null);
      this.cd_Aval.municipio.setValue(aval?.direccion?.municipio || null);
      this.cd_Aval.numExterior.setValue(aval?.direccion?.numeroExterior || null);
      this.cd_Aval.numInterior.setValue(aval?.direccion?.numeroInterior || null);
      this.cd_Aval.avalF_domicilio.setValue(aval?.direccion?.tipo_vivienda ||null);
      this.cd_Aval.lat.setValue(aval?.direccion?.lat ||null);
      this.cd_Aval.lng.setValue(aval?.direccion?.lng ||null); 
    }
    if (aval?.direccionFiscal) {
      this.csf_Aval.calle.setValue(aval?.direccionFiscal?.calle || null);
      this.csf_Aval.ciudad.setValue(aval?.direccionFiscal?.ciudad || null);
      this.csf_Aval.colonia.setValue(aval?.direccionFiscal?.colonia || null);
      this.csf_Aval.codigoPostal.setValue(aval?.direccionFiscal?.cp || null);
      this.csf_Aval.estado.setValue(aval?.direccionFiscal?.estado || null);
      this.csf_Aval.municipio.setValue(aval?.direccionFiscal?.municipio || null);
      this.csf_Aval.numExterior.setValue(aval?.direccionFiscal?.numeroExterior || null);
      this.csf_Aval.numInterior.setValue(aval?.direccionFiscal?.numeroInterior || null);
      this.csf_Aval.actividad_principal.setValue(aval?.actividadPrincipal || null);
      this.csf_Aval.sector.setValue(aval?.sectorEconomico || null);
      this.csf_Aval.regimen_contribucion.setValue(aval?.regimenContribucion || null);
      this.csf_Aval.lat.setValue(aval?.direccionFiscal?.lat ||null);
      this.csf_Aval.lng.setValue(aval?.direccionFiscal?.lng ||null);
    }
  }

  setDataSolicitante(contacto) {
    this.ido.nombres.setValue(contacto?.nombreCompleto || null);
    this.ido.ap_materno.setValue(contacto?.apellidoMaterno || null);
    this.ido.ap_paterno.setValue(contacto?.apellidoPaterno || null);
    this.ido.fecha_nac.setValue(contacto?.fechaNacimiento || null);
    this.ido.nacionalidad.setValue(contacto?.nacionalidad === '1595863462706x437395425278820350' ? 'Mexicana' : null);
    this.ido.tipo_identificacion.setValue(contacto?.tipoIdentificacion || null);
    this.ido.numero_identificacion.setValue(contacto?.numeroIdentificacion || null);
    this.ido.curp.setValue(contacto?.curp || null)
    this.ido.genero.setValue(contacto?.genero ? (contacto.genero === "Hombre" ? "M" : "F") : null);
    this.ido.tituloPersona.setValue(contacto?.tituloPersona || null);
    console.log('+tituloPersona+',this.ido.tituloPersona.value)

  }

  setDataDireccionActivo(direccionActivo) {
    this.cda.calle.setValue(direccionActivo?.calle || null);
    this.cda.ciudad.setValue(direccionActivo?.ciudad || null);
    this.cda.colonia.setValue(direccionActivo?.colonia || null);
    this.cda.codigoPostal.setValue(direccionActivo?.cp || null);
    this.cda.estado.setValue(direccionActivo?.estado || null);
    this.cda.municipio.setValue(direccionActivo?.municipio || null);
    this.cda.numExterior.setValue(direccionActivo?.numExt || null);
    this.cda.numInterior.setValue(direccionActivo?.numInt || null);
    this.cda.lat.setValue(direccionActivo?.lat ||null);
    this.cda.lng.setValue(direccionActivo?.lng ||null);

  }

  setDataDireccion(direccion,infoComplementariaDomicilio) {
    this.cd.calle.setValue(direccion?.calle || null);
    this.cd.ciudad.setValue(direccion?.ciudad || null);
    this.cd.colonia.setValue(direccion?.colonia || null);
    this.cd.codigoPostal.setValue(direccion?.cp || null);
    this.cd.estado.setValue(direccion?.estado || null);
    this.cd.municipio.setValue(direccion?.municipio || null);
    this.cd.numExterior.setValue(direccion?.numeroExterior || null);
    this.cd.numInterior.setValue(direccion?.numeroInterior || null);
    this.cd.tipo_vivienda.setValue(infoComplementariaDomicilio?.tipo_vivienda || null)
    this.cd.pago_mensual.setValue(infoComplementariaDomicilio?.pago_mensual || null)
    this.cd.lat.setValue(direccion?.lat ||null);
    this.cd.lng.setValue(direccion?.lng ||null); 
  }

  setDataConstancia(direccionFiscal, contacto,infoComplementaria ) {
    this.csf.rfc.setValue(contacto?.rfc || null);
    this.csf.regimen_contribucion.setValue(contacto?.regimenContribucion || null);
    this.csf.sector.setValue(contacto?.sectorEconomico || null);
    this.csf.actividad_principal.setValue(contacto?.actividadPrincipal || null)
    this.csf.email.setValue(contacto?.email || null)
    this.csf.tel_cel.setValue(infoComplementaria?.telCel)

    this.csf.calle.setValue(direccionFiscal?.calle || null);
    this.csf.ciudad.setValue(direccionFiscal?.ciudad || null);
    this.csf.colonia.setValue(direccionFiscal?.colonia || null);
    this.csf.codigoPostal.setValue(direccionFiscal?.cp || null);
    this.csf.estado.setValue(direccionFiscal?.estado || null);
    this.csf.municipio.setValue(direccionFiscal?.municipio || null);
    this.csf.numExterior.setValue(direccionFiscal?.numeroExterior || null);
    this.csf.numInterior.setValue(direccionFiscal?.numeroInterior || null);
    this.csf.lat.setValue(direccionFiscal?.lat ||null);
    this.csf.lng.setValue(direccionFiscal?.lng ||null); 
     


    this.info_CSF_FG.markAsDirty();
    this.info_CSF_FG.markAsTouched();

    if(infoComplementaria?.lista_actividades){
      this.array_actividad_economica=infoComplementaria?.lista_actividades;
    }
    if(infoComplementaria?.lista_regimenes){
     this.array_regimen=infoComplementaria?.lista_regimenes;
    }
  }

  setDataReferenciasPersonales(referencias) {
    this.ref_per.rp_nombre1.setValue(referencias?.rp_nombre1 || null);
    this.ref_per.ocupacion1.setValue(referencias?.ocupacion1 || null);
    this.ref_per.rp_direccion1.setValue(referencias?.rp_direccion1 || null)
    this.ref_per.rp_telefono1.setValue(referencias?.rp_telefono1 || null)
    this.ref_per.rp_parentesco1.setValue(referencias?.rp_parentesco1 || null)
  }

  setDataInformacionConsultorio(infoConsultorio){
    this.ic.tipo_consultorio.setValue(infoConsultorio?.tipo_consultorio || null)
    this.ic.total_gasto.setValue(infoConsultorio?.total_gasto || null)
    this.ic.ingreso_mensual_promedio.setValue(infoConsultorio?.ingreso_mensual_promedio || null)
    this.ic.equipo_solicitado.setValue(infoConsultorio?.equipo_solicitado || null)
    this.ic.numero_dependientes.setValue(infoConsultorio?.numero_dependientes || null)
    this.ic.edad_dependiente1.setValue(infoConsultorio?.edad_dependiente1 || null)
    this.ic.edad_dependiente2.setValue(infoConsultorio?.edad_dependiente2 || null)
    this.ic.edad_dependiente3.setValue(infoConsultorio?.edad_dependiente3 || null)
    this.ic.edad_dependiente4.setValue(infoConsultorio?.edad_dependiente4 || null)
    this.ic.edad_dependiente5.setValue(infoConsultorio?.edad_dependiente5 || null)
    this.ic.edad_dependiente6.setValue(infoConsultorio?.edad_dependiente6 || null)
  
  }

  setDataEstadoCuenta(estadoCuenta){
    this.ed.ingreso.setValue(estadoCuenta?.ingresos || null)
    this.ed.descripcion.setValue(estadoCuenta?.descripcion || null)
    this.ed.clave.setValue(estadoCuenta?.clave || null)
    
  }

  ChangeActividad() {
    this.actividades = this.arcusService.CargarActividadesPrincipales(this.csf.sector.value)
  }

  async BuscarDireccion(section: string, codigoPostal: string) {
    this.formularioElegido = section;
  
    const cp: string = codigoPostal.toString();

    if (cp.toString().length == 5) {
      const res: any = await this.buroService.buscarEstado(cp)
      this.guardarColonias(res, section)
      if (res[0].error == false) {
        this.direccion = {
          ...this.direccion,
          //cp: codigoPostal,
          estado: res[0].response.estado,
          municipio: res[0].response.municipio,
          ciudad: res[0].response.ciudad || '',
          delegacion: res[0].response.municipio
          //colonia: res.colonias?.[0] || '',
        };
        //console.log(this.direccion);
        this.setDataColonias(section, this.direccion);

      }

    } else {
    }

  }
  guardarColonias(direcciones, section) {
    switch (section) {
      case "csf":
        this.coloniasDisponibles_csf = [];
        this.csf.colonia.setValue(null)
        direcciones.forEach(element => {
          const { response: { asentamiento } } = element
          this.coloniasDisponibles_csf.push(asentamiento);
        });
        break;
      case "cd":
        this.coloniasDisponibles_cd = [];
        this.cd.colonia.setValue(null)
        direcciones.forEach(element => {
          const { response: { asentamiento } } = element
          this.coloniasDisponibles_cd.push(asentamiento);
        });
        break;
      case "cda":
        this.coloniasDisponibles_cda = [];
        this.cda.colonia.setValue(null)
        direcciones.forEach(element => {
          const { response: { asentamiento } } = element
          this.coloniasDisponibles_cda.push(asentamiento);
        });
        break;
      case "csfA":
        this.coloniasDisponibles_csf_aval = [];
        this.csf_Aval.colonia.setValue(null)
        direcciones.forEach(element => {
          const { response: { asentamiento } } = element
          this.coloniasDisponibles_csf_aval.push(asentamiento);
        });
        break;
      case "cdA":
        this.coloniasDisponibles_cd_aval = [];
        this.cd_Aval.colonia.setValue(null)
        direcciones.forEach(element => {
          const { response: { asentamiento } } = element
          this.coloniasDisponibles_cd_aval.push(asentamiento);
        });
        break;
      default:
        break;

    }
  }
  setColonia(colonia) {
    switch (this.formularioElegido) {
      case "csf":
        this.csf.colonia.setValue(colonia);
        break;
      case "cd":
        this.cd.colonia.setValue(colonia);
        break;
      case "cda":
        this.cda.colonia.setValue(colonia);
        break;
      case "csfA":
        this.csf_Aval.colonia.setValue(colonia);
        break;
      case "cdA":
        this.cd_Aval.colonia.setValue(colonia);
        break;
      default:
        break;

    }
  }

  setDataColonias(section, direccion) {
    switch (section) {
      case "csf":
        this.csf.ciudad.setValue(direccion?.ciudad || null);
        this.csf.colonia.setValue(direccion?.colonia || null);
        this.csf.estado.setValue(direccion?.estado || null);
        this.csf.municipio.setValue(direccion?.municipio || null);
        break;
      case "cd":
        this.cd.ciudad.setValue(direccion?.ciudad || null);
        this.cd.colonia.setValue(direccion?.colonia || null);
        this.cd.estado.setValue(direccion?.estado || null);
        this.cd.municipio.setValue(direccion?.municipio || null);
        break;
      case "cda":
        this.cda.ciudad.setValue(direccion?.ciudad || null);
        this.cda.colonia.setValue(direccion?.colonia || null);
        this.cda.estado.setValue(direccion?.estado || null);
        this.cda.municipio.setValue(direccion?.municipio || null);
        break;
      case "csfA":
        this.csf_Aval.ciudad.setValue(direccion?.ciudad || null);
        this.csf_Aval.colonia.setValue(direccion?.colonia || null);
        this.csf_Aval.estado.setValue(direccion?.estado || null);
        this.csf_Aval.municipio.setValue(direccion?.municipio || null);
        break;
      case "cdA":
        this.cd_Aval.ciudad.setValue(direccion?.ciudad || null);
        this.cd_Aval.colonia.setValue(direccion?.colonia || null);
        this.cd_Aval.estado.setValue(direccion?.estado || null);
        this.cd_Aval.municipio.setValue(direccion?.municipio || null);
        break;
      default:
        break;


    }

  }

  //Guardar formularios

  async guardarIdOficial() {
    const res =await this.validarFormulario(this.info_IDO_FG); 
    if(res) return;

    const contacto = {
      actividadPrincipal: this.csf.actividad_principal.value,
      apellidoPaterno: this.ido.ap_paterno.value,
      apellidoMaterno: this.ido.ap_materno.value,
      curp: this.ido.curp.value,
      email: this.csf.email.value,
      fechaNacimiento: this.ido.fecha_nac.value,
      genero: this.ido.genero.value == 'M' ? "Hombre" : 'Mujer',
      nacionalidad: "1595863462706x437395425278820350",
      nombreCompleto: this.ido.nombres.value,
      numeroIdentificacion: this.ido.numero_identificacion.value,
      regimenContribucion: this.csf.regimen_contribucion.value,
      rfc: this.csf.rfc.value,
      sectorEconomico: this.csf.sector.value,
      tipoIdentificacion: this.ido.tipo_identificacion.value,
      tipoPersona: "Persona fisica",
      tituloPersona: this.ido.tituloPersona.value
    };
    try {
      const response: any = await this.solicitudService.GuardarSolicitud(this.folio, { contacto });
      if (response.statusCode === 200) {
        this.dialog.open(MessageBoxComponent, {
          width: "500px",
          data: {
            text: "Éxito",
            text3: "Tu información se ha guardado con éxito.",
            icon: "success",
            type: "success"
          }
        });
      } else {
        this.dialog.open(MessageBoxComponent, {
          width: "500px",
          data: {
            text: "Error",
            text3: "No se pudo guardar la información.",
            icon: "error",
            type: "error"
          }
        }); 
      }
    } catch (error) {
      console.error("Error al guardar la solicitud :::::", error);
      this.dialog.open(MessageBoxComponent, {
        width: "500px",
        data: {
          text: "Error",
          text3: "Ocurrió un error al procesar la solicitud.",
          icon: "error",
          type: "error"
        }
      });
    }
  }

  async guardarCosntaciFiscal() {

    const res =await this.validarFormulario(this.info_CSF_FG); 
    if(res) return;

    const contacto = {
      actividadPrincipal: this.csf.actividad_principal.value,
      apellidoPaterno: this.ido.ap_paterno.value,
      apellidoMaterno: this.ido.ap_materno.value,
      curp: this.ido.curp.value,
      email: this.csf.email.value,
      fechaNacimiento: this.ido.fecha_nac.value,
      genero: this.ido.genero.value == 'M' ? "Hombre" : 'Mujer',
      nacionalidad: "1595863462706x437395425278820350",
      nombreCompleto: this.ido.nombres.value,
      numeroIdentificacion: this.ido.numero_identificacion.value,
      regimenContribucion: this.csf.regimen_contribucion.value,
      rfc: this.csf.rfc.value,
      sectorEconomico: this.csf.sector.value,
      tipoIdentificacion: this.ido.tipo_identificacion.value,
      tipoPersona: "Persona fisica",
      tituloPersona: this.ido.tituloPersona.value
    };
    const direccionFiscal = {
      direccionCompleta: `${this.csf.calle.value} ${this.csf.numExterior.value} ${this.csf.numInterior.value}, ${this.csf.colonia.value}, ${this.csf.municipio.value}, ${this.csf.estado.value}, C.P.: ${this.csf.codigoPostal.value}`.trim(),
      estado: this.csf.estado.value,
      municipio: this.csf.municipio.value,
      ciudad: this.csf.ciudad.value,
      colonia: this.csf.colonia.value,
      calle: this.csf.calle.value,
      numeroExterior: this.csf.numExterior.value,
      numeroInterior: this.csf.numInterior.value,
      cp: this.csf.codigoPostal.value,
      lat:this.csf.lat.value,
      lng:this.csf.lng.value,

    }
    const infoComplementaria={
      telCel:this.csf.tel_cel.value
    }


    try {
      const response: any = await this.solicitudService.GuardarSolicitud(this.folio, { contacto, direccionFiscal,infoComplementaria });
      if (response.statusCode === 200) {
        this.dialog.open(MessageBoxComponent, {
          width: "500px",
          data: {
            text: "Éxito",
            text3: "Tu información se ha guardado con éxito.",
            icon: "success",
            type: "success"
          }
        });
      } else {
        this.dialog.open(MessageBoxComponent, {
          width: "500px",
          data: {
            text: "Error",
            text3: "No se pudo guardar la información.",
            icon: "error",
            type: "error"
          }
        });
      }
    } catch (error) {
      console.error("Error al guardar la solicitud :::::", error);
      this.dialog.open(MessageBoxComponent, {
        width: "500px",
        data: {
          text: "Error",
          text3: "Ocurrió un error al procesar la solicitud.",
          icon: "error",
          type: "error"
        }
      });
    }
  }

  async guardarComprobanteDomicilio() {
    const res =await this.validarFormulario(this.info_CD_FG); 
    if(res) return;

    const direccion = {
      direccionCompleta: `${this.cd.calle.value} ${this.cd.numExterior.value} ${this.cd.numInterior.value}, ${this.cd.colonia.value}, ${this.cd.municipio.value}, ${this.cd.estado.value}, C.P.: ${this.cd.codigoPostal.value}`.trim(),
      estado: this.cd.estado.value,
      municipio: this.cd.municipio.value,
      ciudad: this.cd.ciudad.value,
      colonia: this.cd.colonia.value,
      calle: this.cd.calle.value,
      numeroExterior: this.cd.numExterior.value,
      numeroInterior: this.cd.numInterior.value,
      cp: this.cd.codigoPostal.value,
      lat:this.cd.lat.value,
      lng:this.cd.lng.value
    }
    const infoComplementariaDomicilio={
      tipo_vivienda: this.cd.tipo_vivienda.value,
      pago_mensual: this.cd.pago_mensual.value
    }
    try {
      const response: any = await this.solicitudService.GuardarSolicitud(this.folio, { direccion,infoComplementariaDomicilio });
      if (response.statusCode === 200) {
        this.dialog.open(MessageBoxComponent, {
          width: "500px",
          data: {
            text: "Éxito",
            text3: "Tu información se ha guardado con éxito.",
            icon: "success",
            type: "success"
          }
        });
      } else {
        this.dialog.open(MessageBoxComponent, {
          width: "500px",
          data: {
            text: "Error",
            text3: "No se pudo guardar la información.",
            icon: "error",
            type: "error"
          }
        });
      }
    } catch (error) {
      console.error("Error al guardar la solicitud :::::", error);
      this.dialog.open(MessageBoxComponent, {
        width: "500px",
        data: {
          text: "Error",
          text3: "Ocurrió un error al procesar la solicitud.",
          icon: "error",
          type: "error"
        }
      });
    }
  }

  async guardardDireccionActivo() {
    const res =await this.validarFormulario(this.info_CDA_FG); 
    if(res) return;
    const direccionActivo = {
      direccionCompleta: `${this.cda.calle.value} ${this.cda.numExterior.value} ${this.cda.numInterior.value}, ${this.cda.colonia.value}, ${this.cda.municipio.value}, ${this.cda.estado.value}, C.P.: ${this.cda.codigoPostal.value}`.trim(),
      estado: this.cda.estado.value,
      municipio: this.cda.municipio.value,
      ciudad: this.cda.ciudad.value,
      colonia: this.cda.colonia.value,
      calle: this.cda.calle.value,
      numExt: this.cda.numExterior.value,
      numInt: this.cda.numInterior.value,
      cp: this.cda.codigoPostal.value,
      pais: this.ido.nacionalidad.value,
      lat:this.cda.lat.value,
      lng:this.cda.lng.value
    }
    try {
      const response: any = await this.solicitudService.GuardarSolicitud(this.folio, { direccionActivo });
      if (response.statusCode === 200) {
        this.dialog.open(MessageBoxComponent, {
          width: "500px",
          data: {
            text: "Éxito",
            text3: "Tu información se ha guardado con éxito.",
            icon: "success",
            type: "success"
          }
        });
      } else {
        this.dialog.open(MessageBoxComponent, {
          width: "500px",
          data: {
            text: "Error",
            text3: "No se pudo guardar la información.",
            icon: "error",
            type: "error"
          }
        });
      }
    } catch (error) {
      console.error("Error al guardar la solicitud :::::", error);
      this.dialog.open(MessageBoxComponent, {
        width: "500px",
        data: {
          text: "Error",
          text3: "Ocurrió un error al procesar la solicitud.",
          icon: "error",
          type: "error"
        }
      });
    }
  }
  async guardarDatosAval(grupoFormulario:any) {
    const res =await this.validarFormulario(grupoFormulario); 
    if(res) return;

    const aval = {
      tipoPersona: "Persona física",
      nombreCompleto: this.ido_Aval.nombres.value,
      apellidoPaterno: this.ido_Aval.ap_paterno.value,
      apellidoMaterno: this.ido_Aval.ap_materno.value,
      nacionalidad: "1595863462706x437395425278820350",
      fechaNacimiento: this.ido_Aval.fecha_nac.value,
      genero: this.ido_Aval.genero.value == 'M' ? "Hombre" : 'Mujer',
      rfc: this.ido_Aval.rfc.value,
      numeroIdentificacion: this.ido_Aval.numero_identificacion.value,
      tipoIdentificacion: this.ido_Aval.tipo_identificacion.value,
      email: this.ido_Aval.email.value,
      telefono: this.ido_Aval.telefono.value,
      direccion: {
        direccionCompleta: `${this.cd_Aval.calle.value} ${this.cd_Aval.numExterior.value} ${this.cd_Aval.numInterior.value}, ${this.cd_Aval.colonia.value}, ${this.cd_Aval.municipio.value}, ${this.cd_Aval.estado.value}, C.P.: ${this.cd_Aval.codigoPostal.value}`.trim(),
        estado: this.cd_Aval.estado.value,
        municipio: this.cd_Aval.municipio.value,
        ciudad: this.cd_Aval.ciudad.value,
        colonia: this.cd_Aval.colonia.value,
        calle: this.cd_Aval.calle.value,
        numeroExterior: this.cd_Aval.numExterior.value,
        numeroInterior: this.cd_Aval.numInterior.value,
        cp: this.cd_Aval.codigoPostal.value,
        tipo_vivienda: this.cd_Aval.avalF_domicilio.value,
        lat:this.cd_Aval.lat.value,
        lng:this.cd_Aval.lng.value
      },
      direccionFiscal: {
        direccionCompleta: `${this.csf_Aval.calle.value} ${this.csf_Aval.numExterior.value} ${this.csf_Aval.numInterior.value}, ${this.csf_Aval.colonia.value}, ${this.csf_Aval.municipio.value}, ${this.csf_Aval.estado.value}, C.P.: ${this.csf_Aval.codigoPostal.value}`.trim(),
        estado: this.csf_Aval.estado.value,
        municipio: this.csf_Aval.municipio.value,
        ciudad: this.csf_Aval.ciudad.value,
        colonia: this.csf_Aval.colonia.value,
        calle: this.csf_Aval.calle.value,
        numeroExterior: this.csf_Aval.numExterior.value,
        numeroInterior: this.csf_Aval.numInterior.value,
        cp: this.csf_Aval.codigoPostal.value,
        lat:this.csf_Aval.lat.value,
        lng:this.csf_Aval.lng.value
      }
    }
    try {
      const response: any = await this.solicitudService.GuardarSolicitud(this.folio, { aval });
      if (response.statusCode === 200) {
        this.dialog.open(MessageBoxComponent, {
          width: "500px",
          data: {
            text: "Éxito",
            text3: "Tu información se ha guardado con éxito.",
            icon: "success",
            type: "success"
          }
        });
      } else {
        this.dialog.open(MessageBoxComponent, {
          width: "500px",
          data: {
            text: "Error",
            text3: "No se pudo guardar la información.",
            icon: "error",
            type: "error"
          }
        });
      }
    } catch (error) {
      console.error("Error al guardar la solicitud :::::", error);
      this.dialog.open(MessageBoxComponent, {
        width: "500px",
        data: {
          text: "Error",
          text3: "Ocurrió un error al procesar la solicitud.",
          icon: "error",
          type: "error"
        }
      });
    }
  }

  async guardarReferenciasPersonales() {

    const res =await this.validarFormulario(this.info_REF_PER); 
    if(res) return;

    const referencias = {
      rp_nombre1: this.ref_per.rp_nombre1.value,
      ocupacion1: this.ref_per.ocupacion1.value,
      rp_direccion1: this.ref_per.rp_direccion1.value,
      rp_telefono1: this.ref_per.rp_telefono1.value,
      rp_parentesco1: this.ref_per.rp_parentesco1.value
    }

    try {
      const response: any = await this.solicitudService.GuardarSolicitud(this.folio, { referencias });
      if (response.statusCode === 200) {
        this.dialog.open(MessageBoxComponent, {
          width: "500px",
          data: {
            text: "Éxito",
            text3: "Tu información se ha guardado con éxito.",
            icon: "success",
            type: "success"
          }
        });
      } else {
        this.dialog.open(MessageBoxComponent, {
          width: "500px",
          data: {
            text: "Error",
            text3: "No se pudo guardar la información.",
            icon: "error",
            type: "error"
          }
        });
      }
    } catch (error) {
      console.error("Error al guardar la solicitud :::::", error);
      this.dialog.open(MessageBoxComponent, {
        width: "500px",
        data: {
          text: "Error",
          text3: "Ocurrió un error al procesar la solicitud.",
          icon: "error",
          type: "error"
        }
      });
    }
  }

  async guardarInformacionConsultorio(){
    const res =await this.validarFormulario(this.info_IC_FG); 
    if(res) return;
    const informacionAdicional = {
      tipo_consultorio: this.ic.tipo_consultorio.value,
      total_gasto:this.ic.total_gasto.value,
      ingreso_mensual_promedio:this.ic.ingreso_mensual_promedio.value,
      equipo_solicitado:this.ic.equipo_solicitado.value,
      numero_dependientes:this.ic.numero_dependientes.value,
      edad_dependiente1: this.ic.edad_dependiente1.value,
      edad_dependiente2: this.ic.edad_dependiente2.value,
      edad_dependiente3: this.ic.edad_dependiente3.value,
      edad_dependiente4: this.ic.edad_dependiente4.value,
      edad_dependiente5: this.ic.edad_dependiente5.value,
      edad_dependiente6: this.ic.edad_dependiente6.value,
    }
    try {
      const response: any = await this.solicitudService.GuardarSolicitud(this.folio, { informacionAdicional });
      if (response.statusCode === 200) {
        this.dialog.open(MessageBoxComponent, {
          width: "500px",
          data: {
            text: "Éxito",
            text3: "Tu información se ha guardado con éxito.",
            icon: "success",
            type: "success"
          }
        });
      } else {
        this.dialog.open(MessageBoxComponent, {
          width: "500px",
          data: {
            text: "Error",
            text3: "No se pudo guardar la información.",
            icon: "error",
            type: "error"
          }
        });
      }
    } catch (error) {
      console.error("Error al guardar la solicitud :::::", error);
      this.dialog.open(MessageBoxComponent, {
        width: "500px",
        data: {
          text: "Error",
          text3: "Ocurrió un error al procesar la solicitud.",
          icon: "error",
          type: "error"
        }
      });
    }
  }

  async validarFormulario(formulario:any){

    formulario.markAllAsTouched();

    if (formulario.invalid) {
      this.dialog.open(MessageBoxComponent, {
        width: "500px",
        data: {
          text: "Información incompleta. Favor de completar los campos requeridos.",
          type: "error",
          icon: "error",
        },
      });
      return true; // Indica que el formulario es inválido
    }
    return false;
  }

  //***************************************** */

 
  async guardarEstadosCuents() {
    const estadoCuenta ={
      ingresos: this.ed.ingreso.value,
      descripcion:this.ed.descripcion.value,
      clave:this.ed.clave.value
    }

    try {
      const response: any = await this.solicitudService.GuardarSolicitud(this.folio, {estadoCuenta});
      if (response.statusCode === 200) {
        this.dialog.open(MessageBoxComponent, {
          width: "500px",
          data: {
            text: "Éxito",
            text3: "Tu información se ha guardado con éxito.",
            icon: "success",
            type: "success"
          }
        });
      } else {
        this.dialog.open(MessageBoxComponent, {
          width: "500px",
          data: {
            text: "Error",
            text3: "No se pudo guardar la información.",
            icon: "error",
            type: "error"
          }
        });
      }
    } catch (error) {
      console.error("Error al guardar la solicitud :::::", error);
      this.dialog.open(MessageBoxComponent, {
        width: "500px",
        data: {
          text: "Error",
          text3: "Ocurrió un error al procesar la solicitud.",
          icon: "error",
          type: "error"
        }
      });
    }
    
   }

  salir() {
    this.dialogRef.close()
  }

  /**
   * SECCION DE VALIDACION DE DOCUMENTOS 
   */

  asignacionDocumentos(tipo): string {
    this.url='';
    if(tipo==4 || tipo==17 || tipo==18){
      const documento = this.doc.documentos.DocumentosSolicitante
      .find(
        (doc) => doc.tipo_documento === tipo
      );
    this.statusDucumento = documento.status;
    this.validacion_FG.get('motivo_cancelacion').setValue(documento?.comentarios || null);
    if(documento.url=='' || documento.url==null ){
      documento.url='';
      this.urlPrecente=false;
    }else{
      this.url=documento
      this.urlPrecente=true;
      this.solicitante=true;
    }
    return documento
    }
    if (tipo === 11 || tipo === 12) {
      const documento = this.doc.documentos.DocumentoComprobanteDomicilio
        .find(
          (doc) => doc.tipo_documento === tipo
        );
      this.statusDucumento = documento.status;
      this.validacion_FG.get('motivo_cancelacion').setValue(documento?.comentarios || null);
      if(documento.url==''){
        documento.url='';
        this.urlPrecente=false;
      }else{
        this.urlPrecente=true;
        this.solicitante=true;
      }
      return documento
    }

    const documento = this.doc.documentos.DocumentosSolicitante.find(
      (doc) => doc.tipo_documento === tipo
    );
    this.statusDucumento = documento.status;
    this.validacion_FG.get('motivo_cancelacion').setValue(documento?.comentarios || null);
    if(documento.url==''){
      documento.url='';
      this.urlPrecente=false;
    }else{
      this.urlPrecente=true;
      this.solicitante=true;
    }
    return documento;

  }
  asignacionDocumentosAval(tipo): string {
    this.url='';
    if (tipo === 12) {
      //const documento = this.doc.documentos.DocumentoComprobanteDomicilio
      const documento = this.doc.documentos.DocumentosAval
        .find(
          (doc) => doc.tipo_documento === tipo
        );
      this.statusDucumento = documento.status;
      this.validacion_FG.get('motivo_cancelacion').setValue(documento?.comentarios || null);
      if(documento.url==''){
        documento.url='';
        this.urlPrecente=false;
      }else{
        this.urlPrecente=true;
        this.solicitante=false;
      }
      return documento
    }
    if(tipo === 11 ){
      const documento = this.doc.documentos.DocumentosAvalDomicilio
      .find(
        (doc) => doc.tipo_documento === tipo
      );
    this.statusDucumento = documento.status;
    this.validacion_FG.get('motivo_cancelacion').setValue(documento?.comentarios || null);
    if(documento.url==''){
      documento.url='';
      this.urlPrecente=false;
    }else{
      this.urlPrecente=true;
      this.solicitante=false;
    }
    return documento
    }

    const documento = this.doc.documentos.DocumentosAval.find(
      (doc) => doc.tipo_documento === tipo
    );
    this.statusDucumento = documento.status;
    this.validacion_FG.get('motivo_cancelacion').setValue(documento?.comentarios || null);
    if(documento.url==''){
      documento.url='';
      this.urlPrecente=false;
    }else{
      this.urlPrecente=true;
      this.solicitante=false;
    }
    return documento;

  }

  siguientelDocumento() {
    this.posicionArreglo = (this.posicionArreglo + 1) % this.arrayViewDocument.length;
    const document = this.arrayViewDocument[this.posicionArreglo];

    this.toggleExpand(document);
  }

  regresarDocumento() {
    this.posicionArreglo = (this.posicionArreglo - 1 + this.arrayViewDocument.length) % this.arrayViewDocument.length;
    const document = this.arrayViewDocument[this.posicionArreglo];
    this.toggleExpand(document);

  }

  zoomIn() {

    if (this.zoomLevel < 2.0) { // Límite máximo de zoom
      this.zoomLevel += 0.1;
    }
  }

  zoomOut() {
    if (this.zoomLevel > 0.8) { // Límite mínimo de zoom
      this.zoomLevel -= 0.1;
    }
  }

  async validacionDocumento() {

    const documento: any = this.url;

    if(this.urlPrecente){
      if ([1, 2].includes(documento.status)) {
        try {
          await this.userService.CambiarStatusDocumento({
            folio: this.folio,
            tipo_documento: documento.tipo_documento,
            solicitante: this.solicitante,
            status: 3,
          }).toPromise();
  
          await this.ObtenerStatus();
  
          // Mostrar mensaje de éxito
          this.dialog.open(MessageBoxComponent, {
            width: "500px",
            data: {
              text: "Documento validado correctamente.",
              text3: "El documento ha sido validado con éxito.",
              type: "success",
              icon: "check_circle",
            },
          });
        } catch (err) {
          // Mostrar mensaje de error si la validación falla
          this.dialog.open(MessageBoxComponent, {
            width: "500px",
            data: {
              text: "Hubo un error al validar el documento.",
              text3: "Vuelve a intentarlo o contacta al soporte.",
              type: "error",
              icon: "error",
            },
          });
        }
      }
    }
  }

  async cancelacionDocumento() {
    if(this.urlPrecente){
      if (this.validacion_FG.get('motivo_cancelacion').value != null) {
        const documento: any = this.url;
  
        this.userService
          .CambiarStatusDocumento({
            folio: this.folio,
            tipo_documento: documento.tipo_documento,
            solicitante: this.solicitante,
            status: 2,
            comentarios: this.validacion_FG.get('motivo_cancelacion').value,
          })
          .subscribe(
            (res) => {
              // this.userService.changeStatus(this.folio, 2, 2)
              this.ObtenerStatus();
              this.dialog.open(MessageBoxComponent, {
                width: "500px",
                data: {
                  text: "Documento cancelado con éxito.",
                  text3: "El documento ha sido cancelado correctamente.",
                  type: "success",
                  icon: "check_circle",
                },
              });
            },
            (err) => {
              this.dialog.open(MessageBoxComponent, {
                width: "500px",
                data: {
                  text: "El servicio no está disponible por el momento",
                  text3: "Vuelve a intentarlo.",
                  type: "error",
                  icon: "error",
                },
              });
              this.ObtenerStatus();
            }
          );
      } else {
        this.validacion_FG.markAllAsTouched();
        this.dialog.open(MessageBoxComponent, {
          width: '500px',
          data: {
            text: 'El comentario es obligatorio.',
            text3: 'Por favor, ingresa la información correspondiente en el campo de comentarios.',
            icon: 'error',
            type: 'error',
          },
        });
      }
    }
  }
  async ObtenerStatus() {
    // this.getIdUser();

    try {
      const res = await this.userService
        .ObtenerStatusDocuments(this.data_get_document)
        .toPromise();

      // Actualizar los datos de los documentos solicitantes
      this._actualizarDocumentos(
        this.doc.documentos.DocumentosSolicitante,
        res.DocumentosSolicitante
      );
      this._actualizarDocumentos(
        this.doc.documentos.DocumentoComprobanteDomicilio,
        res.DocumentosSolicitante
      );

      // Actualizar los datos de los documentos del aval
      this._actualizarDocumentos(
        this.doc.documentos.DocumentosAval,
        res.DocumentosAval
      );

      this._actualizarDocumentos(
        this.doc.documentos.DocumentosAvalDomicilio,
        res.DocumentosAval
      );

      this.documentos_necesarios =
        this.doc.documentos.DocumentosSolicitante.length +
        this.doc.documentos.DocumentosAval.length;
      this.documentos_estados_cuenta =
        this.doc.documentos.DocumentosSolicitante.filter((documento) =>
          [4, 17, 18].includes(documento.tipo_documento)
        );

      if (Number(this.perfil) === 4) {
        const result1 = this.doc.documentos.DocumentosSolicitante.filter(
          (doc: any) => {
            return doc.status === 2;
          }
        );

        const result2 = this.doc.documentos.DocumentosAval.filter(
          (doc: any) => {
            return doc.status === 2;
          }
        );
        this.cancelDoc = result1.length + result2.length > 0;
      }

      this.faltanDocumentos = false;
      this._hayDocumentosSinCargar(this.doc.documentos.DocumentosSolicitante);
      this._hayDocumentosSinCargar(this.doc.documentos.DocumentosAval);
      this.DocumentosCompletos();
      this.toggleExpand(this.arrayViewDocument[this.posicionArreglo]);
      setTimeout(() => {
        this.toggleExpand(this.arrayViewDocument[this.posicionArreglo]);
      }, 100);
    } catch (error) {
      console.error('Error al obtener los documentos:', error);
      this._snackBar.open(
        "Ha ocurrido un error, por favor vuelve a intentarlo. ",
        "Ok",
        {
          duration: 10000,
          horizontalPosition: "center",
          verticalPosition: "top",
          panelClass: ["warning-snackbar"],
        }
      );
    }
  }
  private _hayDocumentosSinCargar(documentos) {
    if (!this.faltanDocumentos) {
      for (const documento of documentos) {
        if (documento.tipo_documento != 21 && documento.status == 0) {
          this.faltanDocumentos = true;
          break;
        }
      }
    }
  }
  DocumentosCompletos() {
    console.log("Entrando Documentos completados ........")
    if (!this.userService.alertService.isOnline()) return;
    this.completado = true;
    let status = 0;

    this.doc.documentos.DocumentosSolicitante.forEach((documento) => {
      if (documento.requerido == true && documento.status != 3) {
        this.completado = false;
        console.log("DocumentosSolicitante ........",this.completado)
      }
      this.solicitud_prellenada =
        documento.tipo_documento == 21 && documento.url.length > 0;
    });
    if(!this.omitirAval){
      this.doc.documentos.DocumentosAval.forEach((documento) => {
        if (documento.requerido == true && documento.status != 3) {
          this.completado = false;
          console.log("DocumentosAval ........",this.completado)
        }
      });
    }
      if (this.doc.documentos.DocumentoComprobanteDomicilio)
        this.doc.documentos.DocumentoComprobanteDomicilio
        .forEach((documento) => {
          if (documento.requerido == true && documento.status != 3) {
            this.completado = false;
            console.log("DocumentoComprobanteDomicilio ........",this.completado)
          }
        });

    if (!this.solicitud_prellenada) {
      this.documentos_necesarios -= 1;
    }
  }
  private _actualizarDocumentos(original: any[], nuevos: any[]) {

    for (const documento of original) {
      const actualizado = nuevos.find(
        (doc: any) => doc.tipo_documento === documento.tipo_documento
      );
      if (actualizado) {
        if (actualizado.tipo_documento == 11 || actualizado.tipo_documento == 12) {
          //console.log("--.-.-.-.-..-.-",actualizado)

        }
        documento.comentarios = actualizado.comentarios || documento.comentarios;
        documento.status = actualizado.status || documento.status;
        documento.url = actualizado.url || documento.url;
      }
    }
  }

  /* Acciones para el pdf */

  descargarDocumento(): void {
    if(this.urlPrecente){
      const documento: any = this.url;
      const enlace = document.createElement('a');
      enlace.href = documento.url;
      enlace.download = '';
      enlace.click();
    }else{
      this.dialog.open(MessageBoxComponent, {
        width: "500px",
        data: {
          text: "No se han incluido documentos para su descarga.",
          type: "error",
          icon: "error",
        },
      });
    }
  }

  async descargarZip() {
    try {
      // Obtener los documentos
      this.documentos = this.doc.documentos;

      // Verificar que this.documentos sea un objeto con arrays
      if (typeof this.documentos !== 'object' || !Object.values(this.documentos).every(Array.isArray)) {
        console.error("Los documentos no están en el formato esperado (debe ser un objeto de arrays).");
        return;
      }

      const zip = new JSZip();

      // Iterar sobre los arrays de documentos y agregar los archivos al ZIP
      for (const docs of Object.values(this.documentos)) {
        for (const doc of docs) {
          if (doc.url) {
            try {
              const response = await fetch(doc.url);

              // Verificar si la respuesta fue exitosa
              if (!response.ok) {
                console.warn(`No se pudo descargar el archivo: ${doc.nombre_documento}`);
                continue;
              }

              // Obtener el archivo como un blob (aunque no los manipules antes de guardarlos)
              const blob = await response.blob();

              // Agregar el archivo al archivo ZIP con un nombre limpio y con extensión PDF
              const nombreArchivo = `${doc.nombre_documento.replace(/[^a-zA-Z0-9]/g, "_")}.pdf`;
              zip.file(nombreArchivo, blob);
            } catch (error) {
              console.error(`Error al procesar el documento: ${doc.nombre_documento}`, error);
            }
          } else {
            console.warn(`El documento ${doc.nombre_documento} no tiene URL disponible.`);
          }
        }
      }

      if (zip.files.length) {
        zip.generateAsync({ type: "blob" })
          .then((content) => {
            saveAs(content, "documentos.zip");
          })
          .catch((error) => {
            console.error("Error al generar el archivo ZIP", error);
          });
      } else {
        console.warn("No se han agregado archivos al ZIP.");
      }

    } catch (error) {
      console.error("Error al descargar los documentos", error);
    }
  }

  
  CompletarExpediente() {
    this.DocumentosCompletos()
    console.log('**--completado--+* ::',this.completado )
    if (!this.userService.alertService.isOnline()) return;
   
    if(this.perfil==15){
      if(this.completado){
        this.userService.changeStatus(this.folio, 2, 3).subscribe((res) => {
          // dejamos el estatus del proceso Confirmación con su estado actual
          this.userService
            .changeStatus(this.folio, 3, 0)
            .subscribe((res) => null);
          this.userService
            .DeleteProcessComite({
              folio: this.folio,
              id_cliente: this.id_cliente,
            })
            .subscribe(
              (res2: any) => {
                const dialogref = this.dialog.open(MessageBoxComponent, {
                  width: "500px",
                  data: {
                    text: "Éxito",
                    text3: "Se ha completado el expediente",
                    icon: "success",
                    type: "success",
                  },
                });
                dialogref
                  .afterClosed()
                  .subscribe((_) =>{
                    this.salir()
                    this.router.navigate(["dashboard/solicitudes"])
                  });
              },
              (err2: any) => {
                console.error("err2 :", err2);
                this.dialog.open(MessageBoxComponent, {
                  width: "500px",
                  data: {
                    text: "El servicio no está disponible por el momento",
                    text3: "Vuelve a intentarlo.",
                    type: "error",
                    icon: "error",
                  },
                });
              }
            );
        });
      }else{
        this.dialog.open(MessageBoxComponent, {
          width: "500px",
          data: {
            text: "El expediente se encuentra incompleto o carece de validación.",
            text3: "Vuelve a intentarlo.",
            type: "error",
            icon: "error",
          },
        });
      }
    }else{
      const dialogref = this.dialog.open(MessageBoxComponent, {
        width: "500px",
        data: {
          text: "Éxito",
          icon: "success",
          type: "success",
        },
      }).afterClosed().subscribe(()=>this.salir());
    }
  }







}
