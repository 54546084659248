<div
  mat-dialog-content
  class="container"
  style="
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: 585px;
    overflow: hidden;
  "
>
  <div>
    <div class="img_icon_msg">
      <img [src]="data.icon_url" />
    </div>
  </div>
  <div class="new-mat-dialog">
    <mat-dialog-content style="overflow: hidden; height: 100%;">
      <div class="title">
        <h4>{{ data.text1 }}</h4>
        <div class="container-select">
          <p>Nombre activo</p>
          <div>
            <input style="width: 97%; height: 83%;" type="text" [(ngModel)]="nombre_activo">
          </div>
          <p>{{ data.text2 }}</p>
          <select [(ngModel)]="condicion_activo_select">
            <option *ngFor="let activo of data.option2" [value]="activo">
              {{ activo }}
            </option>
          </select>
          <p>{{ data.text3 }}</p>
          <select [(ngModel)]="vendedor_nuevo_select">
            <option *ngFor="let vendedor of data.option3" [value]="vendedor">
              {{ vendedor }}
            </option>
          </select>
          <p>{{data.text5}}</p>
          <select [(ngModel)]="version_select"  (change)="checkVersion($event.target.value)">
            <option *ngFor="let v of data.option5" [value]="v">{{ v }}</option>
          </select>
          <p>{{data.text4}}</p>
          <select [(ngModel)]="plazo_select">
            <option *ngFor="let plazo of data.option4" [value]="plazo">
              {{ plazo }}
            </option>
          </select>
          <p>Condiciones de pago</p>
            <select [(ngModel)]="condiciones_pago_select"> 
              <option value="48 hrs"> 48 hrs</option>
              <option value="Contra engrega"> Contra entrega</option>
              <option value="Pendiente"> Pendiente</option>
            </select>
            <!-- <input style="width: 97%; height: 83%;" type="text" [(ngModel)]="condiciones_pago_select"> -->
         
          <p>Promoción</p>
          <div>
            <input style="width: 97%; height: 83%;" type="text" [(ngModel)]="promocion_select">
          </div>
          
        </div>
      </div>
      <div *ngIf="errorMsg" class="error-msg">Por favor ingresa todos los datos</div>
      
    </mat-dialog-content>
    <mat-dialog-actions class="footer-btn">
      <button (click)="aceptar()">
        <mat-spinner *ngIf="loading" diameter="20"></mat-spinner>
        <img *ngIf="!loading"
          src="https://leaseforu-assets.s3.us-east-2.amazonaws.com/assets/images/check-green.svg"
        />Aceptar
      </button>
      <button (click)="cerrar()">
        <img
          src="https://leaseforu-assets.s3.us-east-2.amazonaws.com/assets/images/uncheck-red.svg"
        />Cancelar
      </button>
    </mat-dialog-actions>
  </div>
</div>
