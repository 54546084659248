import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { from } from 'rxjs';
import { timeout } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })

export class BuroService {
    httpHeaders: any;
    estados = [
        { id: "CDMX", valor: "Ciudad de México", estado: "Ciudad de México" },
        { id: "AGS", valor: "Aguascalientes", estado: "Aguascalientes" },
        { id: "BCN", valor: "Baja California", estado: "Baja California" },
        { id: "BCS", valor: "Baja California Sur", estado: "Baja California Sur" },
        { id: "CAM", valor: "Campeche", estado: "Campeche" },
        { id: "CHS", valor: "Chiapas", estado: "Chiapas" },
        { id: "CHI", valor: "Chihuahua", estado: "Chihuahua" },
        { id: "COA", valor: "Coahuila", estado: "Coahuila de Zaragoza" },
        { id: "COL", valor: "Colima", estado: "Colima" },
        { id: "DGO", valor: "Durango", estado: "Durango" },
        { id: "EM", valor: "Estado de México", estado: "México" },
        { id: "GTO", valor: "Guanajuato", estado: "Guanajuato" },
        { id: "GRO", valor: "Guerrero", estado: "Guerrero" },
        { id: "HGO", valor: "Hidalgo", estado: "Hidalgo" },
        { id: "JAL", valor: "Jalisco", estado: "Jalisco" },
        { id: "MICH", valor: "Michoacán", estado: "Michoacán de Ocampo" },
        { id: "MOR", valor: "Morelos", estado: "Morelos" },
        { id: "NAY", valor: "Nayarit", estado: "Nayarit" },
        { id: "NL", valor: "Nuevo León", estado: "Nuevo León" },
        { id: "OAX", valor: "Oaxaca", estado: "Oaxaca" },
        { id: "PUE", valor: "Puebla", estado: "Puebla" },
        { id: "QRO", valor: "Querétaro", estado: "Querétaro" },
        { id: "QR", valor: "Quintana Roo", estado: "Quintana Roo" },
        { id: "SLP", valor: "San Luis Potosí", estado: "San Luis Potosí" },
        { id: "SIN", valor: "Sinaloa", estado: "Sinaloa" },
        { id: "SON", valor: "Sonora", estado: "Sonora" },
        { id: "TAB", valor: "Tabasco", estado: "Tabasco" },
        { id: "TAM", valor: "Tamaulipas", estado: "Tamaulipas" },
        { id: "TLAX", valor: "Tlaxcala", estado: "Tlaxcala" },
        { id: "VER", valor: "Veracruz", estado: "Veracruz de Ignacio de la Llave" },
        { id: "YUC", valor: "Yucatán", estado: "Yucatán" },
        { id: "ZAC", valor: "Zacatecas", estado: "Zacatecas" },
      ];
    constructor(
        private http: HttpClient
    ) {
        const currentUser = localStorage.getItem('currentUser')
        const { token } = currentUser ? JSON.parse( currentUser ) : { token: "test"}

		this.httpHeaders = {
            headers: new HttpHeaders({
				'Content-Type': 'application/json',
				'Authorization': token,
            })
        };
    }

    async consultaBuro(data: any) {
        console.log("DATA:", data);

        return from(
            this.http.post(`${environment.config.apiUrl}consultaBuro`, data, this.httpHeaders)
            .toPromise().then((res: any) => {
                    console.log('Response', res);
                    return res;
                }
            ).catch((e) => {
                console.log('Error: ----- ', e);
                return e;
            })
        );
    }

    async consultaBuroPM(data: any) {
        console.log("DATA PM:", data);

        return from(
            this.http.post(`${environment.config.apiUrl}consultaBuroPM`, data, this.httpHeaders)
            .toPromise().then((res: any) => {
                    console.log('Response', res);
                    return res;
                }
            ).catch((e) => {
                console.log('Error: ----- ', e);
                return e;
            })
        );
    }

    async buscarEstado(cp: string) {
        return await this.http.post(`${environment.config.apiUrl}info_cp`, {cp}).toPromise()
    }

    async consultaReporteBC(fecha_inicio: any = null, fecha_final: any = null) {
        const data: any = {};
      
        if (fecha_inicio !== null) {
          data.fecha_inicio = fecha_inicio;
        }
      
        if (fecha_final !== null) {
          data.fecha_final = fecha_final;
        }
      
        try {
          const respuesta: any = await this.http.post(`${environment.config.apiUrl}reporte_buro`, data, this.httpHeaders)
            .toPromise();
      
          console.log('Response', respuesta);
          return respuesta;
        } catch (error) {
          console.log('Error: ----- ', error);
          throw error;
        }
      }
      

    async sendPinPhone(phone){
        return await this.http.post(`${environment.config.apiUrl}sendPINcode`, phone).toPromise()
    }

    async consultaBuroConAutorizacion(data: any) {
        console.log("DATA:", data);

        return from(
            this.http.post(`${environment.config.apiUrl}consulta_buro`, data, this.httpHeaders)
            .toPromise().then((res: any) => {
                    console.log('Response', res);
                    return res;
                }
            ).catch((e) => {
                console.log('Error: ----- ', e);
                return e;
            })
        );
    }

    async DescargarPDF(data) {
        return this.http.post(`${environment.config.apiUrl}buro/consulta`, data, this.httpHeaders).toPromise()
    }
    
    async DescargarPDFAcuseBuro(data) {
        return this.http.post(`${environment.config.apiUrl}buro/acuse_buro`, data, this.httpHeaders).toPromise()
    }

    async ObtenerReporteBuroArcus() {
        try {
            const response = await this.http.post(`${environment.config.apiUrl}arcus/reporte_buro_pm`, {}, this.httpHeaders)
                .pipe(
                    timeout(180000) 
                )
                .toPromise();
    
            return response;
        } catch (error) {
            // Manejo de errores, por ejemplo:
            if (error.name === 'TimeoutError') {
                console.error('La solicitud ha excedido el tiempo de espera.');
            } else {
                console.error('Error en la solicitud:', error);
            }
    
            throw error;
        }
    }

    async ClientePrecalificado(data){
        return this.http.post(`${environment.config.apiUrl}cliente_precalificado`, data, this.httpHeaders).toPromise()
    }

    async ReporteBCCliente(data){
        return this.http.post(`${environment.config.apiUrl}reporte_bc_cliente/obtener`, data, this.httpHeaders).toPromise()

    }

    obtenerCodigoEstado(estado) {
        console.log(`buscar estado: ${estado}`);
        
        return this.estados.filter( elemento => elemento.valor.toLowerCase() == estado.toLowerCase())[0]
    }
}