import { Component, OnInit } from '@angular/core';
import { AuthenticationService, UserService } from 'src/app/_services';
import { Router } from '@angular/router';
import { perfilDirectorGeneral, perfilDirectorComercial, perfilGerenteComercial } from './opciones/opciones';
import { PerfilService } from 'src/app/_services/perfil.service';

@Component({
  selector: 'app-reasignacion',
  templateUrl: './reasignacion.component.html',
  styleUrls: ['./reasignacion.component.scss']
})
export class ReasignacionComponent implements OnInit {
  user: any;
  perfil: any;
  opciones = [];

  constructor(
	private authenticationService: AuthenticationService,
	private router: Router,
  private perfilService: PerfilService,
  private userService: UserService
  ) { }

  async ngOnInit(): Promise<void> {
   this.user = JSON.parse(localStorage.getItem('currentUser'));
   this.perfil = Number(this.user.perfil);
   if (this.perfil === 6 ) {
      this.opciones = perfilDirectorGeneral;
    } else if (this.perfil === 12 ) {
      this.opciones = perfilDirectorComercial;
    } else if (this.perfil === 8 || this.perfil === 25 || this.perfil === 28) {
      this.opciones = perfilGerenteComercial;
   }

   console.log(this.user);
   const users = await this.perfilService.getUsuarios(Number(this.perfil), this.user.id);
   console.log(users);

  }

  Selected( acro ){
    if( !this.userService.alertService.isOnline() ) return
    this.router.navigate(['/reasignar/select', acro])
  }

  logout() {
    this.authenticationService.logout();
    this.router.navigate(['/login']);
  }

}
