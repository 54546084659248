import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { MessageBoxComponent } from "src/app/_dialogs/message-box/message-box.component";
import { BuroService } from "src/app/_services";

@Component({
  selector: "app-nip-buro",
  templateUrl: "./nip-buro.component.html",
  styleUrls: ["./../../consulta-buro.component.scss"],
})
export class NipBuroComponent implements OnInit {
  @Input() procesos;
  @Input() formData;
  @Output() buroResponse = new EventEmitter();
  @Output() onBack = new EventEmitter();

  codeForm: FormGroup;
  signForm: FormGroup;

  public code = "";
  public codeValido = "";
  public isValid = false;
  public invalidCode = false;
  submitted = false;
  loading = false;
  counter: number = 10;
  timer: any;
  formattedCounter: string;
  disabledButton = true;

  type = 1
  fechaHoy = new Date()

  checkAuthorization = false;

  constructor(
    private formBuilder: FormBuilder,
    private buroService: BuroService,
    private dialog: MatDialog,

  ) {}

  get fc() {
    return this.codeForm.controls;
  }

  ngOnInit(): void {
    this.codeForm = this.formBuilder.group({
      checkAcept: [false, Validators.requiredTrue],
      codeNIP: ["", [Validators.required, Validators.minLength(4)]],
    });

    this.signForm = this.formBuilder.group({
      checkAcept: [false, Validators.requiredTrue],
    });
    // this.sendPin();
  }

  public onFullFill(data: any) {
    if (data.length === 4) {
      this.code = data;
      this.isValid = true;
      this.invalidCode = false;
      console.log(typeof this.code, this.isValid, this.invalidCode);
      this.formData.Authorization.code = this.code;
      this.fc.codeNIP.patchValue(this.code);
      console.log(this.formData.Authorization.code);
    }
  }

  async sendPin() {
    const phone = {
      phone: this.formData.Authorization.target,
    };
    this.disabledButton = true;

    try {
      const response = await this.buroService.sendPinPhone(phone);
      console.log(response);
    } catch (err) {
      console.log(err);
    }
    this.startTimer()
  }

  async onSubmit() {
    this.loading = true
    this.submitted = true;
    if ((this.type == 2 && this.codeForm.invalid) || 
        (this.type == 1 && this.signForm.invalid)) {
      console.log(this.type == 1 ? this.signForm : this.codeForm);
      console.log("invalido ", this.submitted);
      return;
    } else {
      if (this.camposInvalidos()) return;
      this.formData.Authorization.code = this.code;
      const tipo_persona = this.formData.tipo_persona;
      if( tipo_persona != 2 ){
        if (this.type == 1) {
          (await this.buroService.consultaBuro(this.formData)).subscribe((res) => this.ConsultaResponse(res))
        } else {
          (await this.buroService.consultaBuroConAutorizacion(this.formData)).subscribe((res) => this.ConsultaResponse(res))
        }
      }else{
        (await this.buroService.consultaBuroPM(this.formData)).subscribe(
          async (res: any) => {
            console.log(res);
            this.loading = false;
            if (res.score) {
              this.buroResponse.emit(res);
              this.procesos[2].status = 1;
            } else if (res.error.message === 'La última consula a buró fue hace menos de 90 días.') {
              this.buroResponse.emit({ score: 'menos 90 dias' });
              this.procesos[2].status = 1;
            } else if (res.error.message === 'El servicio no está disponible por el momento') {
              this.dialog.open(MessageBoxComponent, {
                width: "500px",
                data: {
                  text2: "Error al consultar buró",
                  icon: "error",
                  type: "error"
                }
              })
            } else {
              this.buroResponse.emit({ score: '' });
              this.procesos[2].status = 1;
            }
          }
        );
      }
    }
  }
  camposInvalidos() {
    return false;
  }

  back() {
    this.onBack.emit(true);
  }

  startTimer() {
    clearInterval(this.timer);
    this.counter = 20; 
    this.disabledButton = true; 
    this.formatCounter(); 
  
    this.timer = setInterval(() => {
      this.counter--;
      if (this.counter === 0) {
        this.stopTimer();
        this.disabledButton = false;
      }
      this.formatCounter();
    }, 1000);
  }

  formatCounter() {
    const minutes = Math.floor(this.counter / 60);
    const seconds = this.counter % 60;
    this.formattedCounter = `${this.padNumber(minutes)}:${this.padNumber(
      seconds
    )}`;
  }

  padNumber(num: number): string {
    return num.toString().padStart(2, "0");
  }
  stopTimer() {
    clearInterval(this.timer);
    this.disabledButton = true;
  }

  ConsultaResponse(res: any){
      console.log(res);
      this.loading = false;
     if(res.status != 403){
       if (res.score) {
        this.buroResponse.emit(res);
        this.procesos[3].status = 1;
      } else if (res.error.message === 'La última consula a buró fue hace menos de 90 días.') {
        this.buroResponse.emit({ score: 'menos 90 dias' });
        this.procesos[3].status = 1;
      } else if (res.error.message === 'El servicio no está disponible por el momento') {
        this.dialog.open(MessageBoxComponent, {
          width: "500px",
          data: {
            text2: "Error al consultar buró",
            icon: "error",
            type: "error"
          }
        })
      } else {
        this.buroResponse.emit({ score: '' });
        this.procesos[3].status = 1;
      }}else{
        this.dialog.open(MessageBoxComponent, {
          width: "500px",
          data: {
            text2: "Revisa el NIP ingresado y vuelve a intentarlo",
            icon: "error",
            type: "error"
          }
        })
      }
    }
}
