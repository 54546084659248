import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { SolicitudService, UserService } from 'src/app/_services';
import { NuevaSolicitudService } from 'src/app/_services/nueva_solicitud.service';
//import { GoogleMap } from '@angular/google-maps';
import { MatDialog } from '@angular/material/dialog';
import { GoogleMapsComponent } from 'src/app/_components/google-maps/google-maps.component';
import { ValidacionCamposOcrComponent } from 'src/app/_components/validacion-campos-ocr/validacion-campos-ocr.component';
import { MessageBoxComponent } from 'src/app/_dialogs/message-box/message-box.component';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { RelacionPatrimonialComponent } from 'src/app/_components/relacion-patrimonial/relacion-patrimonial.component';
import { Console } from 'console';


@Component({
  selector: 'app-ocr-solicitud',
  templateUrl: './ocr-solicitud.component.html',
  styleUrls: ['./ocr-solicitud.component.scss']
})
export class OcrSolicitudComponent implements OnInit {

  currentStep = 1

  data_get_document: any = {};
  perfil = 0;
  id_cliente = null;
  folio = "";
  tipo_persona = 1;
  tipo_aval = 1;
  loading = false;
  $loading = null;
  data_aval = {};
  id_usuario = null;
  flujo_cotizador = false;
  isUploading=false;

  rfcAval:string=null;

  tipoDocumento: number = 0;
  omitirAval:boolean=false;


  Documentos = {
    DocumentosSolicitante: [],
    DocumentoComprobanteDomicilio: [],
    DocumentosSolicitanteLegal: [],
    DocumentosAval: [],
    DocumentosAvalDomicilio:[],
    DocumentosAvalLegal: [],
    
  };
  documentos_necesarios = 0;
  faltanDocumentos = false;
  DocumentosValidar = [];
  validandoDocumentos = false;
  cancelDoc = false;
  completado = false;
  solicitud_prellenada = false;
  formData: FormData;
  formDataAval: FormData;
  documentos_estados_cuenta = [];
  geoLocalizacion: boolean = false;
  geoLocalizacionActivo: boolean = false;
  geoLocalizacionAval:boolean=false;


  allowed_type = "application/pdf";

  constructor(
    private nuevaSolicitudService: NuevaSolicitudService,
    private userService: UserService,
    private _snackBar: MatSnackBar,
    private route: ActivatedRoute,
    public dialog: MatDialog,
    private solicitudService: SolicitudService,
    private cdr: ChangeDetectorRef,
    private router: Router
  ) { }

  async ngOnInit() {

    
    const clientDetails = JSON.parse(localStorage.getItem('client_details') || null);
    this.perfil = JSON.parse(localStorage.getItem('currentUser')).perfil
    this.id_cliente = clientDetails?.id_cliente ?? JSON.parse(localStorage.getItem('current_id_cliente')); 

    console.log('**1**',this.id_cliente)



    /* const storedFolio = localStorage.getItem('folio_solicitud');
    this.folio = this.folio || storedFolio; */

   
    this.route.queryParams.subscribe(params => {
      this.flujo_cotizador = params['flujo_cotizador']
   
    });

    

    // Obtener el folio desde la URL
    if (this.flujo_cotizador) {
      this.route.queryParams.subscribe(params => {
        this.folio = params['folio'];
      //console.log('Folio obtenido:', this.folio)      
        if (this.folio) {
          localStorage.setItem('folio_solicitud', this.folio);
          //console.log('Folio guardado en localStorage:', this.folio);
        }
      });
    }else{
      this.folio = clientDetails?.folio ?? null;
    }

    const parsedUser = JSON.parse(localStorage.getItem('currentUser'));
    this.id_usuario = parsedUser?.id ?? null;
    // this.folio = this.data.folio
    this.data_get_document =
      this.tipo_persona == 1
        ? {
          folio: this.folio.toString(),
          id_cliente: this.id_cliente,
          tipo_persona: this.tipo_persona,
          tipo_aval: this.tipo_aval,
          empleado: false,
          nuevo_flujo: true,
        }
        : {
          folio: this.folio,
          tipo_persona: this.tipo_persona,
          tipo_aval: this.tipo_aval,
        };
    this.ObtenerStatus();
    const res = await this.datosDynamoDB();
    if(res?.aval?.omitir){
      this.omitirAval= res.aval.omitir
    }

  }

  async ObtenerStatus() {
   this.getIdUser();
    

    this.data_get_document.id_cliente = this.id_cliente;

    try {
      const res = await this.userService
        .ObtenerStatusDocuments(this.data_get_document)
        .toPromise();

      this.Documentos = this.getDocumentosData();

      this._actualizarDocumentos(
        this.Documentos.DocumentosSolicitante,
        res.DocumentosSolicitante
      );
      this._actualizarDocumentos(
        this.Documentos.DocumentoComprobanteDomicilio,
        res.DocumentosSolicitante
      );

      // Actualizar los datos de los documentos del aval
      this._actualizarDocumentos(
        this.Documentos.DocumentosAval,
        res.DocumentosAval
      );

      this._actualizarDocumentos(
        this.Documentos.DocumentosAvalDomicilio,
        res.DocumentosAval
      );

      this.documentos_necesarios =
        this.Documentos.DocumentosSolicitante.length +
        this.Documentos.DocumentosAval.length;
      this.documentos_estados_cuenta =
        this.Documentos.DocumentosSolicitante.filter((documento) =>
          [4, 17, 18].includes(documento.tipo_documento)
        );

      if (Number(this.perfil) === 4) {
        const result1 = this.Documentos.DocumentosSolicitante.filter(
          (doc: any) => {
            return doc.status === 2;
          }
        );

        const result2 = this.Documentos.DocumentosAval.filter(
          (doc: any) => {
            return doc.status === 2;
          }
        );
        this.cancelDoc = result1.length + result2.length > 0;
      }

      this.faltanDocumentos = false;
      this._hayDocumentosSinCargar(this.Documentos.DocumentosSolicitante);
      this._hayDocumentosSinCargar(this.Documentos.DocumentosAval);
      this.DocumentosCompletos();
    } catch (error) {
      console.error('Error al obtener los documentos:', error);
      this._snackBar.open(
        "Ha ocurrido un error, por favor vuelve a intentarlo. ",
        "Ok",
        {
          duration: 10000,
          horizontalPosition: "center",
          verticalPosition: "top",
          panelClass: ["warning-snackbar"],
        }
      );
    }
  }

  private _hayDocumentosSinCargar(documentos) {
    if (!this.faltanDocumentos) {
      for (const documento of documentos) {
        if (documento.tipo_documento != 21 && documento.status == 0) {
          this.faltanDocumentos = true;
          break;
        }
      }
    }
  }
  DocumentosCompletos() {
    if (!this.userService.alertService.isOnline()) return;
    this.completado = true;
    let status = 0;

    this.Documentos.DocumentosSolicitante.forEach((documento) => {
      if (documento.requerido == true && documento.status != 3) {
        this.completado = false;
      }
      this.solicitud_prellenada =
        documento.tipo_documento == 21 && documento.url.length > 0;
    });
    this.Documentos.DocumentosAval.forEach((documento) => {
      if (documento.requerido == true && documento.status != 3) {
        this.completado = false;
      }
    });

    if (this.Documentos.DocumentosSolicitanteLegal)
      this.Documentos.DocumentosSolicitanteLegal.forEach((documento) => {
        if (documento.requerido == true && documento.status != 3) {
          this.completado = false;
        }
        this.solicitud_prellenada =
          documento.tipo_documento == 21 && documento.url.length > 0;
      });
    if (this.Documentos.DocumentosAvalLegal)
      this.Documentos.DocumentosAvalLegal.forEach((documento) => {
        if (documento.requerido == true && documento.status != 3) {
          this.completado = false;
        }
      });

    if (!this.solicitud_prellenada) {
      this.documentos_necesarios -= 1;
    }
  }
  SeleccionarDocumento(doc, solicitante) {
    // if (!this.userService.alertService.isOnline()) return;
    // const doc = (solicitante) ? this.Documentos.DocumentosSolicitante[idx] : this.Documentos.DocumentosAval[idx]

    console.log("folio : ", this.folio)
    console.log("Cliente : ", this.id_cliente.toString())
    console.log("Tipo de documento : ", doc.tipo_documento)
    console.log("Solicitante", solicitante)
    console.log("ID Cliente : ", this.id_cliente.toString())
    console.log("validacion : ", (doc.status == 3 || doc.status == 1))

    this.tipoDocumento = doc.tipo_documento;
    if (doc.status == 3 || doc.status == 1) return;

    solicitante = true
    this.formData = new FormData();
    this.formData.append("folio", this.folio);
    this.formData.append("id_cliente", this.id_cliente.toString());
    this.formData.append("tipo_documento", doc.tipo_documento);
    this.formData.append("solicitante", solicitante);
    this.formData.append("id_usuario_mod", this.id_cliente.toString());
    this.formData.append("type", doc.tipo_documento);
    this.formData.append("nombre", doc.tipo_documento==3? "ine_1":"constancia_1");

    const $document: HTMLInputElement = document.querySelector("#document");
     $document.value = "";

    let changeDetected = false;

    $document.addEventListener("change", (event: Event) => {
        const input = event.target as HTMLInputElement;
        if (input.files && input.files.length > 0) {
            console.log("Archivo seleccionado:");
            changeDetected = true; 
        }
    }, { once: true }); // Se ejecuta solo una vez para evitar múltiples llamadas
    $document.click();

    // Verificar después de un tiempo si el evento `change` no ocurrió
    setTimeout(() => {
        if (!changeDetected) {
            console.log("El usuario canceló la selección del archivo.");
            this.ObtenerStatus();
        }
    }, 10000); 

  }
  SeleccionarDocumentoAval(doc, solicitante) {
    // if (!this.userService.alertService.isOnline()) return;
    // const doc = (solicitante) ? this.Documentos.DocumentosSolicitante[idx] : this.Documentos.DocumentosAval[idx]
    console.log("::::AVAL::::")
    console.log("folio : ", this.folio)
    console.log("Cliente : ", this.id_cliente.toString())
    console.log("Tipo de documento : ", doc.tipo_documento)
    console.log("Solicitante", false)
    console.log("ID Cliente : ", this.id_cliente.toString())
    console.log("validacion : ", (doc.status == 3 || doc.status == 1))

    this.tipoDocumento = doc.tipo_documento;
    if (doc.status == 3 || doc.status == 1) return;

    solicitante = false
    this.formDataAval = new FormData();
    this.formDataAval.append("folio", this.folio);
    this.formDataAval.append("id_cliente", this.id_cliente.toString());
    this.formDataAval.append("tipo_documento", doc.tipo_documento);
    this.formDataAval.append("solicitante", solicitante);
    this.formDataAval.append("id_usuario_mod", this.id_cliente.toString() ?? this.id_usuario);
    this.formDataAval.append("type", doc.tipo_documento);
    this.formDataAval.append("nombre", "ine_aval");


    const $document: any = document.querySelector("#documentaval");
    $document.value = "";

    let changeDetected = false;

    $document.addEventListener("change", (event: Event) => {
        const input = event.target as HTMLInputElement;
        if (input.files && input.files.length > 0) {
            console.log("Archivo seleccionado:");
            changeDetected = true; 
        }
    }, { once: true }); // Se ejecuta solo una vez para evitar múltiples llamadas
    $document.click();

    // Verificar después de un tiempo si el evento `change` no ocurrió
    setTimeout(() => {
        if (!changeDetected) {
            console.log("El usuario canceló la selección del archivo.");
            this.ObtenerStatus();
        }
    }, 10000); 
   
  }

  async SubirDocumentoAval({ target: { files } }) {

    // if (!this.userService.alertService.isOnline()) return;
    const file = files[0];

    if (file) {
      this.formDataAval.append("document", file);

      if (this.tipoDocumento == 9 ) {
        // Ejecutar el proceso Textract
        const statusTextract = await this.textractAval();

        if (statusTextract?.statusCode === 400) {
          this.dialog.open(MessageBoxComponent, {
            width: "500px",
            data: {
              text: "Error",
              text3: statusTextract.message,
              icon: "error",
              type: "error",
            },
          });
          this.isUploading=false;
          this.cdr.detectChanges();
          return;
        }

        if (statusTextract?.statusCode === 500) {
          this.isUploading=false;
          this.cdr.detectChanges();
          return;
        }
      }

      this.userService.SubirDocumento(this.formDataAval).subscribe(
        (res) => {
          const $document = document.querySelector("#documentaval");
          this._snackBar.open(
            "Se ha cargado correctamente el documento",
            "Ok",
            {
              duration: 5000,
              horizontalPosition: "center",
              verticalPosition: "top",
              panelClass: ["success-snackbar"],
            }
          );
          // this.userService.changeStatus(this.folio, 2, 1) // ya se realiza desde el servicio
          this.ObtenerStatus();
        },
        (err) => {
          this._snackBar.open(
            "Ha ocurrido un error, por favor vuelve a intentarlo. ",
            "Ok",
            {
              duration: 10000,
              horizontalPosition: "center",
              verticalPosition: "top",
              panelClass: ["warning-snackbar"],
            }
          );
          this.ObtenerStatus();
        }
      );
    }
  }
  async SubirDocumento({ target: { files } }) {
    const file = files[0];

    if (file) {
      this.formData.append("document", file);

      if (this.tipoDocumento == 3 || this.tipoDocumento == 5) {
        // Ejecutar el proceso Textract
        const statusTextract = await this.textract();

        if (statusTextract?.statusCode === 400) {
          this.dialog.open(MessageBoxComponent, {
            width: "500px",
            data: {
              text: "Error",
              text3: statusTextract.message,
              icon: "error",
              type: "error",
            },
          });
          this.isUploading=false;
          this.cdr.detectChanges();
          return;
        }

        if (statusTextract?.statusCode === 500) {
          this.dialog.open(MessageBoxComponent, {
            width: "500px",
            data: {
              text: "Error",
              text3: "El documento no es legible. Intente con otro archivo.",
              icon: "error",
              type: "error",
            },
          });
          this.isUploading=false;
          this.cdr.detectChanges();
          return;
        }
        if (statusTextract?.statusCode === 200) {
          if(this.tipoDocumento == 3){
            await this.cambioStatus();
          }
        }

      }


      this.userService.SubirDocumento(this.formData).subscribe(
        (res) => {
          this._snackBar.open(
            "Se ha cargado correctamente el documento",
            "Ok",
            {
              duration: 5000,
              horizontalPosition: "center",
              verticalPosition: "top",
              panelClass: ["success-snackbar"],
            }
          );
          this.ObtenerStatus();
        },
        (err) => {
          this._snackBar.open(
            "Ha ocurrido un error, por favor vuelve a intentarlo.",
            "Ok",
            {
              duration: 10000,
              horizontalPosition: "center",
              verticalPosition: "top",
              panelClass: ["warning-snackbar"],
            }
          );
        }
      );
    }
  }
 /* TESTRACT***************************************************************** */
  async textract(): Promise<{ statusCode: number; message: string } | null> {
    console.log("##########--Textract--##############");

    let res;

    try {

      if(this.tipoDocumento == 3){
        res = await this.userService.SubirTextract(this.formData);
      }
      
      if(this.tipoDocumento == 5){
        res = await this.userService.SubirTextractOCR(this.formData);

        console.log("Respuesta de constancia ...", res);
      }


      if (res.statusCode === 200) {
        return {
          statusCode: 200,
          message: res.message || "Documento cargado y procesado con éxito",
        };
      }

      if (res.statusCode === 400) {
        return {
          statusCode: 400,
          message: res.message || "El documento no contiene suficiente información válida. Por favor, intente subir otro documento.",
        };
      }

      if (res.statusCode === 500) {
        this.dialog.open(MessageBoxComponent, {
          width: "500px",
          data: {
            text: "Error",
            text3: "No se pudo guardar la información.",
            icon: "error",
            type: "error",
          },
        });
        return {
          statusCode: 500,
          message: "No se pudo guardar la información.",
        };
      }
      return {
        statusCode: res.statusCode || 500,
        message: res.message || "Error inesperado. Inténtalo más tarde.",
      };

    } catch (error) {

      console.error("Error en el proceso Textract:", error);

      if (error.status === 400) {
        return {
          statusCode: 400,
          message: "El documento no contiene suficiente información válida. Por favor, intente subir otro documento.",
        };
      }

      return {
        statusCode: 500,
        message: "Error interno del servidor. Inténtalo más tarde.",
      };
    } finally {
      console.log("##########--Textract--##############");
    }
  }

  async textractAval(): Promise<{ statusCode: number; message: string } | null> {
    console.log("##########--Textract--##############");

    try {

      const res = await this.userService.SubirTextract(this.formDataAval);


      if (res.statusCode === 200) {
        return {
          statusCode: 200,
          message: res.message || "Documento cargado y procesado con éxito",
        };
      }

      if (res.statusCode === 400) {
        return {
          statusCode: 400,
          message: res.message || "El documento no contiene suficiente información válida. Por favor, intente subir otro documento.",
        };
      }

      if (res.statusCode === 500) {
        this.dialog.open(MessageBoxComponent, {
          width: "500px",
          data: {
            text: "Error",
            text3: "No se pudo guardar la información.",
            icon: "error",
            type: "error",
          },
        });
        return {
          statusCode: 500,
          message: "No se pudo guardar la información.",
        };
      }
      return {
        statusCode: res.statusCode || 500,
        message: res.message || "Error inesperado. Inténtalo más tarde.",
      };

    } catch (error) {

      console.error("Error en el proceso Textract:", error);

      if (error.status === 400) {
        return {
          statusCode: 400,
          message: "El documento no contiene suficiente información válida. Por favor, intente subir otro documento.",
        };
      }

      return {
        statusCode: 500,
        message: "Error interno del servidor. Inténtalo más tarde.",
      };
    } finally {
      console.log("##########--Textract--##############");
    }
  }

  async cambioStatus(){
    try {        
      let nombres: string='';
      const datosDynamo:any = await this.datosDynamoDB();

      if (datosDynamo?.contacto?.nombreCompleto.length>0) {
        nombres = datosDynamo.contacto.nombreCompleto;
      } else {
        const clientDetails = localStorage.getItem('client_details');
        if (clientDetails) {
          const parsedClientDetails = JSON.parse(clientDetails);
          nombres = parsedClientDetails.cliente_nombre || 'A QUIEN CORRESPONDA';
        } else {
          nombres = 'A QUIEN CORRESPONDA';
        }
      }
      
      const data ={folio:this.folio, id_cliente:this.id_cliente, nombres:nombres}
      await this.solicitudService.GuardarStatusInfoGeneral(data);

      await this.userService.changeStatus(this.folio,1,3).toPromise();
  
    } catch (error) {
      console.error("Error en la lógica de guardado o cambio de estado:", error);
    }
  }
/* TESTRACT***************************************************************** */
  resset = (event) => {
    event.target.value = null;
  };

  VerDocumento(documento, solicitante) {
    localStorage.setItem("documento", JSON.stringify(documento));

    if (documento.url) {
      const url = `#/dashboard/cliente/${this.folio}/ver_documento`;
      window.open(url, "_blank");
    }
  }

  getDocumentosData() {
    return {
      DocumentosSolicitante: [
        {
          tipo_documento: 3,
          nombre_documento: "Identificación oficial",
          descripcioncompleta: "Copia de identificación oficial VIGENTE (INE, Pasaporte)",
          descripcion_corta: "INE o Pasaporte",
          comentarios: "",
          requerido: true,
          status: 0,
          url: ""
        },
        {
          tipo_documento: 5,
          nombre_documento: "Constancia fiscal actual",
          descripcioncompleta: "Constancia de situación fiscal actualizada con antigüedad no mayor a 6 meses.",
          descripcion_corta: "Menor a 3 meses",
          comentarios: "",
          requerido: false,
          status: 0,
          url: ""

        },
        {
          tipo_documento: 4,
          nombre_documento: "Estados de cuenta",
          descripcioncompleta: "Últimos 3 estados de cuenta y recibos de nómina (ambos completos y con cadena digital del SAT)",
          descripcion_corta: "Menor a 3 meses",
          comentarios: "",
          requerido: true,
          status: 0,
          url: ""
        },
        {
          comentarios: "",
          descripcion_corta: "",
          descripcion_completa: "",
          nombre_documento: "Buro PDF",
          requerido: false,
          status: 0,
          tipo_documento: 20,
          url: ""
        },
        {
          tipo_documento: 17,
          nombre_documento: "Estados de cuenta adicional",
          descripcioncompleta: "Últimos 3 estados de cuenta y recibos de nómina (ambos completos y con cadena digital del SAT)",
          descripcion_corta: "Menor a 3 meses",
          comentarios: "",
          requerido: true,
          status: 0,
          url: ""
        },
        {
          tipo_documento: 18,
          nombre_documento: "Estados de cuenta adicional",
          descripcioncompleta: "Últimos 3 estados de cuenta y recibos de nómina (ambos completos y con cadena digital del SAT)",
          descripcion_corta: "Menor a 3 meses",
          comentarios: "",
          requerido: true,
          status: 0,
          url: ""
        },
        {
          comentarios: "",
          descripcion_corta: "Cotización",
          descripcioncompleta: "Cotización creada del vendor",
          nombre_documento: "Cotización vendor",
          requerido: true,
          status: 0,
          tipo_documento: 22,
          url: ""
        }

      ],
      DocumentoComprobanteDomicilio: [
        {
          tipo_documento: 11,
          nombre_documento: "Comprobante de domicilio",
          descripcioncompleta: "Copia del comprobante de de domicilio actual con antigüedad no mayor a 3 meses.",
          descripcion_corta: "Documento personal",
          comentarios: "",
          requerido: false,
          status: 0,
          url: ""
        },
        {
          tipo_documento: 12,
          nombre_documento: "Comprobante de domicilio donde se encontrará el equipo",
          descripcioncompleta: "Comprobante de domicilio donde se encontrará el equipo",
          descripcion_corta: "Donde se encontrará el equipo",
          comentarios: "",
          requerido: false,
          status: 0,
          url: ""
        },
      ],
      DocumentosSolicitanteLegal: [],
      DocumentosAval: [
        {
          tipo_documento: 9,
          nombre_documento: "Identificación oficial del representante legal",
          descripcioncompleta: "Copia de identificación official VIGENTE con fotografía y firma del representante legal (INE, Pasaporte)",
          descripcion_corta: "INE o Passaporte",
          comentarios: "",
          requerido: false,
          status: 0,
          url: ""
        },
        {
          comentarios: "",
          descripcion_corta: "",
          descripcion_completa: "",
          nombre_documento: "Buro PDF",
          requerido: false,
          status: 0,
          tipo_documento: 20,
          url: ""
        }
       /*  {
          tipo_documento: 5,
          nombre_documento: "Constancia fiscal actual",
          descripcioncompleta: "Constancia de situación fiscal actualizada con antigüedad no mayor a 6 meses.",
          descripcion_corta: "Menor a 3 meses",
          comentarios: "",
          requerido: true,
          status: 0,
          url: ""

        }, */
       /*  {
          tipo_documento: 11,
          nombre_documento: "Comprobante de domicilio",
          descripcioncompleta: "Copia del comprobante de de domicilio actual con antigüedad no mayor a 3 meses.",
          descripcion_corta: "Documento personal",
          comentarios: "",
          requerido: true,
          status: 0,
          url: ""
        }, */
      ],
      DocumentosAvalDomicilio: [
        {
          tipo_documento: 11,
          nombre_documento: "Comprobante de domicilio",
          descripcioncompleta: "Copia del comprobante de de domicilio actual con antigüedad no mayor a 3 meses.",
          descripcion_corta: "Documento personal",
          comentarios: "",
          requerido: true,
          status: 0,
          solicitante:false,
          url: ""
        }
      ],
      DocumentosAvalLegal: []
    }
  }
  private _actualizarDocumentos(original: any[], nuevos: any[]) {


    for (const documento of original) {
      const actualizado = nuevos.find(
        (doc: any) => doc.tipo_documento === documento.tipo_documento
      );
      if (actualizado) {
        documento.comentarios = actualizado.comentarios || documento.comentarios;
        documento.status = actualizado.status || documento.status;
        documento.url = actualizado.url || documento.url;
        documento.solicitante =documento?.solicitante;
        if (actualizado.tipo_documento == 11 || actualizado.tipo_documento == 12) {
          this.getDynamoDB(documento);
        }
      }
    }
  }
  async getIdUser() {
    const data = {
      folio: this.folio
    }
    const res: any = await this.nuevaSolicitudService.getIdUser(data)
    this.id_cliente = res.data[0].id_cliente
    localStorage.setItem('current_id_cliente', this.id_cliente)
  }

  openGoogleMapsDialog(documento): void {

    const dialogRef = this.dialog.open(GoogleMapsComponent, {
      data: {
        folio: this.folio,
        tipo_documento: documento.tipo_documento,
        solicitante: documento.solicitante
        

      },
      width: '900px',
      height: '700px'
    });
    dialogRef.afterClosed().subscribe(result => {
      this.ObtenerStatus();
    });
  }

  async getDynamoDB(doc) {
   
    if (this.folio.length > 0) {
      try {
        const res = await this.datosDynamoDB();
       
        if (doc.tipo_documento == 11 ) {

          if(doc?.solicitante==true || doc?.solicitante==undefined){
           
            if (res.direccion && res.direccion.lat && res.direccion.lng) {
              this.geoLocalizacion = true;
              this.cdr.detectChanges();
            }
          }else{
            if (res.aval?.direccion && res.aval.direccion.lat && res.aval.direccion.lng) {
              this.geoLocalizacionAval = true;
              this.cdr.detectChanges();
            }
          }
        }
        if (doc.tipo_documento == 12) {

          if (res.direccionActivo && res.direccionActivo.lat && res.direccionActivo.lng) {
            this.geoLocalizacionActivo = true;
            this.cdr.detectChanges();
      

          }
        } else {
          return
        }
      } catch (error) {
        console.error("Error al obtener de DynamoDB", error);
      }
    } else {
      return
    }

  }
  abrir() {
    const dialogRef = this.dialog.open(ValidacionCamposOcrComponent, {
      data:{
        documentos: this.Documentos,
        idCliente: this.id_cliente,
        omitirAval: this.omitirAval
      },
      width: '1600px',
      height: '800px'
    });
  }
  async siguiente() {

    const docValidar = [3, 4, 17, 18];
    const docAvalValidar=[9];

    for (const tipo of docValidar) {
      if (!this.validarDocumentosIncluidos(this.Documentos.DocumentosSolicitante, tipo)) {
        console.log(` Tipo: ${tipo}`);
        this.dialog.open(MessageBoxComponent, {
          width: "500px",
          data: {
            text: "Es necesario incluir todos los documentos requeridos para continuar con el trámite.",
            icon: "error",
            type: "error",
          },
        });
        return;
      }
    }
    if(!this.omitirAval){
      for (const tipo of docAvalValidar) {
        if (!this.validarDocumentosIncluidos(this.Documentos.DocumentosAval, tipo)) {
          console.log(`Tipo: ${tipo}`);
          this.dialog.open(MessageBoxComponent, {
            width: "500px",
            data: {
              text: "Es necesario incluir todos los documentos requeridos para continuar con el trámite.",
              icon: "error",
              type: "error",
            },
          });
          return;
        }
      }
    }

    //this.guardarRFC_Aval()
    await this.cambioStatus();
    this.router.navigate(["ocr_solicitud/completa"]);
    if (this.folio) {
      localStorage.setItem('folio_solicitud', this.folio);
      //console.log('Folio guardado en localStorage:', this.folio);
    }
  }

  validarDocumentosIncluidos(array: any[], tipo: number): boolean {
    return array.some(doc =>
      doc.tipo_documento === tipo && doc.status !== 0 && doc.url.trim() !== ""
    );
  }

  /* Omitir Aval */
  async onSlideToggleChange(event: MatSlideToggleChange) {
    this.omitirAval=event.checked;
    let aval ={};

    try {
      const res = await this.datosDynamoDB();

      if(res?.aval){
        const aval1:[]=res.aval
        aval ={
          ...aval1,
          omitir :this.omitirAval
        }
      }else{
        aval ={
          omitir :this.omitirAval
        }
      }
      await this.solicitudService.GuardarSolicitud(this.folio, { aval });
      
    } catch (error) {
      console.error(error)
    }

  }

  async datosDynamoDB() {
    try {
      if (!this.folio) {
        console.warn("Folio inválido o no definido.");
        return;
      }
      const res = await this.solicitudService.ObtenerSolicitud(this.folio);
     
      if (!res || Object.keys(res).length === 0) {
        console.warn("No se encontraron datos en DynamoDB.");
        return;
      }
      return res;
    } catch (error) {
      console.error("Error al obtener los datos de DynamoDB:", error);
    }
  }

  relacionPatrimonial(){
    const dialogRef = this.dialog.open(RelacionPatrimonialComponent,{
      data:{
        folio:this.folio
      },
      width: '1000px',
      height: '700px'
    });
  }

  async guardarRFC_Aval(){
    const res = await this.datosDynamoDB(); 

    if (!res) {
      console.warn("No se obtuvieron datos válidos de DynamoDB.");
      return;
    }
  
    const {
      tipoPersona,
      nombreCompleto,
      apellidoPaterno,
      apellidoMaterno,
      nacionalidad,
      fechaNacimiento,
      genero,
      rfc,
      numeroIdentificacion,
      tipoIdentificacion,
      actividadPrincipal,
      regimenContribucion,
      sectorEconomico,
      email,
      telefono,
      direccion,
      direccionFiscal
    } = res?.aval; 

    const aval={
      tipoPersona:tipoPersona || null,
      nombreCompleto:nombreCompleto || null,
      apellidoPaterno:apellidoPaterno || null,
      apellidoMaterno:apellidoMaterno || null, 
      nacionalidad:nacionalidad || null,
      fechaNacimiento:fechaNacimiento || null,
      genero:genero || null,
      rfc:this.rfcAval  || rfc ||null,
      numeroIdentificacion:numeroIdentificacion || null,
      tipoIdentificacion:tipoIdentificacion || null,
      actividadPrincipal:actividadPrincipal || null,
      regimenContribucion:regimenContribucion || null,
      sectorEconomico:sectorEconomico || null,
      email:email || null,
      telefono:telefono || null,
      direccion:direccion || {},
      direccionFiscal:direccionFiscal || {}
    }


    try {
      const response: any = await this.solicitudService.GuardarSolicitud(this.folio, { aval });
    } catch (error) {
      console.error("Error al guardar la solicitud :::::", error);
    }

  }

  recibirRFC(rfc){
   this.rfcAval=rfc;
  }
  
}
