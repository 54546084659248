import { Component, EventEmitter, Inject, Input, OnInit, Output } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { PerfilService } from "src/app/_services/perfil.service";
import { VendorsService } from "src/app/_services/vendors.service";
import { environment } from "src/environments/environment";

@Component({
  selector: "app-new-modal-message",
  templateUrl: "./new-modal-message.component.html",
  styleUrls: ["./new-modal-message.component.scss"],
})
export class NewModalMessageComponent implements OnInit {
  @Output() guardar = new EventEmitter<any>();
  @Input() form = {}
  @Input() loading = false
  vendedor_nuevo_select = "";
  plazo_select = "";
  condiciones_pago_select = "";
  promocion_select = "";
  condicion_activo_select = "";
  nombre_activo = "";
  version_select: number;
  errorMsg = false;

  perfil
  id_usuario
  empresa = ''
  Vendors: any[] = [];
  Vendedores: any[] = [];
  filteredVendors: any[] = []; 
  searchText: string = '';
  seleccion_vendor: any;
  seleccion_vendedor: any;
  id_vendedor
  reasignar: boolean = true;
  reasignar_check: boolean = true;

  constructor(
    public dialogRef: MatDialogRef<NewModalMessageComponent>,
    @Inject(MAT_DIALOG_DATA) public data,
    private perfilService: PerfilService,
     private vendorService: VendorsService,

  ) {}

  async ngOnInit() {
    console.log("data", this.data);
    this.nombre_activo = this.data.descr_activo
    const { id, perfil, perfil_master, id_master } = JSON.parse(localStorage.getItem('currentUser'))
    const vendor_id  = this.data.option7;
    this.Vendedores= this.data.option8;
    this.id_vendedor= this.data.option9;

    await this.BuscarVendors(vendor_id);

   // await vendor_idthis.BuscarVendors(vendor_id);
   if(this.id_vendedor>0 && (vendor_id != this.id_vendedor)){
    await this.asignacionVendedor();
   }
    

    console.log("Vendedores:", this.Vendedores);
    
        this.perfil = Number(perfil)
        this.id_usuario = Number(id)
        this.perfil = Number(perfil)
    
  }

  asignacionVendedor() {
    this.reasignar = false;
    this.reasignar_check = false;
    let valor 
    
    if(this.id_vendedor>0){
     valor = this.Vendedores.filter(vendedor => vendedor.id == this.id_vendedor);
    }
    
  
    if (valor.length > 0) {
      this.seleccion_vendedor = valor[0].id;
      console.log("***** valor", valor[0].id);
    } else {
      this.seleccion_vendedor = null; 
      console.log("No se encontró ningún vendedor con el ID:", this.id_vendedor);
    }
  }

  async BuscarVendors(vendor_id) {
    console.log("Vendor ID:", vendor_id);
    try {

      const resp_vendors = await this.vendorService.ObtenerVendors({
        perfil: this.perfil,
        id_usuario: this.id_usuario,
        country: this.empresa
      }).toPromise();

      console.log("Vendors:", resp_vendors);

      this.Vendors = resp_vendors.data.map(vendor => ({
        id: vendor.id,
        fullname: vendor.fullname,
        user_id: vendor.user_id
      }));

      const vendor = this.Vendors.filter(vendor => vendor.user_id == vendor_id); 
      this.seleccion_vendor = vendor[0].fullname;
    } catch (error) {
      console.error("Error:", error);
    }
  }

  async onVendorChange(event: any) {
    this.seleccion_vendor=null;
    const selectedVendorId = event.target.value; 
    console.log("Vendor seleccionado:", selectedVendorId);
    if(selectedVendorId) {
      this.seleccion_vendor=selectedVendorId;
      try {
       await this.ObtenerVendedores(selectedVendorId); 
      } catch (error) {
        console.error("Error:", error);
      }
    }
  }

  async onVendedorChange(event: any) {

  }

  async ObtenerVendedores(id_vendor: number) {
    try {
      const resp = await this.vendorService.ObtenerVendedores(id_vendor).toPromise()
      this.Vendedores = resp.data.map( vendedor => ({
        id: vendedor.id,
        fullname: vendedor.nombres + " " + vendedor.apellido_parterno        ,
      }));
    console.log("Vendedores:", this.Vendedores);
    } catch (error) { 
      console.error("Error:", error);
    }
  }

  filterVendors() {
    if (this.searchText) {
      this.filteredVendors = this.Vendors.filter(vendor =>
        vendor.fullname.toLowerCase().includes(this.searchText.toLowerCase())
      );
    } else {
      this.filteredVendors = [...this.Vendors]; 
    }
  }

  async aceptar() {
    this.loading = true;
    this.form = {
      folio: this.data.folio,
      is_demo: environment.config.isDemo,
      vendedor_nuevo: this.vendedor_nuevo_select,
      plazo: this.plazo_select,
      condiciones_pago: this.condiciones_pago_select,
      promocion: this.promocion_select,
      condicion_activo: this.condicion_activo_select,
      nombre_activo: this.nombre_activo,
      version: this.version_select,
    };
    console.log("ASIGNACION RESP", this.form);
    console.log("SELECCION VENDEDOR", this.seleccion_vendedor);
    if(this.vendedor_nuevo_select != "" &&
      this.plazo_select  != "" &&
      this.condiciones_pago_select != "" &&
      this.promocion_select != "" &&
      this.version_select != 0 &&
      this.condicion_activo_select != ""){
        console.log("enviar form")
        this.loading = true
        this.data.aceptar(this.form, this.loading,
          this.seleccion_vendedor)
        
    }else{
      this.errorMsg = true;
      this.loading = false
    }
    // if (resp) {
    //   this.dialog.closeAll();
    //   this.celula_seleccionada = ''
    //   // window.location.reload();
    // }

    // if (this.data.aceptar) {
    //   this.data.aceptar();
    // }
    // this.dialogRef.close();
  }
  cerrar() {
    if (this.data.cerrar) {
      console.log("CERRAR");
      this.data.cerrar();
      this.dialogRef.close();
    }
  }
  checkInputValue(event: any): void {
    // this.numero_guia = event.target.value;
    // this.isInputEmpty = this.numero_guia === '';
    
  }
  checkVersion(versionSeleccionada: number) {
    this.version_select = versionSeleccionada;
  
    // Buscar la nueva versión en los datos pasados al modal
    let nuevaVersion = this.data.responseData.find((v: { version: number }) => v.version == versionSeleccionada);
    
    if (nuevaVersion) {
      const rawNuevo = JSON.parse(nuevaVersion.raw_cot);
      this.data.option4 = rawNuevo.periods.map(periodo => periodo.period); // Actualizar plazos
    }
  
    console.log("Nueva versión seleccionada:", this.version_select);
    console.log("Nuevos plazos:", this.data.option4);
  }
  

}
