<div class="container">
    <div>
        <div class="estado-civil">
          <div class="estado-civil-container">
            <mat-label class="estado-civil-label">Estado civil</mat-label>
			<mat-radio-group 
                class="estado-civil-group"
				[(ngModel)]="estado_civil"
                (change)="onEstadoCivilChange($event)"
                >
				<mat-radio-button class="estado-civil-option"value="SOLTERO">Soltero</mat-radio-button>
				<mat-radio-button class="estado-civil-option" value="CASADO">Casado</mat-radio-button>
			</mat-radio-group>
          </div>
            <div class="regimen-matrimonial-container" *ngIf="estado_civil === 'CASADO'">
                <mat-label class="regimen-matrimonial-label">Régimen Matrimonial</mat-label>
                <input type="text" [(ngModel)]="regimen_matrimoanial" class="input-regimen" placeholder="Escribe tu régimen matrimonial">
            </div>
        </div>
        <div>
            <div class="encabezado">
                <div class="contenido-encabezado">
                    <p class="titulo">ACTIVOS</p>
                </div>
                <div class="contenido-encabezado-agregar" (click)="agregarActivo()">
                    <img class="agregar"
                        src="https://leaseforu-assets.s3.us-east-2.amazonaws.com/assets/images/agregarRelacionPatrimonial.svg">
                    <p class="texto-agregar">Agregar</p>
                </div>
            </div>
            <div class="tabla">
                <table class="tabla">
                    <thead>
                        <th>DESCRIPCION DE LOS BIENES</th>
                        <th>UBICACIÓN (DIRECCIÓN)</th>
                        <th style="width: 70px;">VALOR COMERCIAL</th>
                    </thead>
                    <tbody class="registros">
                        <tr *ngFor="let activo of activos; let i = index">
                            <td>
                                <input matInput type="text" [(ngModel)]="activo.descripcion">
                            </td>
                            <td>
                                <textarea [(ngModel)]="activo.ubicacion"> </textarea>
                            </td>
                            <td>
                                <input matInput type="number" [(ngModel)]="activo.valorcomercial"
                                    (ngModelChange)="totalActivos()">
                            </td>
                            <td>
                                <button (click)="eliminarActivo(i)">X</button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div class="totales">
                <div class="texto-total">
                    <p>TOTAL:</p>
                </div>
                <div>
                    <div class="campo-total">
                        <p class="valor-total">$ {{totalActivo}}</p>
                    </div>
                </div>
            </div>
        </div>
        <div>
            <div class="encabezado">
                <div class="contenido-encabezado">
                    <p class="titulo">PASIVOS</p>
                </div>
                <div class="contenido-encabezado-agregar" (click)="agregarPasivo()">
                    <img class="agregar"
                        src="https://leaseforu-assets.s3.us-east-2.amazonaws.com/assets/images/agregarRelacionPatrimonial.svg">
                    <p class="texto-agregar">Agregar</p>
                </div>
            </div>
            <div class="tabla">
                <table class="tabla">
                    <thead>
                        <th>TIPO (ARRENDAMIENTO, CREDITO, ETC)</th>
                        <th>INSTITUCIÓN</th>
                        <th>FECHA DE INICIO</th>
                        <th>MONTO</th>
                        <th>PLAZO</th>
                    </thead>
                    <tbody class="registros">
                        <tr *ngFor="let pasivo of pasivos; let i = index">
                            <td>
                                <input matInput type="text" [(ngModel)]="pasivo.tipo">
                            </td>
                            <td>
                                <input matInput type="text" [(ngModel)]="pasivo.institucion">
                            </td>
                            <td>
                                <input matInput type="date" [(ngModel)]="pasivo.fechaInicio">
                            </td>
                            <td>
                                <input matInput type="number" [(ngModel)]="pasivo.monto"
                                    (ngModelChange)="totalPasivos()">
                            </td>
                            <td>
                                <input matInput type="number" [(ngModel)]="pasivo.plazo">
                            </td>
                            <td>
                                <button (click)="eliminarPasivo(i)">X</button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div class="totales">
                <div class="texto-total">
                    <p>TOTAL:</p>
                </div>
                <div>
                    <div class="campo-total">
                        <p class="valor-total">$ {{totalPasivo}}</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="botones">
        <div class="btn-validacion" >
            <button class="val-btn" (click)="guardarInformacion()"> Guardar</button>
        </div>
        <div class="guardar-btn-div">
            <button class="salir-btn" (click)="salir()">Cancelar</button>
        </div>
    </div>
</div>