import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatStepper } from '@angular/material/stepper';
import { ActivatedRoute, Router } from '@angular/router';
import { InfoComponent } from 'src/app/_components/info/info.component';
import { AuthenticationService, SolicitudService, UserService } from 'src/app/_services';
import { ComentariosComponent } from '../../valuacion/comentarios/comentarios.component';
import { MessageBoxComponent } from 'src/app/_dialogs/message-box/message-box.component';
import { customPatterns } from 'src/app/_models/maskPattern';

@Component({
  selector: 'app-main-ocr',
  templateUrl: './main-ocr.component.html',
  styleUrls: ['./main-ocr.component.scss']
})
export class MainOcrComponent implements OnInit {


  @ViewChild('stepper') private stepper: MatStepper;

  firstStepCompleted = true;
  secondStepCompleted = true;
  thirdStepCompleted = true;
  formData = null
  customPattern = customPatterns
  es_medico = false
  typeP = 1;
  loading = false;
  submitted = false;
  returnUrl: string;
  parte = 1;
  folio = null
  tipo_persona = 1
  load_request = false
  perfil = 0

  isStep1Completed1: boolean = false;
  isStep1Completed2: boolean = false;
  isStep1Completed3: boolean = false;

  id_cliente = 0
  id_usuario = 0
  nombres = ''
  ap_paterno = ''
  ap_materno = ''
  tel_cel = ''
  correo = ''

  allowed_type = "application/pdf";
  $loading = null
  documento = {
    nombre_documento: 'Solicitud impresa',
    descripcioncompleta: 'Selecciona la solicitud que llenaste previamente',
    status: 0,
    comentarios: '',
    url: '',
    tipo_documento: 21
  }
  coficontactoid = null
  client_details: any = {}

  dataCoFi = {
    "email": "",
    "rfc": "",
    "tipoPersonaFiscal": "",
    "sectorEconomico": "",
    "actividadPrincipal": "",
    "nombres": "",
    "nombreRS": "",
    "apellidoPaterno": "",
    "apellidoMaterno": "",
    "fechaNacimiento": "",
    "genero": "",
    "nacionalidad": "",
    "moneda": "mxn",
    "direccion": "",
    "direccionGeografica": "",
    "calle": "",
    "numExterior": "",
    "numInterior": "",
    "colonia": "",
    "ciudad": "",
    "municipio": "",
    "estado": "",
    "codigoPostal": "",
    "direccionFiscal": "",
    "direccionGeograficaFiscal": "",
    "calleFiscal": "",
    "numExteriorFiscal": "",
    "numInteriorFiscal": "",
    "coloniaFiscal": "",
    "ciudadFiscal": "",
    "municipioFiscal": "",
    "estadoFiscal": "",
    "codigoPostalFiscal": ""
  }

  solicitud = {
    contacto: {
      primerNombre: '',
      apellidoMaterno: '',
      apellidoPaterno: '',
      fechaNacimiento: '',
      rfc: '',
      tituloPersona: '',
      regimenContribucion: '',
      genero: '',
      sectorEconomico: ''
    },
    aval: {},
    conyuge: {
      primerNombre: '',
      segundoNombre: '',
      apellidoPaterno: '',
      apellidoMaterno: '',
      ocupacion: '',
      telefono: '',
    },
    referencias: [],
    direccion: {},
    direccionFiscal: {}
  }

  ready = false

  flujo_cotizador=false

  constructor(
    private authenticationService: AuthenticationService,
    private router: Router,
    private userService: UserService,
    private dialog: MatDialog,
    private solicitudService: SolicitudService,
    private cdr: ChangeDetectorRef,
     private route: ActivatedRoute
  ) { }

  async ngOnInit() {

   
    this.route.queryParams.subscribe(params => {
      this.flujo_cotizador = params['flujo_cotizador']
    });

    

    // Obtener el folio desde la URL
    if (this.flujo_cotizador) {
      this.route.queryParams.subscribe(params => {
        this.folio = params['folio'];   
      });
      this.id_cliente = Number(localStorage.getItem("current_id_cliente")) 
    
     if(this.folio!=null&&this.id_cliente!=null){
      localStorage.setItem(
        'client_details',
        JSON.stringify({ folio: this.folio, id_cliente: this.id_cliente })
      );
     }

    }
    
    this.ready = false
    //this.$loading = document.querySelector('.uploading').setAttribute('class', 'uploading hide')
    const loadingElement = document.querySelector('.uploading');
    if (loadingElement) {
      loadingElement.setAttribute('class', 'uploading hide');
    } else {
      console.warn("Elemento con clase '.uploading' no encontrado en el DOM.");
    }

    this.perfil = JSON.parse(localStorage.getItem('currentUser')).perfil
    const steps = localStorage.getItem('steps');

    const datos_generales = steps
      ? Number(JSON.parse(steps)?.datos_generales || 0) 
      : 0;
    

    const storedClientDetails = localStorage.getItem('client_details');
    this.client_details = storedClientDetails ? JSON.parse(storedClientDetails) : {};
    this.coficontactoid = this.client_details.coficontactoid || null
    
    this.folio = this.client_details.folio
    this.parte = datos_generales + 1
    localStorage.setItem('tipoPersona', this.client_details.tipo_persona)
    this.tipo_persona = this.client_details.tipo_persona
    const tmp = localStorage.getItem('tipoPersona');
    if (tmp != '' && tmp != 'undefined') {
      this.typeP = Number(tmp);
    } else {
      localStorage.setItem('tipoPersona', '1');
      this.changeActive(1);
    }

    const currentUser = JSON.parse(localStorage.getItem("currentUser"))
    if (this.perfil == 1) {
      const { id_cliente, CotNombreCliene, CotApellidoPaterno, CotApellidoMaterno, EmailCliente, ClienteTelefono } = currentUser.quatation
      this.nombres = CotNombreCliene
      this.ap_paterno = CotApellidoPaterno
      this.ap_materno = CotApellidoMaterno
      this.tel_cel = ClienteTelefono
      this.correo = EmailCliente
      this.id_cliente = id_cliente
    } else {
      
      if(storedClientDetails!=null){
        const { id_cliente, cliente_nombre, cliente_apellido_paterno, cliente_apellido_materno, email, cliente_telefono } = JSON.parse(localStorage.getItem("client_details"))
      this.nombres = cliente_nombre
      this.ap_paterno = cliente_apellido_paterno
      this.ap_materno = cliente_apellido_materno
      this.tel_cel = cliente_telefono
      this.correo = email
      this.id_cliente = id_cliente
      this.id_usuario = currentUser.id
      }else{
        this.nombres = null
      this.ap_paterno = null
      this.ap_materno = null
      this.tel_cel = null
      this.correo = null
      this.id_cliente = null
      this.id_usuario = currentUser.id
      }
    }

    const datosCliente = await this.solicitudService.ObtenerDatosCliente(this.correo)
  
    const solicitud = await this.solicitudService.ObtenerSolicitud(this.folio)
   
    this.llenarSolicitud({ datosCliente, solicitud })
    this.ready = true
  
  }

  changeActive(tipo: number, es_medico = false) {

    if (this.tipo_persona > 0) return
    localStorage.setItem('tipoPersona', tipo.toString());
    this.typeP = tipo;

  }
  changeForm(newPart) {
  
    this.parte = newPart;
    this.stepper.next();
  }
  llenarSolicitud({ datosCliente = { conyugue: {} }, solicitud = {} }: any) {

    if (solicitud) {
      const { contacto, conyuge } = solicitud
      this.solicitud.contacto = {
        primerNombre: contacto ? contacto.nombreCompleto : datosCliente ? datosCliente?.first_name : this.nombres,
        apellidoPaterno: contacto ? contacto.apellidoPaterno : datosCliente ? datosCliente?.first_lastname : this.ap_paterno,
        apellidoMaterno: contacto ? contacto.apellidoMaterno : datosCliente ? datosCliente?.second_lastname : this.ap_materno,
        rfc: contacto?.rfc || datosCliente?.rfc || '',
        fechaNacimiento: contacto ? contacto.fechaNacimiento : datosCliente ? datosCliente?.birthday : '',
        genero: contacto ? contacto.genero : '',
        regimenContribucion: contacto ? contacto.regimenContribucion : '',
        sectorEconomico: contacto ? contacto.sectorEconomico : '',
        tituloPersona: contacto ? contacto.tituloPersona : '',
      }

      this.solicitud.conyuge = {
        primerNombre: conyuge ? conyuge.primerNombre : datosCliente && datosCliente.conyugue ? datosCliente.conyugue.primerNombre : '',
        segundoNombre: conyuge ? conyuge.segundoNombre : datosCliente && datosCliente.conyugue ? datosCliente.conyugue.segundoNombre : '',
        apellidoPaterno: conyuge ? conyuge.apellidoPaterno : datosCliente && datosCliente.conyugue ? datosCliente.conyugue.apellidoPaterno : '',
        apellidoMaterno: conyuge ? conyuge.apellidoMaterno : datosCliente && datosCliente.conyugue ? datosCliente.conyugue.apellidoMaterno : '',
        ocupacion: conyuge ? conyuge.ocupacion : datosCliente && datosCliente.conyugue ? datosCliente.conyugue.ocupacion : '',
        telefono: conyuge ? conyuge.telefono : datosCliente && datosCliente.conyugue ? datosCliente.conyugue.telefono : '',
      }

      this.solicitud.direccion = solicitud.direccion
    }
  }

  SeleccionarDocumento(doc, solicitante) {
    if (!this.userService.alertService.isOnline()) return;
    // const doc = (solicitante) ? this.Documentos.DocumentosSolicitante[idx] : this.Documentos.DocumentosAval[idx]

    if (doc.status == 3 || doc.status == 1) return;

    this.formData = new FormData();
    this.formData.append("folio", this.folio);
    this.formData.append("id_cliente", this.id_cliente.toString());
    this.formData.append("tipo_documento", doc.tipo_documento);
    this.formData.append("solicitante", solicitante);
    this.formData.append("id_usuario_mod", this.id_usuario);


    const $document: any = document.querySelector("#document");
    $document.click();
  }

  VerDescripcion(descripcion) {
    this.dialog.open(InfoComponent, {
      width: "500px",
      data: {
        text: descripcion,
      },
    });
  }

  VerComentario(documento, solicitante) {
    if (documento.comentarios) {
      this.dialog.open(ComentariosComponent, {
        width: "550px",
        data: {
          text: documento.comentarios,
          ver_comentario: true,
        },
      });
    }
  }

  VerDocumento(documento, solicitante) {
  

    if (this.userService.alertService.isOnline()) {
      const { tipo_documento, status } = documento;
      localStorage.setItem("documento", JSON.stringify(documento));

      if (status != 3 && tipo_documento == 3 && solicitante) {
        localStorage.setItem("tipo_documento", tipo_documento);
        return this.router.navigate(["dashboard/comercial/validacionId"]);
      }

      if (documento.url) {
        if (
          (documento.tipo_documento == 3 || documento.tipo_documento == 9) &&
          solicitante
        ) {
          this.router.navigate([
            "/dashboard",
            "comite",
            "valuacion",
            "validacionId",
          ]);
        } else {
          this.router.navigate([
            "/dashboard",
            "cliente",
            this.folio,
            "ver_documento",
          ]);
        }
      }
    }
  }

  resset = (event) => {
    event.target.value = null;
  };

  SubirDocumento({ target: { files } }) {

    if (!this.userService.alertService.isOnline()) return;
    const file = files[0];
 

    if (file) {
      if (!this.allowed_type.includes(file.type)) {
        return this.dialog.open(MessageBoxComponent, {
          width: "500px",
          data: {
            text: "Documento no válido",
            text2: "Verifica que sea formato PDF",
            text3: "Vuelve a intentarlo",
            icon: "error",
            type: "error",
          },
        });
      }
      this.formData.append("document", file);


      this.$loading = document.querySelector('.uploading').setAttribute('class', 'uploading show')
      this.GuardarSolicitudVacia().then(res => {

        this.userService.SubirDocumento(this.formData).subscribe(
          (res) => {

            const $document = document.querySelector("#document");

            this.$loading = document.querySelector('.uploading').setAttribute('class', 'uploading hide')
            this.dialog.open(MessageBoxComponent, {
              minWidth: "500px",
              data: {
                text: "Se ha cargado correctamente el documento",
                text3: file.name,
                icon: "success",
                type: "success",
              },
            });
      
            this.userService.goToDashboard()
            // aqui va el salto de proceso
          },
          (err) => {
     
            this.dialog.open(MessageBoxComponent, {
              width: "400px",
              data: {
                text: "No se ha cargado el documento correctamente",
                ttext2: "Vuelve a intentarlo",
                type: "error",
                icon: "error",
              },
            });
          }
        );
      })
    }
  }

  private async GuardarSolicitudVacia() {

    const data = this.typeP == 2 ? {
      razon_social: this.nombres,
      sector: '',
      actividad_principal: '',
      monto_linea: '0',
      activo_arrendar: '',
      pagina: '',
      telefono: this.tel_cel,
      fecha_constitucion: '',
      pm_rfc: '',
      numero_empleados: '',
      nombre_director: '',
      pm_correo: this.correo
    } : {
      id_cliente: this.id_cliente,
      folio: this.folio,
      es_medico: `${this.es_medico}`,
      nombres: this.nombres,
      ap_materno: this.ap_materno,
      ap_paterno: this.ap_paterno,
      correo: this.correo,
      tel_cel: this.tel_cel
    }
    const autoSave = await (await this.solicitudService.saveDatosGenerales(data, 1, this.typeP, 1)).toPromise()
    


    const data2 = this.typeP == 2 ? {
      rl_nombres: ' ',
      rl_apellidos: ' ',
      rl_rfc: ' ',
      ce_nombres: ' ',
      ce_apellidos: ' ',
      ce_correo: ' ',
      ce_puesto: ' ',
      domicilio_empresa: ' ',
    } : { tipo_vivienda: ' ' };
    await (await this.solicitudService.saveDatosGenerales(data2, 2, this.typeP, 1)).toPromise()

    const data3 = this.typeP == 2
      ? {
        accionista1: ' ',
        participacion1: ' ',
        participacion1_rfc: ' ',
      }
      : { ocupacion_medica: ' ' };
    await (await this.solicitudService.saveDatosGenerales(data3, 3, this.typeP, 2)).toPromise()

    await (await this.solicitudService.saveReferencias({ institucion1: ' ' }, 1, this.typeP, 1)).toPromise()

    await (await this.solicitudService.saveReferencias(this.typeP == 2
      ? { proveedor_nombre1: ' ' }
      : { rp_nombre1: ' ' }, 2, this.typeP, 2)).toPromise()
    if (this.typeP == 2) {
      await (await this.solicitudService.saveReferencias({ cliente_nombre1: ' ' }, 3, this.typeP, 2)).toPromise()
    }
    localStorage.setItem('tipoAval', '1')
    await (await this.solicitudService.saveAval({ aval_nombre_rs: ' ' }, 1, this.typeP, 1)).toPromise()
    await (await this.solicitudService.saveAval({ aval_autoriza: true }, 2, this.typeP, 2)).toPromise()
  }

  SetData(data, parte) {


    this.dataCoFi.tipoPersonaFiscal = this.typeP == 2 ? "Persona moral" : "Persona física"
    const fechaNacimiento = data.fecha_nac ? data.fecha_nac.split('-') : []
    if (parte == 1) {
      this.dataCoFi.apellidoMaterno = data.ap_materno
      this.dataCoFi.apellidoPaterno = data.ap_paterno
      this.dataCoFi.email = data.pm_correo || data.correo
      this.dataCoFi.fechaNacimiento = fechaNacimiento.length == 3 ? `${fechaNacimiento[2]}/${fechaNacimiento[1]}/${fechaNacimiento[0]} 12:00 am` : ''
      this.dataCoFi.nacionalidad = data.nacionalidad
      this.dataCoFi.nombres = data.nombres
      this.dataCoFi.genero = data.sexo == 'F' ? 'Mujer' : 'Hombre'
      this.dataCoFi.rfc = data.pm_rfc || data.rfc
      this.dataCoFi.sectorEconomico = data.sector
      this.dataCoFi.actividadPrincipal = data.actividad_principal

      this.dataCoFi.nombreRS = data.razon_social
    } else if (parte == 2) {
      this.dataCoFi.direccion = data.direccion.direccion
      this.dataCoFi.direccionGeografica = data.direccion.direccionGeografica
      this.dataCoFi.calle = data.direccion.calle
      this.dataCoFi.numExterior = data.direccion.numExterior
      this.dataCoFi.numInterior = data.direccion.numInterior
      this.dataCoFi.colonia = data.direccion.colonia
      this.dataCoFi.ciudad = data.direccion.ciudad
      this.dataCoFi.municipio = data.direccion.municipio
      this.dataCoFi.estado = data.direccion.estado
      this.dataCoFi.codigoPostal = data.direccion.codigoPostal
      this.dataCoFi.direccionFiscal = data.direccionFiscal.direccion
      this.dataCoFi.direccionGeograficaFiscal = data.direccionFiscal.direccionGeografica
      this.dataCoFi.calleFiscal = data.direccionFiscal.calle
      this.dataCoFi.numExteriorFiscal = data.direccionFiscal.numExterior
      this.dataCoFi.numInteriorFiscal = data.direccionFiscal.numInterior
      this.dataCoFi.coloniaFiscal = data.direccionFiscal.colonia
      this.dataCoFi.ciudadFiscal = data.direccionFiscal.ciudad
      this.dataCoFi.municipioFiscal = data.direccionFiscal.municipio
      this.dataCoFi.estadoFiscal = data.direccionFiscal.estado
      this.dataCoFi.codigoPostalFiscal = data.direccionFiscal.codigoPostal
    }
  }

  async EnviarDatosCoFi() {

    const res: any = await this.solicitudService.GuardarContactoCoFi(this.dataCoFi)

    if (res.statusCode == 200) {
      this.client_details.coficontactoid = res.data.idContacto
      localStorage.setItem('client_details', JSON.stringify(this.client_details))
    }

    this.dialog.open(MessageBoxComponent, {
      width: "500px",
      data: {
        text2: "Información guardada",
        text3: "Se ha actulizado la información en CoFi",
        type: "success",
        icon: "success"
      }
    })

  }

  logout() {
    this.authenticationService.logout();
    this.router.navigate(['/login']);
  }
  // partetres(){
  // 	this.parte=3;
  // 	console.log("Valor de Parte ::.", this.parte)
  // }
  updateStep1Completion1(status: boolean) {
    this.isStep1Completed1 = status;
    this.cdr.detectChanges();
  }
  updateStep2Completion2(status: boolean) {
    this.isStep1Completed2 = status;
    this.cdr.detectChanges();
  }
  updateStep3Completion3(status: boolean) {
    this.isStep1Completed3 = status;
    this.cdr.detectChanges();
  }


}
