import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";

@Component({
  selector: "app-documents-new",
  templateUrl: "./documents-new.component.html",
  styleUrls: ["./documents-new.component.scss"],
})
export class DocumentsNewComponent implements OnInit {
  @Input() documento;
  @Input() index;
  @Input() solicitante;
  @Input() show = true;
  @Input() isUploading: boolean = false; 
  @Input() isAval=false;
  @Input() rfcAval:string=null

  @Output() VerInfo = new EventEmitter();
  @Output() Upload = new EventEmitter();
  @Output() VerDocumento = new EventEmitter();
  @Output() VerComentarios = new EventEmitter();
  @Output() Aceptar = new EventEmitter();
  @Output() Rechazar = new EventEmitter();
  @Output () rfcAvalEvent = new EventEmitter();

  aceptando = false;
  rechazando = false;
  perfil = null;
  registroFederalContribuyentes:string=null;
  

  validar_documento;

  documentos_estados_cuenta = [];

  constructor() {}

  ngOnInit(): void {
    this.registroFederalContribuyentes=this.rfcAval?this.rfcAval:null;
  }
  
  verDocumento(): any {
    console.log("this.documento.status :", this.documento.status);
    console.log(
      "this.documento.tipo_documento :",
      this.documento.tipo_documento
    );
    // if( !this.userService.alertService.isOnline() ) return
    console.log(this.index);
    console.log(!this.documento.url);

    console.log("Documento: ", this.documento);
    if (this.documento.status == 0) return;
    if (
      this.documento.tipo_documento == 3 &&
      this.documento.status == 3 &&
      this.perfil == 3
    )
      return;
    if (this.documento.tipo_documento == 3 && this.documento.status == 3)
      return;
    if (
      this.documento.tipo_documento != 3 ||
      this.documento.tipo_documento != 9
    )
      return this.VerDocumento.emit(this.index);
    if (!this.documento.url.length) return;
    this.VerDocumento.emit(this.index);
  }

  upload(): any {
    // if( !this.userService.alertService.isOnline() ) return
    this.isUploading = true; 
    console.log(this.index);
    if (this.documento.status == 1 || this.documento.status == 3){
  
      this.isUploading = false; 
      
      return;
    } 
    setTimeout(() => {
      this.isUploading = false; 
      },20000);
    this.Upload.emit(this.index);
  }
  info(): any {
    // if( !this.userService.alertService.isOnline() ) return
    this.VerInfo.emit(this.documento.descripcioncompleta);
  }

  rfc(){
    this.rfcAvalEvent.emit(this.registroFederalContribuyentes);
  }
}
